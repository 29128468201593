import WebServiceWrapper from "./WebServiceWrapper";

export const BacklogService = {
  GetActionItems,
  AddingActionItems,
  AddEditSprint,
  GetNotStartedSprints,
  StartSprint,
  GetSprintReleaseData,UpdateActionItemOrderIndex
};

function GetActionItems(projectId, isTrue) {
  return WebServiceWrapper.Get(`/backlog/GetActionItems?projectID=${projectId}&isFromWorkItemPage=${isTrue}`);
}

function AddingActionItems(payload) {
  return WebServiceWrapper.Post("/backlog/AddingActionItems", payload);
}

function AddEditSprint(sprintData) {
  return WebServiceWrapper.Post("/backlog/AddEditSprintData", sprintData);
}

function GetNotStartedSprints(projectId) {
  return WebServiceWrapper.Get(`/backlog/GetNotStartedSprints?projectID=${projectId}`);
}

function StartSprint(payload) {
  return WebServiceWrapper.Post("/backlog/StartSprint",payload);
}

function GetSprintReleaseData(projectId){
  return WebServiceWrapper.Get(`/backlog/GetSprintReleaseData?projectID=${projectId}`);
}
function UpdateActionItemOrderIndex(data) {
  return WebServiceWrapper.Post("/backlog/UpdateActionItemOrderIndex", data);
}
export const LoginPageText = {
  EmailPlaceholder: "Email ID",
  PasswordPlaceholder: "Password",
  EmailRequired: "Enter username.",
  InvalidEmail: "Enter a valid email.",
  EmailExists: "This email already exists.",

  PasswordRequired: "Enter password.",
  LoginFailure: "Invalid email or password.",
  Inactive: "Your account is inactive",
};
export const ForgotPasswordMsg = {
  Email: "Enter valid email.",
  FalsyEMail: "Email does not exist."

}

export const ToastMessages = {
  Fail: "Oops!, Something went wrong.",
  InvalidFileFormat: "File is not in the correct format.",
  Added: "Data added succesfully.",
  Update: "Data updated successfully.",
  ResetEmail: "Reset link sent successfully. ",
  PasswordConfirmation: "Password changed successfully.",
  SaveReport: "Time logged successfully.",
  IssueOccured: "Issue occurred while loading items.",
  ItemAdded: "Item [itemNo]: [itemDesc] added successfully.",
  ItemUpdated: "Item [itemNo]: [itemDesc] updated successfully.",
  NoReportAvail: "No report available for the selected date.",
  ResetPasswordError: "Issue occurred while updating password.",
  UpdateFailed: "Issue occured while updating details.",
  DateExist: "Please edit report for this date.",
  InactiveResetPassword: "Your account is inactive. You cannot reset the password.",
  WorkLogDelete: "WorkLog Deleted successfully.",
  UpdateReport: "Time logged data updated successfully.",
  UserDelete: "User Deleted successfully.",
  TaskDelete: "Task Deleted successfully.",
  UserIsLead: "Selected user cannot be deleted as the user is a lead for the project. Please change the Project Lead and try again.",
  UserIsLeadStatus: "The selected user's status cannot be updated as the User is the Project Lead for a project. Please change the Project Lead and try again.",
  ProjectDelete: "Project Deleted successfully.",
  EmployeeIsLead: "You selected a Termination Date, but the employee's status cannot be updated because they are the Project Lead for one or more projects. Please change the Project Lead and try again.",
  MiscOtherDelete: "Option deleted successfully.",
  DataDelete:"Record deleted successfully.",
  IsLead:"The employee's role cannot be updated as they are currently assigned as the Project Lead for one or more projects. Please reassign the Project Lead and try again.",
};

export const AddEditActionItemErrorMsg={
  IssueType:'Please select the issue type.',
  Assignee:"Please select the assignee value.",
  Priority:"Please select the priority value.",
  Title:"Please enter the title.",
Description:"Please enter the description.",
EstimatedHrs:"Please enter the valid estimated hrs.",
DesignEstimate:"Please enter design estimate.",
DevEstimate:"Please enter dev estimate.",
QaEstimate:"Please enter QA estimate.",
ValidateAllFields:"Please update all mandatory fields on page.",
Rootcause:"Please select root cause.",
Comments:"Please add comments.",
    NewComments: "Please click save before adding one more comment.",
    commentErroOnStatusChange: "Please add Comments after changing Status",
    Category:"Please select the category value.",
    workItemType:"Please select work item type."
}
export const CommonErrorMessages = {
  DueDate: "Due Date is in past.",
  Required: "This field is required.",
  DuplicateSwminName: "Swimlane name already exists.",
  DuplicateReleaseName: "Release name already exists.",
  DuplicatePhaseName: "Phase name already exists.",
  StartDate: "Select valid start date.",
  EndDate: "Select valid end date.",
  DuplicateName: "This name already exists.",
  Added: "Data added successfully.",
  Updated: "Data update successfully.",
  SelectProject: "Please select project.",
  Type: "Please select the type.",
  TimeSpent: "Please enter the valid time spent.",
  Description: "Please enter the description.",
  NewProject: "Please enter project name.",
  ClientName: "Please enter client name.",
  SelectLead: "Please select the lead.",
  Key: "Please enter the key.",
  DuplicateKey: "key already exists.",
  validTime: "Enter valid time spent",
  ReleaseName: "Enter release name.",
  PhaseName: "Enter phase name.",
  PhaseEstimate: "Enter valid hrs.",
  MiscOther: "Please enter value",
  MiscOtherSameVal: "Entered value already exists.",
  WorkDone: "Please select work done option.",
  IssuesFixed: "Please enter the number of issues fixed.",
  CommentsEmpty:"Please enter the comments.",
  MiscOtherType: 'Please select Miscellaneous Category.',
};
export const ResetPasswordErrorMessage = {
  Password: "Enter new password.",
  ConfirmPassword: "Re-enter new password.",
  NotMatched: "Password fields does not match.",
  PasswordMinLength:
    "Password should have a minimum of 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.",
  Sucess: "Success",
  UserInactive: "User Inactive"
};
export const LoginErrorMsg = {
  FiveAttempts: "Your account has reached the maximum number of failed login attempts. Please try after 5 minutes or click forgot password link to reset your password.",
  AccountBlocked: "Your profile have been blocked due to multiple failed attempts. Please reset your password."

};
export const ActiveSprintMsg = {
  Select: "Please select a sprint."

};
export const ReportErrorMessage = {
  Hours: "Time spent value should be greater then 0.",
  Task: "Select the task.",
  Misc: "Select miscellaneous activity.",
  TaskName: "Please Enter task name.",
  Description: "Please Enter Description.",
};
export const BreadToastMsg = {
  LinkCopied: "URL copied to clipboard.",
  Failed: "Something went wrong.",
};
export const ProjectErrorMessage = {
  NameEmpty: "Please enter name.",
  EmployeeNotSelected: "Please select employee.",
  ProjectAdd: "Project added successfully.",
  ProjectUpdate: "Project updated successfully.",
  CategoryName:"Please enter category name."
};

export const ManageUser = {
  FirstNameEmpty: "Please enter minimum 3 characters.",
  LastNameEmpty: "Please enter minimum 3 characters.",
  EmailEmpty: "Please enter email.",
  TeamEmpty: "Please select a team.",
  RoleEmpty: "Please select a role.",
  UserAdd: "Employee Added Successfully.",
  UserUpdate: "Employee Updated Successfully.",
  ProfileUpdate: "Data Updated Successfully.",
  ProfilePicDelete: "Profile image deleted Successfully.",
  Phone: "Phone number must be 10 characters long.",
};

export const Sprint = {
  SprintName: "Please enter sprint name.",
  sprintEmpty: "At least one work item should be added to start the sprint.",
  SprintCreate: "Sprint Created Successfully.",
  SprintUpdate: "Sprint Updated Successfully.",
};

export const CreateActionItemErrorMsg = {
  IssueType: "Please select the issue type.",
  Title: "Please enter the title.",
  AssignTo: "Please select the employee.",
  PriorityList: "Please select the priority.",
  Description: "Please enter the description.",
  DesignEst: "Please enter design estimate.",
  DevEst: "Please enter development estimate.",
  QaEst: "Please enter QA estimate.",
  rootCauseDescription: "Please enter the description for the root cause.",
  AllowedFileFormatTypes: "Only pdf,images and docs files are allowed.",
  TitleMaxLimitMsg: "Title must be less than 500 characters."
};

export const RolesPermissionErrorMessage = {
  AdminPermissionEmpty: "Please select Permission for Admin Role.",
  ProjectLeadPermissionEmpty: "Please select Permission for Project Lead.",
  EmployeePermissionEmpty: "Please select Permission for Project Lead.",
  PermissionSave: "Roles & Permission Saved successfully.",
};

// export const GlobalEstimation = {
//   Delete:"Record deleted successfully"
// };
export const TshirtSizeError = {
  TshirtSize: "Enter T-Shirt Size.",
  ScopeOfWork: "Enter Scope of Work.",
  EstimatedTime: "Enter Estimated Time.",
  DuplicateSize:"T-Shirt size already exists."
};

export const EstimationError = {
  WorkItem: "Enter Estimated Work Item.",
  Description: "Enter Description.",
  TShirtSize: " Select T-Shirt Size.",
  //DuplicateSize:"T-Shirt size already exists."
};
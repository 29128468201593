import React from "react";
import { LetteredAvatarMsg } from "../../Helpers/Enums";
import { useNavigate } from "react-router-dom";

const LetteredAvatar = ({ name, customStyle, bgcolor, editTask, header, profileImageComments, commentsHistory, myTasks, isComments, isTimeLog }) => {

  const navigate = useNavigate();
  function getInitials(name) {
    if (typeof name === "string" && name.trim() !== "") {
      const nameParts = name.trim().split(" ");
      if (nameParts.length >= LetteredAvatarMsg.TwoLetters) {
        return `${nameParts[0][0]}${nameParts[1][0]}`;
      } else if (nameParts.length === LetteredAvatarMsg.OneLetter) {
        return `${nameParts[0][0]}`;
      }
    }
    return ""; // Handle cases where name is undefined or doesn't have enough parts.
  }




  let initials = getInitials(name);

  const defaultStyle = {
    display: "flex",
    height: customStyle !== undefined ? customStyle.height : "23px",
    width: customStyle !== undefined ? customStyle.width : "24px",
    borderRadius: customStyle !== undefined ? customStyle.borderRadius : "15px",
    color: "black",
    background: `${bgcolor}`,
    //marginRight: "4px",
  };
  const commentsHistoryStyle = {
    ...defaultStyle,

    fontSize: "15px",
    fontFamily: "Lato-Regular",
    height: "27px",
    width: "29px",
  };
  const comments = {
    ...defaultStyle,
    borderRadius: "50%",
    fontSize: "15px",
    fontFamily: "Lato-Regular",
    height: "40px",
    width: "40px",
  };

  const profileMyTask = {
    ...defaultStyle,
    borderRadius: "50%",
    height: "22px",
    width: "22px",
    marginTop: "6px",
    marginLeft: isTimeLog ? "-10px" : "",
  };

  const myTask = {
    ...defaultStyle,
    marginTop: "6px",
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: isTimeLog ? "-10px" : ""
  };
  const handleclick = () => {
    //navigate("/Profile")
  }

  // <img style={{
  //   display: "flex",
  //   height: "38px",
  //   width: "40px",
  //   borderRadius: '50%',
  //   objectFit: 'cover'
  // }} src={profileImage} srcSet={`${profileImage} 1x, ${profileImage} 2x`} alt="Profile" ></img>


  return (
    <>
      {/* <summary>
    date: 22-06-2024
    Name: Mustakim
    description: to show image when profileImagePath is true
    <summary>*/}
      {(profileImageComments !== null && profileImageComments !== undefined) ?
        <img style={(commentsHistory && !isComments) ? comments : myTasks ? profileMyTask : {
          display: "flex",
          //height: "45px",
          // width: "45px",
          borderRadius: '50%',
          objectFit: 'cover',

        }} title={name} src={profileImageComments} srcSet={`${profileImageComments} 1x, ${profileImageComments} 2x`} alt="Profile" onContextMenu={(e) => e.preventDefault()} ></img>
        :
        <div style={((commentsHistory && !isComments) || editTask) ? comments : commentsHistory ? commentsHistoryStyle : myTasks ? myTask : !editTask ? defaultStyle : {}} >
          <span onClick={handleclick} style={{ margin: myTasks ? "" : "auto", fontSize: header || commentsHistory || isComments ? "19px" : myTasks ? "8px" : "10px", fontWeight: header ? "" : 'bold' }} title={`${customStyle ? "" : commentsHistory ? "" : editTask ? "" : myTasks ? "" : "Assignee:-"} ${name}`}
          >{initials}</span>
        </div>
      }
    </>
  );
};

export default LetteredAvatar;
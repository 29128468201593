import {
  AllowedFileFormats,
  FileIcons,
  ToastMessageType,
  MonthNames,
  MaxCharacterCount,
} from "../Helpers/Enums";
import { useEffect, useState } from "react";

import ErrorField from "./ErrorField";
import { CreateActionItemErrorMsg } from "../Helpers/HelperText";
import { useContext } from "react";
import { AppContext } from "../Contexts/AppContextProvider";
import { AddLocalStorageData } from "../Helpers/Utilities";
/* <summary>
  date: 6-10-2023
  Name: Neychelle Godinho
  description: Multiple File Upload Component 
  <summary>*/

const MultipleFileUpload = ({
  DontShowInComment,
  getFileList,
  load,
  getvideoLinkList,
  fileList,
  videoLinkList,
  type,
  clearAttachments,
  maxFileSizeLimit,
  files,
  showFilesFrom, handleDroppedFiles, handlePasteFiles, handleEdit
}) => {

  const [documentFile, setDocumentFile] = useState([]);
  const [tempDocumentFile, setTempDocumentFile] = useState([]);
  const [deleteFileList, setdeleteFileList] = useState([]);
  const [deleteVideoLinkList, setdeleteVideoLinkList] = useState([]);

  const [videoLink, setvideoLink] = useState([]);
  const [videoLinkValue, setvideoLinkValue] = useState([]);
  const [maxFileSizeLimitMsg, SetMaxFileSizeLimitMsg] = useState(false);
  const validFileFormatsReadable = AllowedFileFormats;
  const { ShowToastMessage } = useContext(AppContext);
  // useEffect(()=>{
  //   console.log("documentFile",documentFile)
  //   console.log("tempDocumentFile",tempDocumentFile)
  //   console.log("videoLink",videoLink)
  //   console.log("deleteFileList",deleteFileList)
  //   console.log("deleteVideoLinkList",deleteVideoLinkList)
  // },[documentFile,tempDocumentFile,videoLink,deleteFileList,deleteVideoLinkList])
  /* <summary>
  date: 6-10-2023
  Name: Neychelle Godinho
  description: Gets the file icon depending on the file extension
  <summary>*/


  useEffect(() => {
    if (fileList !== undefined && fileList.length > 0) {
      fileList.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
    }
  }, [fileList])

  useEffect(() => {
    if (
      files !== null &&
      files !== undefined &&
      files.length > MaxCharacterCount.Empty
    ) {
      setDocumentFile((prevDocumentFiles) => [...prevDocumentFiles, ...files]);
      let tmpFileList = Array.from(files).map((file) => ({
        path: file.path,
        fileName: file.name,
        fileMediaId: file.fileMediaId,
      }));

      setTempDocumentFile(tmpFileList);

      const mockEvent = { target: { files: files } };
      HandleFileChange(mockEvent);
    }
  }, [files]);

  const isValidFileType = (fileType) => {
    return AllowedFileFormats.includes(fileType.toLowerCase());
  };

  useEffect(() => { }, [tempDocumentFile]);

  const GetFileIcon = (responseText) => {
    if (responseText) {
      const splitString = responseText.split(".");

      if (
        splitString[splitString.length - 1] === "doc" ||
        splitString[splitString.length - 1] === "docx"
      )
        return FileIcons.WORD;
      else if (
        splitString[splitString.length - 1] == "xls" ||
        splitString[splitString.length - 1] == "xlsx" ||
        splitString[splitString.length - 1] == "csv"
      )
        return FileIcons.EXCEL;
      else if (splitString[splitString.length - 1] == "pdf")
        return FileIcons.PDF;
      else if (splitString[splitString.length - 1] == "mp4")
        return FileIcons.VIDEO;
      else if (splitString[splitString.length - 1] == "png")
        return FileIcons.PNG;
      else if (splitString[splitString.length - 1] == "webm")
        return FileIcons.WEBM;
      else if (splitString[splitString.length - 1] == "txt")
        return FileIcons.TEXT;
      else return FileIcons.IMAGE;
    }
  };
  /* <summary>
  date: 6-10-2023
  Name: Neychelle Godinho
  description: call the parent component function whenever
   the documentfile state id updated which then passes the list of files.
  <summary>*/
  useEffect(() => {
    return getFileList(documentFile, deleteFileList);
  }, [documentFile, type]);


  useEffect(() => {
    if (videoLinkList !== undefined && videoLinkList.length != MaxCharacterCount.Empty) {
      let tmpVideoLink = [...videoLink];
      tmpVideoLink = videoLinkList.map((x) => {
        return {
          actionItemFileId: x.actionItemFileId,
          actionItemId: x.actionItemId,
          sharepointVideoLink: x.sharepointVideoLink,
        };
      });
      setvideoLink(tmpVideoLink);
    }
    if (fileList !== undefined && fileList.length != MaxCharacterCount.Empty) {
      let tmpFileList = [...tempDocumentFile];
      tmpFileList = fileList.map((x) => {
        return {
          path: x.path,
          fileName: x.fileName,
          fileMediaId: x.fileMediaId,
          createdOn: formatDateTime(x.createdOn),
          createdBy: x.createdByName,
        };
      });
      setTempDocumentFile(tmpFileList);

    }
  }, [type]);

  useEffect(() => {
    return getvideoLinkList(videoLink, deleteVideoLinkList);
  }, [videoLink]);
  useEffect(() => {
    if (clearAttachments) {
      setDocumentFile([]);
      setTempDocumentFile([]);
      setdeleteFileList([]);
      setdeleteVideoLinkList([]);
      setvideoLink([]);
      setvideoLinkValue([]);
    }
  });

  useEffect(() => {
    return maxFileSizeLimit(maxFileSizeLimitMsg);
  }, [maxFileSizeLimitMsg]);
  /* <summary>
  date: 6-10-2023
  Name: Neychelle Godinho
  description: removes the file from the list anf file input
  <summary>*/

  // const OnClearSingleFileCapsule = (index) => {
  //   const tempList = [...tempDocumentFile];
  //   let deleteList = [...deleteFileList];
  //   const deletedFile = tempList.splice(index, 1)[0];
  //   deleteList.push(deletedFile);
  //   setTempDocumentFile(tempList);
  //   setdeleteFileList(deleteList);
  //   const updatedDocumentFile = documentFile.filter((file, i) => i !== index);
  //   setDocumentFile(updatedDocumentFile);

  //   let totalFileSize = 0;

  //   updatedDocumentFile.forEach((x, index) => {
  //     totalFileSize = x.size + totalFileSize;
  //   });

  //   const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
  //   if (bytesToMegaBytes(totalFileSize) < 20) {
  //     SetMaxFileSizeLimitMsg(false);
  //   } else {
  //     SetMaxFileSizeLimitMsg(true);
  //   }
  // };
  const OnClearSingleFileCapsule = (index, event) => {
    event.preventDefault(); // Prevent default behavior

    const tempList = [...tempDocumentFile];
    let deleteList = [...deleteFileList];
    const deletedFile = tempList.splice(index, 1)[0];
    deleteList.push(deletedFile);
    setTempDocumentFile(tempList);
    setdeleteFileList(deleteList);
    const updatedDocumentFile = documentFile.filter((file, i) => i !== index);
    setDocumentFile(updatedDocumentFile);

    let totalFileSize = 0;

    updatedDocumentFile.forEach((x, index) => {
      totalFileSize = x.size + totalFileSize;
    });

    const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
    if (bytesToMegaBytes(totalFileSize) < 20) {
      SetMaxFileSizeLimitMsg(false);
    } else {
      SetMaxFileSizeLimitMsg(true);
    }
  };


  useEffect(() => {
    getFileList(documentFile, deleteFileList);
    if (deleteFileList.length > 0)
      handleEdit();
  }, [deleteFileList]);


  /* <summary>
  date: 6-10-2023
  Name: Neychelle Godinho
  description: preview of the file uploaded
  <summary>*/
  const OpenFile = (file) => {
    window.open(file);
  };
  const OpenBlobFile = (file) => {
    const url = URL.createObjectURL(file);
    window.open(url);
  };
  /* <summary>
  date: 6-10-2023
  Name: Neychelle Godinho
  description: sets the document file state on file change
  <summary>*/

  const HandleFileChange = (e) => {
    let chosenFiles = Array.prototype.slice.call(e.target.files);
    const allowedFiles = chosenFiles.filter((file) =>
      isValidFileType(file.type)
    );
    chosenFiles = allowedFiles;
    if (allowedFiles.length === MaxCharacterCount.Empty) {
      ShowToastMessage(
        CreateActionItemErrorMsg.AllowedFileFormatTypes,
        ToastMessageType.Fail
      );
      return;
    }

    const uploadedFile = [...documentFile];
    const fileUpload = [...tempDocumentFile];
    let invalidFile = [];
    let totalFileSize = 0;

    // Find the maximum order in the existing files
    const maxOrder =
      fileUpload.length > MaxCharacterCount.Empty
        ? Math.max(...fileUpload.map((file) => file.order))
        : 0;

    chosenFiles.forEach((x, index) => {
      let fileExists = tempDocumentFile.find((y) => y.fileName === x.name);

      if (!fileExists) {
        totalFileSize = x.size + totalFileSize;
        const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
        if (bytesToMegaBytes(totalFileSize) < 20) {
          uploadedFile.push(x);
          // handleDroppedFiles(x);
          //Comment to check-in
          let fileObj = {
            path: URL.createObjectURL(x),
            fileName: x.name,
            fileMediaId: 0,
            order: maxOrder + index + 1, // Assign the order based on the index
          };
          fileUpload.unshift(fileObj); // Add the new file at the beginning

          // fileUpload.push(fileObj);
          SetMaxFileSizeLimitMsg(false);

        } else {
          invalidFile.push(x.name);
          x.value = "";
          SetMaxFileSizeLimitMsg(true);
        }
      } else {
        // If the file already exists, append a letter to the file name
        let fileCount = 1;
        let newFileName = `${x.name}_${fileCount}`;
        while (tempDocumentFile.some((y) => y.fileName === newFileName)) {
          fileCount++;
          newFileName = `${fileCount}_${x.name}`;
        }
        uploadedFile.push(x);
        let fileObj = {
          path: URL.createObjectURL(x),
          fileName: newFileName,
          fileMediaId: 0,
          order: maxOrder + index + 1, // Assign the order based on the index
        };
        fileUpload.push(fileObj);
        SetMaxFileSizeLimitMsg(false);
      }
    });

    // Sort fileUpload array by fileName in ascending order
    // fileUpload.sort((a, b) => a.fileName.localeCompare(b.fileName));

    setTempDocumentFile(fileUpload);
    setDocumentFile(uploadedFile);
    handleEdit();
  };
  // const HandleFileChange = (e) => {
  //   debugger
  //   let chosenFiles = Array.prototype.slice.call(e.target.files);
  //   const allowedFiles = chosenFiles.filter((file) =>
  //       isValidFileType(file.type)
  //   );
  //   chosenFiles=allowedFiles;
  //   if (allowedFiles.length === MaxCharacterCount.Empty) {
  //       ShowToastMessage(CreateActionItemErrorMsg.AllowedFileFormatTypes,ToastMessageType.Fail)
  //       return;
  //   }
  //   const uploadedFile = [...documentFile];
  //   const fileUpload = [...tempDocumentFile];
  //   let invalidFile = [];
  //   let totalFileSize = 0;

  //   // Find the maximum order in the existing files
  //   const maxOrder =
  //       fileUpload.length > MaxCharacterCount.Empty
  //       ? Math.max(...fileUpload.map((file) => file.order))
  //       : 0;

  //  chosenFiles.forEach((x, index) => {
  //     let fileExists = tempDocumentFile.find((y) => y.fileName === x.name);

  //     if (!fileExists) {
  //         totalFileSize = x.size + totalFileSize;
  //         const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
  //         if (bytesToMegaBytes(totalFileSize) < 20) {
  //             let fileCount = 0;
  //             let newFileName = `${fileCount}_${x.name}`;
  //             while (tempDocumentFile.some((y) => y.fileName === newFileName)) {
  //                 fileCount++;
  //                 newFileName = `${fileCount}_${x.name}`;
  //             }
  //             uploadedFile.push(x);
  //             let fileObj = {
  //                 path: URL.createObjectURL(x),
  //                 fileName: newFileName,
  //                 fileMediaId: 0,
  //                 order: maxOrder + index + 1, // Assign the order based on the index
  //             };

  //             fileUpload.push(fileObj);
  //             SetMaxFileSizeLimitMsg(false);
  //         } else {
  //             invalidFile.push(x.name);
  //             x.value = "";
  //             SetMaxFileSizeLimitMsg(true);
  //         }
  //     } else {
  //         let fileCount = 1;
  //         let newFileName = `${fileCount}_${x.name}`;
  //         while (tempDocumentFile.some((y) => y.fileName === newFileName)) {
  //             fileCount++;
  //             newFileName = `${fileCount}_${x.name}`;
  //         }
  //         uploadedFile.push(x);
  //         let fileObj = {
  //             path: URL.createObjectURL(x),
  //             fileName: newFileName,
  //             fileMediaId: 0,
  //             order: maxOrder + index + 1, // Assign the order based on the index
  //         };
  //         fileUpload.push(fileObj);
  //         SetMaxFileSizeLimitMsg(false);
  //     }
  // });

  // fileUpload.sort((a, b) => a.fileName.localeCompare(b.fileName));

  // setTempDocumentFile(fileUpload);
  // setDocumentFile(uploadedFile);

  // };

  const RemoveVideoLink = (link) => {
    const array = videoLink.filter((x) => x.sharepointVideoLink != link);
    const deleteVideoList = videoLink.filter(
      (x) => x.sharepointVideoLink == link
    );
    setvideoLink(array);
    setdeleteVideoLinkList(deleteVideoList);
    handleEdit();
  };

  const HandleVideoLinkChange = (e) => {
    const { name, value } = e.target;
    setvideoLinkValue(value);
    // handleEdit();

    AddLocalStorageData("isDirty", true);
  };

  const AddVideoLink = () => {
    let tmpVideoList = [...videoLink];
    tmpVideoList.push({
      actionItemFileId: 0,
      actionItemId: 0,
      sharepointVideoLink: videoLinkValue,
    });

    setvideoLink(tmpVideoList);
    setvideoLinkValue("");
    handleEdit();
  };

  useEffect(() => { }, [tempDocumentFile]);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const time = `${hours}:${minutes}:${seconds}`;

    return `${day}/${monthName}/${year} ${time}`;
  };
  const regex = /<p dir="auto">1<br>(.*?)<\/p>/;
  const match = showFilesFrom ? showFilesFrom.match(regex) : "";

  let filename = "";
  if (match && match.length > 1) {
    filename = match[1];
  }
  const handleContextMenu = (event) => {
    // Check if the right-click target is not an image
    if (event.target.tagName !== 'IMG') {
      event.preventDefault();
      handlePasteFiles(event);
    }
  };
  return (
    <div>
      {/* <i onClick={handleContextMenu} className="fa fa-info-circle" aria-hidden="true" title="Right click to paste the copied image."></i> */}
      {DontShowInComment && (
        <div className="file-upload-sec" >
          <button className="paste-img-btn" onClick={handlePasteFiles}>Click to paste copied image</button>
          <label
            className="file-upload-btn"
            htmlFor="fileUpload"
            style={{ margin: "0px", fontWeight: "bold" }}
          >
            {/* <button className="file-upload-btn"> */}
            <input
              className="file-upload"
              id="fileUpload"
              type="file"
              accept={validFileFormatsReadable}
              name="documentFile"
              onChange={HandleFileChange}
              multiple
            // style={{ display: "none" }}
            />
            <div className="upload-icon" style={{ textAlign: 'center' }}
            // onContextMenu={handleContextMenu}
            >
              <img src="Assets/Images/cloud-upload-icon.png" alt="" />
            </div>
            <div className="file-upload-txt"
            //onContextMenu={handleContextMenu}
            >
              <span>Click or drag files to upload</span>
            </div>
            <div
            //onContextMenu={handleContextMenu }
            >
              <ul
                style={{
                  listStyleType: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  paddingLeft: "0px",
                }}
              >
                {type == "Create" &&
                  documentFile.map((file, index) => (

                    <li key={index}>
                      <div className="file-hover">
                        <div style={{ height: '70px', width: '100%' }}>
                          <div className="image-previ">
                            <a href={file.path} target="_blank" rel="noopener noreferrer">
                              <img title={file.fileName} style={{ height: '70px', width: '100%', objectFit: 'cover' }} src={URL.createObjectURL(file)} alt="" />
                            </a>
                          </div>
                        </div>
                        <button size="sm" style={{ cursor: "none" }}>
                          <img
                            style={{ width: "22px" }}
                            src={GetFileIcon(file.name)}
                          ></img>
                        </button>
                        <span
                          onClick={() => OpenBlobFile(file)}
                          style={{ marginRight: 5 }}
                        >
                          {" "}
                          {file.name}
                        </span>
                        <button className="delete-attachment" type="button" style={{ margin: 0, zIndex: 1002 }}>
                          <img
                            style={{ width: '16px' }}
                            title="Delete"
                            //onClick={() => OnClearSingleFileCapsule(index)}
                            onClick={(event) => OnClearSingleFileCapsule(index, event)}
                            src="../Assets/images/cross-icon.png"
                          ></img>
                        </button>
                      </div>
                    </li>
                  ))}

                {type == "Edit" &&
                  tempDocumentFile.map((file, index) => (
                    <li key={index}>
                      <div className="file-hover">
                        <div style={{ height: '70px', width: '100%' }}>
                          <div className="image-previ">
                            <a href={file.path} target="_blank" rel="noopener noreferrer">
                              <img title={file.fileName} style={{ height: '70px', width: '100%', objectFit: 'cover' }} src={file.path} alt="" />
                            </a>
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <button size="sm" style={{ cursor: "none" }}>
                            <img
                              style={{ width: "22px" }}
                              src={GetFileIcon(file.fileName)}
                            ></img>
                          </button>
                          <div
                            style={{ padding: "2px", zIndex: 1002 }}
                            onClick={() => OpenFile(file.path)}
                          >
                            <span
                              onClick={() => OpenFile(file.path)}
                              style={{ marginRight: 5 }}
                            >
                              {" "}
                              {file.fileName}
                            </span>
                            <br />
                            <span style={{ fontSize: "x-small", color: "grey" }}>
                              {file.createdBy}:-  {file.createdOn}
                            </span>
                          </div>
                          <button className="delete-attachment" type="button" style={{ margin: 0, zIndex: 1002 }}>
                            <img style={{ width: '16px' }}
                              title="Delete"
                              onClick={(event) => OnClearSingleFileCapsule(index, event)}
                              src="../Assets/images/cross-icon.png"
                              alt=""
                            ></img>
                          </button>
                          {/* <div className="image-preview">
                    <img src={file.path} alt="" />
                  </div> */}


                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
              {maxFileSizeLimitMsg && (
                <>
                  <ErrorField
                    show={maxFileSizeLimitMsg}
                    message={"The file limit exceeded 20MB"}
                  />
                  <br />
                </>
              )}
            </div>
            {/* </button> */}
            {/* <input
            id="fileUpload"
            type="file"
            accept={validFileFormatsReadable}
            name="documentFile"
            onChange={HandleFileChange}
            className="file"
            multiple
            style={{ display: "none" }}
          /> */}
            {/* <img
            className="upload-img"
            src="../Assets/images/Icon awesome-upload.png"
            width="18px"
            alt="Upload Icon"
          />
          {"    "}
          Choose File */}
          </label>
        </div>
      )}

      {/* {DontShowInComment && (
        <div className="file-attachment-btn" style={{ width: "136px" }}>
          <label
            className="attach-btn"
            htmlFor="fileUpload"
            style={{ margin: "0px" }}
          >
            <a
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://edotoffice.sharepoint.com/_layouts/15/sharepoint.aspx"
            >
              <img
                className="upload-img"
                src="../Assets/images/sharepoint-icon.png"
                width="18px"
                alt="Upload Icon"
              />
              {"    "}
              Sharepoint
            </a>
          </label>
        </div>
      )} */}
      {/* {DontShowInComment && (
        <div>
          <ul
            style={{
              listStyleType: "none",
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "0px",
            }}
          >
            {videoLink.map((link, index) => (
              <li key={index}>
                <div className="file-hover">
                  <span style={{ marginRight: 5 }}>
                    <a href={link.sharepointVideoLink} target="_blank">
                      <span className="truncate-text">
                        {link.sharepointVideoLink.slice(0, 20)}...
                      </span>
                      <span className="tooltiptext">
                        {link.sharepointVideoLink}
                      </span>
                    </a>
                  </span>
                  <button type="button" style={{ margin: 0 }}>
                    <img
                      title="Delete"
                      onClick={() => RemoveVideoLink(link.sharepointVideoLink)}
                      src="../Assets/images/cross-icon.png"
                    ></img>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )} */}
      {/* {DontShowInComment && (
       <div className="saveLink" style={{ width: "100%", display: "flex" }}> 
          <input
            className=" form-control saveLinkInput-class"
            id="saveLinkInput"
            name="saveLinkInput"
            onChange={(e) => {
              HandleVideoLinkChange(e);
            }}
            value={videoLinkValue}
            type="text"
          />
          {videoLinkValue != "" ? (
            <button
              style={{
                fontSize: "15px",
                backgroundColor: "transparent",
                border: "none",
              }}
              className="btn btn-secondary "
              id="saveLinkSubmit"
              onClick={() => AddVideoLink()}
              name="saveLinkSubmit"
            >
              <img
                src="Assets/images/AddIcons.png"
                style={{
                  width: "24px",
                  height: "25px",
                  marginRight: "4px",
                }}
              />
            </button>
          ) : (
            ""
          )}
        </div>
      )} */}

      <div className="" style={{ marginTop: '14px' }}>
        <div className="edit-task-accordion">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Attachment Links
                </button>
              </h2>

              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div>
                    <button className="sharepoint-upload">
                      <img src="Assets/images/sharepoint-logotype.png" alt="" />{" "}
                      Sharepoint
                    </button>
                  </div>

                  {/* <div className="mt-2">
                    <input
                      className="form-control custom-input-ns"
                      type="text"
                    />
                  </div>

                  <div className="mt-2">
                    <input
                      className="form-control custom-input-ns"
                      type="text"
                    />
                  </div> */}
                  {/* <div className="mt-2">
      {videoLink.map((link, index) => (
       
        <a style={{marginRight:"4px"}} href={link.sharepointVideoLink}>{link.sharepointVideoLink}{ "  "}</a>
        
      ))}
    </div> */}
                  <div className="linkSec mt-2">
                    {videoLink.map((link, index) => (
                      <div key={index} className="video-link-container">
                        <a target="_blank"
                          rel="noreferrer" // Add this attribute to mitigate security risk
                          className="video-link" style={{ marginRight: "4px" }} href={link.sharepointVideoLink}>{link.sharepointVideoLink}</a>
                        <button className="delete-button" type="button" style={{ margin: 0 }}>
                          <img className="delete-icon" style={{ width: '17px', height: '18px' }}
                            title="Delete"
                            onClick={() => RemoveVideoLink(link.sharepointVideoLink)}
                            src="../Assets/images/cross-icon.png"
                            alt="Delete"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                  {/* <div className="mt-2">
      <input
        className="form-control custom-input-ns"
        type="text"
        placeholder="Enter Link"
      />
    </div>                      */}
                  <div className="saveLink" style={{ width: "100%", display: "flex", marginTop: '5px' }}>
                    <input
                      className=" form-control saveLinkInput-class"
                      id="saveLinkInput"
                      name="saveLinkInput"
                      onChange={(e) => {
                        HandleVideoLinkChange(e);
                      }}
                      value={videoLinkValue}
                      type="text"
                    />

                    {videoLinkValue != "" ? (
                      <button
                        style={{
                          fontSize: "15px",
                          backgroundColor: "transparent",
                          border: "none"
                        }}
                        className="btn btn-secondary "
                        id="saveLinkSubmit"
                        onClick={() => AddVideoLink()}
                        name="saveLinkSubmit"
                      >
                        <img
                          src="Assets/images/AddIcons.png"
                          style={{
                            width: "24px",
                            height: "25px",
                            marginRight: "4px",
                          }}
                        />
                        {/* Add link */}
                      </button>

                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleFileUpload;

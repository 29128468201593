import WebServiceWrapper from "./WebServiceWrapper";

export const ActionItemService = {
  AddEditActionItem,
  GetProjectName,
  GetPriorityList,
  GetActionItemTypeList,
  GetAssigneeListByProjectId,
  UploadFiles,
  GetActionDetails,
  EditActionItem,
  SaveComments,
  GetRootCauseTypes,
  DeleteComment,GetActionItemParams,GetActionItemHistory,
GetWorkItemTypeFromDB,AddWorkItemType,GetDefaultTasks,DeleteWorkItemType
};

function AddEditActionItem(payload) {
  return WebServiceWrapper.PostWithHeader("/actionItem/AddEditActionItem", payload, 60000, {
    "Content-Type": "multipart/form-data",
  });
}

function SaveComments(data) {
  return WebServiceWrapper.Post("/actionItem/SaveComments", data);
}
function GetProjectName(projectId) {
  return WebServiceWrapper.Get(
    `/actionItem/GetProjectName?projectId=${projectId}`
  );
}
function GetPriorityList() {
  return WebServiceWrapper.Get("/actionItem/GetPriorityList");
}
function GetActionItemTypeList() {
  return WebServiceWrapper.Get("/actionItem/GetActionItemTypeList");
}
function GetAssigneeListByProjectId(projectId) {
  return WebServiceWrapper.Get(
    `/actionItem/GetAssigneeListByProjectId?projectId=${projectId}`
  );
}
function UploadFiles(payload) {
  return WebServiceWrapper.Post("/actionItem/UploadFiles", payload);
}
function GetActionDetails(actionItemId, projectId) {

  return WebServiceWrapper.Get(`/actionItem/GetActionDetails?actionItemId=${actionItemId}&projectId=${projectId}`);
}
function EditActionItem(payload) {
  return WebServiceWrapper.PostWithHeader("/actionItem/EditActionItem", payload, 1000000, {
    "Content-Type": "multipart/form-data",
  });
}

function GetRootCauseTypes() {
  return WebServiceWrapper.Get("/actionItem/GetRootCauseTypes");
}

function DeleteComment(workLogDetailId) {
  const data = { workLogDetailId: workLogDetailId }
  return WebServiceWrapper.Post("/actionItem/DeleteSavedWorkLogDetails", data);
}



function GetActionItemParams(key) {

  return WebServiceWrapper.Get(`/actionItem/GetActionItemParams?key=${key}`);
}
function GetActionItemHistory(actionItemId) {

  return WebServiceWrapper.Get(`/actionItem/GetActionItemHistory?actionItemId=${actionItemId}`);
}

function GetWorkItemTypeFromDB() {
  return WebServiceWrapper.Get("/actionItem/GetActionWorkItemType");
}

function AddWorkItemType(value,subValue) {
  const data = { value:value, subValue:subValue }
  return WebServiceWrapper.Get(`/actionItem/AddWorkItemType?values=${value}&subValues=${subValue}`);
  
}

function GetDefaultTasks(projectId) {
  return WebServiceWrapper.Get(`/actionItem/GetDefaultTasks?projectId=${projectId}`);
}
function DeleteWorkItemType(workItemId) {
  return WebServiceWrapper.Get(`/actionItem/DeleteWorkItemType?workItemId=${workItemId}`);
}

// function SaveMiscOtherValue(miscOtherValue, loggedInUser, miscOtherType) {
//   return WebServiceWrapper.Get(`/reports/SaveMiscOtherValue?miscOtherValue=${miscOtherValue}&loggedInUser=${loggedInUser}&miscOtherType=${miscOtherType}`);
// }

import React from "react";

const ErrorPage = () => {
        /* <summary>
        date: 15-05-2024
        Name: MS
        description: 404 Error page 
        <summary>*/ 
  return (
    <>
      {/* <main style={{ backgroundColor: "#ffffff", minHeight: "93vh" }}>
        <section className="error-page">
          <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="content-alignment">
              <div>
                <img
                  width="450px"
                  src="../Assets/images/404-error.jpg"
                  alt="Not Found"
                  height="450px" 
                />
              </div>
              <div className="content-pad">
                <h3 className="text-bold">Page Not Found</h3>
              </div>
              <div className="para-pad">
                <p>We're sorry, the page you requested could not be found</p>
              </div>
              <a href="/ManageProjects">
                <button className="save-project-btn">
                  Go to Main Page
                </button>
              </a>
            </div>
          </div>
        </section>
      </main> */}
       <title>404 Error</title>
  <section className="page-not-found-main">
    <img src="Assets/Images/404-img.png" alt="" />
    <div>
      <h1 className="page-not-found">Page Not Found</h1>
      <p className="brief-msg">
        We're sorry, the page you requested could not be found
      </p>
     
      <a href="/ManageProjects">
      <button className="home-btn">Go to Manage Projects</button>
              </a>
    </div>
  </section>
    </>
  );
};

export default ErrorPage;

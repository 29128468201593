import { GetLocalStorageData } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

function LoginUser(payload) {
  return WebServiceWrapper.Post("/login/AuthenticateUser", payload);
}

function ResetPassword(data) {
  return WebServiceWrapper.Post("/login/ResetPassword", data);
}

const UserLogout = () => {
  const loggedEmployeId =GetLocalStorageData("userId")
  const employeeId=loggedEmployeId?loggedEmployeId:0
  if (employeeId === 0) {
   
    return;
  }
  return WebServiceWrapper.Get(`/login/Logout?employeeId=${employeeId}`);
};
const SendEmailForPasswordRecovery = (email) => {
  return WebServiceWrapper.Get(`/login/SendEmailForPasswordRecovery?email=${email}`);
};

const GetEmployeeInfo = (newgUID) => {
  
  return WebServiceWrapper.Get(`/login/GetEmployeeInfo?newgUID=${newgUID}`);
};


const CheckEmail = (email,employeeId) => {
  return WebServiceWrapper.Get(`/login/CheckEmail?email=${email}&employeeId=${employeeId}`);
};

const SendWelcomeEmail = (email) => {
  return WebServiceWrapper.Get(`/login/SendWelcomeEmail?email=${email}`);
};
const CheckIfAuthorize = () => {
  return WebServiceWrapper.Get(`/login/CheckIfAuthorize`);
};
export const LoginService = {
  LoginUser,
  UserLogout,SendEmailForPasswordRecovery,ResetPassword,GetEmployeeInfo,CheckEmail,SendWelcomeEmail,CheckIfAuthorize
};


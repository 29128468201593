import React, { useState, useContext } from "react";
import { ManageUsersService } from "../WebApiServices/ManageUsers.Service";
import { ProjectService } from "../WebApiServices/Project.service";
import { HTTPResponse, ToastMessageType } from "../Helpers/Enums";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { ToastMessages } from "../Helpers/HelperText";
import { GetLocalStorageData } from "../Helpers/Utilities";

const UserStatusCell = ({
  active,
  employeeId,
  context,
  id,
  onStatusChange,
  onChanges,
}) => {
  const { ShowToastMessage } = useContext(AppContext);

  const { LogOut } = useUIContext();
  const [status, setStatus] = useState(active);

  const handleUserStatusToggle = async () => {
    const newStatus = !status;
    const userId = GetLocalStorageData("userId");

    try {
      let response;
      if (context === "ManageUsers") {
        response = await ManageUsersService.UpdateUserStatus(
          employeeId,
          newStatus,
          userId
        );
        // onStatusChange();

      } else if (context === "projects") {
        response = await ProjectService.UpdateProjectStatus(
          id,
          newStatus,
          userId
        );
        onChanges();
      }

      if (response && response.status === HTTPResponse.OK) {

        if (context === "ManageUsers") {
          if (response.data === "IsLead") {
            setStatus(false);
            ShowToastMessage(ToastMessages.UserIsLeadStatus, ToastMessageType.Fail);
          }
          onStatusChange();
          setStatus(newStatus);
        }
        else {
          onStatusChange();
          setStatus(newStatus);
        }
        onStatusChange();
        setStatus(newStatus);

      }
      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  return (
    <td
      className="toggle-col"
      style={{ borderBottom: "0px", paddingLeft: "0px" }}
    >
      <div
        className="col-4 col-md-4 col-lg-4 toggle-col"
        style={{ padding: "0px 0px", backgroundColor: "inherit" }}
      >
        <label className="toggle">
          <input
            className="toggle-input"
            type="checkbox"
            checked={status}
            onChange={handleUserStatusToggle}
          />

          <span
            className={status ? "toggle-label checked" : "toggle-label"}
            data-off=""
            data-on=""
            title={status ? "Active" : "Inactive"}
          />

          <span
            className={status ? "toggle-handle checked" : "toggle-handle"}
          />
        </label>
      </div>
    </td>
  );
};

export default UserStatusCell;

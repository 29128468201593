import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import "ag-grid-community/styles//ag-theme-material.css";
import "ag-grid-community/styles//ag-theme-balham.css";
import { useRef, useState } from "react";

export default function AGGrid(props) {
  const { gridOptions } = props;
  const [pageSize, setPageSize] = useState(10);

  const gridApiRef = useRef(null); // Storing gridApi

  // Function to handle page size change
  const onPageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    if (gridApiRef.current) {
      gridApiRef.current.paginationSetPageSize(newSize);
    }
  };

  // Function to capture gridApi onGridReady
  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    if (props.onGridReady) {
      props.onGridReady(params); // Call the original onGridReady if it's passed as a prop
    }
  };

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{
          height:
            props.height ?? props.isTimelog
              ? "670px"
              : props.isTimeTracker
              ? "590px"
              : props.isProject
              ? "600px"
              : "605px",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <AgGridReact
          // autoHeight={props.isMyTasks}

          paginationAutoPageSize={false}
          rowDragMultiRow={true} // Allow dropping multiple rows
          rowDragManaged={props.rowDragManaged} // Enable managed row dragging
          onRowDragStart={props.onRowDragStart} // Event handler for drag start
          onRowDragEnter={props.onRowDragEnter} // Event handler for drag enter
          onRowDragEnd={props.onRowDragEnd} // Event handler for drag end
          rowDragEntireRow={props.rowDrag}
          onRowClicked={props.onRowClicked}
          onGridReady={props.isProject ? onGridReady : props.onGridReady}
          suppressMenuHide={true}
          pagination={true}
          paginationPageSize={props.isProject ? pageSize : props.pagination}
          defaultColDef={{
            sortable: true,
            editable: false,
            unSortIcon: true,
            filter: true,
            resizable: true,
            autoHeight: props.autoHeight,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            textOverflow: "ellipsis",
            whiteSpace: "pre",
            overflow: "hidden",
          }}
          animateRows={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          gridOptions={gridOptions}
          rowData={props.rowData}
          columnDefs={props.colDefs}
          rowHeight={props.isTimelog ? 48.5 : props.isTimeTracker ? 48.5 : 50}
          //autoHeight={false}
          overlayNoRowsTemplate={props.overlayNoRowsTemplate}
          onSortChanged={props.onSortChanged}
          pinnedBottomRowData={props.pinnedBottomRowData}
        ></AgGridReact>
      </div>
      {props.isProject && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <label htmlFor="pageSizeSelector">Rows per page: </label>
            {console.log("llllllllllllllllllllllllll",props.rowData.length)}
            <select
              id="pageSizeSelector"
              value={pageSize}
              onChange={onPageSizeChange}
            >
                <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value={props.rowData.length}>Show All</option>
            </select>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
}

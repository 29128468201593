import React, { useState, useEffect, useContext } from "react";
import { ToastMessages, LoginPageText, LoginErrorMsg } from "../Helpers/HelperText";
import {
  AddLocalStorageData,
  IsEmailValid,
  isStringEmpty,
  changePageTitle,
  RemoveLocalStorageData
} from "../Helpers/Utilities";
import ErrorField from "../Components/ErrorField";
import {
  HTTPResponse,
  MaxCharacterCount,
  ToastMessageType,
} from "../Helpers/Enums";
import { LoginService } from "../WebApiServices/Login.service";
import { AppContext } from "../Contexts/AppContextProvider";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../Components/ForgotPassword"

const Login = () => {
  const { SetProfileImage } = useContext(AppContext);
  const { SetRoleId } = useContext(AppContext);
  const { ToggleLoader } = useContext(AppContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [pastedUrl, setPastedUrl] = useState(false)
  const [creds, setCreds] = useState({
    emailId: "",
    password: "",
  });
  const [fieldErrors, setFieldErrors] = useState({
    emailError: false,
    passwordError: false,
    MaxAttempts: false,
    AccountBlocked: false
  });
  const { ShowToastMessage } = useContext(AppContext);
  const HandleEmailChange = (e) => {
    setCreds((prev) => ({
      ...prev,
      emailId: e.target.value,
    }));
    setFieldErrors(false);
  };
  const HandlePasswordChange = (e) => {
    setCreds((prev) => ({
      ...prev,
      password: e.target.value,
    }));
    setFieldErrors(false);
  };
  const HandleSubmit = (data) => {

    if (Validate()) {
      LoginUser();
    }
  };
  const Validate = () => {
    const { emailId, password } = creds;
    let validate = true;
    if (isStringEmpty(emailId)) {
      setFieldErrors((prev) => ({
        ...prev,
        emailError: LoginPageText.EmailRequired,
      }));
      validate = false;
    } else if (!IsEmailValid(emailId)) {
      setFieldErrors((prev) => ({
        ...prev,
        emailError: LoginPageText.InvalidEmail,
      }));
      validate = false;
    }
    if (isStringEmpty(password)) {
      setFieldErrors((prev) => ({
        ...prev,
        passwordError: LoginPageText.PasswordRequired,
      }));
      validate = false;
    }
    return validate;
  };
  /* <summary>
  date: 12-03-2024
  Name: Mustakim Shaikh
  description: to disable the login after 5 failed login attempts.
  <summary>*/
  const [time, setTime] = useState(false)
  const LoginUser = async () => {
    try {
      ToggleLoader(true);
      let response = await LoginService.LoginUser({
        Email: creds.emailId,
        Password: creds.password,
      });
  
      const { data, status } = response;
      if (status && status === HTTPResponse.OK && data.isActive != undefined && !data.isActive) {

        ToggleLoader(false);
        ShowToastMessage(LoginPageText.Inactive, ToastMessageType.Fail);
      }
      else if (data.loggedIn && data.fullName == "MaxAttempts") {

        ToggleLoader(false);

        setFieldErrors((prev) => ({
          ...prev, MaxAttempts: true
        }))

      }
      else if (data == "Account Locked") {
        ToggleLoader(false);
        //ShowToastMessage("Your account is blocked", ToastMessageType.Fail);
        setFieldErrors((prev) => ({
          ...prev, AccountBlocked: true
        }))

      }
      else {
        switch (status) {
          case HTTPResponse.OK: {
            ToggleLoader(false);
            SetRoleId(data.userRole);
            SetProfileImage(data?.imagePath);
            AddLocalStorageData("roleID", data.userRole);
            AddLocalStorageData("userName", data.fullName);
            AddLocalStorageData("color", data.color);
            AddLocalStorageData("userRole", data.userRole);
            AddLocalStorageData("email", data.email);
            AddLocalStorageData("teamId", data.teamId);
            AddLocalStorageData("projectsLead", data.projectsLeading);
            AddLocalStorageData("userId", data.employeeId);
            AddLocalStorageData("imagePath", data?.imagePath);

            const pastedLink = localStorage.getItem("lastVisitedUrl");
            const trimmedLink = pastedLink ? pastedLink.substring(pastedLink.indexOf("/", pastedLink.indexOf("/", pastedLink.indexOf("/") + 1) + 1)) : null

            // Remove the stored URL from local storage
            localStorage.removeItem("lastVisitedUrl");

            // Navigate to the trimmed URL
            if (trimmedLink !== null) {
              navigate(trimmedLink);
            } else {
              navigate("/ManageProjects");
            }
            break;
          }
          case HTTPResponse.BadRequest: {
            ToggleLoader(false);
            ShowToastMessage(LoginPageText.LoginFailure, ToastMessageType.Fail);
            break;
          }
          case HTTPResponse.InternalServerError:
          default: {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
            break;
          }
        }
      }


    } catch {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const [animatedClass, setAnimatedClass] = useState("");


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      LoginUser();
    }
  };

  useEffect(() => {
    RemoveLocalStorageData("lastVisitedUrl")
    //  localStorage.removeItem("lastVisitedUrl")
    changePageTitle("Login");
    const timeoutId = setTimeout(() => {
      setAnimatedClass("aos-init aos-animate");
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  const OpenForgetPasswordModal = () => {
    setForgotPassword(true);
  };
  const onClose = () => {
    setForgotPassword(false);
  }
  const year = new Date().getFullYear()
  return (
    <div className="mainn login-page">
      {forgotPassword &&
        (
          <ForgotPassword onClose={onClose} />
        )
      }
      <main id="main ">
        <section id="" className="">
          <div className={`container animatedClass`} data-aos="fade-up">
            <div
              className={`row  animatedClass`}
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <div
                className="wrap-login100"
                style={{ padding: "30px" }}
              >
                <div className="login100-form">
                  <div className="login100-form-title p-b-49">Login</div>
                  <div
                    className="wrap-input100 validate-input m-b-23"
                    data-validate="Email is reauired"
                    style={{ marginBottom: " 5px" }}
                  >
                    <div className="label-input100"><label>Username</label></div>
                    <input
                      className="input100"
                      type="text"
                      placeholder={LoginPageText.EmailPlaceholder}
                      value={creds.emailId}
                      name="emailId"
                      onKeyDown={handleKeyDown}
                      onChange={HandleEmailChange}
                      maxLength={MaxCharacterCount.LoginEmail}
                    />
                    <i
                      className="fa fa-envelope"
                      style={{
                        position: "absolute",
                        top: "46px",
                        left: "10px",
                        fontSize: "16pt",
                        color: "gray"
                      }}
                    ></i>

                  </div>
                  {fieldErrors.emailError && (
                    <ErrorField
                      show={fieldErrors.emailError}
                      message={fieldErrors.emailError}
                    />
                  )}
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Password is required"
                    style={{ marginTop: "5px", position: "relative" }}
                  >
                    <div className="label-input100"><label>Password</label></div>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="input100"
                      name="password"
                      placeholder={LoginPageText.PasswordPlaceholder}
                      value={creds.password}
                      onKeyDown={handleKeyDown}
                      onChange={HandlePasswordChange}
                      maxLength={MaxCharacterCount.LoginPassword}
                      onPaste={(e) => e.preventDefault()}  // Disable pasting
                    />
                    <span onClick={() => setShowPassword(!showPassword)}>
                      {" "}
                      <i
                        className={
                          showPassword
                            ? "fa fa-eye eye-icon"
                            : "fa eye-icon fa-eye-slash"
                        }
                        style={{
                          position: "absolute",
                          right: "16px",
                          top: "48px",
                          fontSize: "16pt",
                          color: "gray"
                        }}
                        id="togglePassword"
                      ></i>{" "}
                    </span>
                    <i
                      className="fa fa-lock"
                      style={{
                        position: "absolute",
                        top: "47px",
                        left: "14px",
                        fontSize: "19pt",
                        color: "gray"
                      }}
                    ></i>


                  </div>
                  {fieldErrors.passwordError && (
                    <ErrorField
                      show={fieldErrors.passwordError}
                      message={fieldErrors.passwordError}
                    />
                  )}
                  <div className="text-right p-t-8 p-b-31 forgotPassword">
                    <a
                      className="forgotPassword"
                      style={{ cursor: "pointer" }}
                      onClick={OpenForgetPasswordModal}
                    >
                      <label> Forgot password?</label>
                    </a>
                  </div>
                  <div className="container-login100-form-btn">
                    <div className="wrap-login100-form-btn">
                      <div className="login100-form-bgbtn" />
                      <button
                        disabled={time}
                        className="login100-form-btn"
                        onClick={HandleSubmit}
                      >
                        Login
                      </button>


                    </div>
                  </div>
                  {!fieldErrors.AccountBlocked && fieldErrors.MaxAttempts && (
                    <ErrorField style={{ textWrap: 'wrap' }}
                      show={fieldErrors.MaxAttempts}
                      message={LoginErrorMsg.FiveAttempts}
                    />
                  )}

                  {fieldErrors.AccountBlocked && (
                    <ErrorField style={{ textWrap: 'wrap' }}
                      show={fieldErrors.AccountBlocked}
                      message={LoginErrorMsg.AccountBlocked}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="footer-text">
              <div />
              <p style={{ color: "white", marginBottom: 0 }}>
                © {year} <a style={{ color: "rgb(103 181 172)", textDecoration: "none" }} href="https://www.edot-solutions.com/" target="_blank">eDOT-Solutions</a>. All rights reserved.
                <br />
              </p>
            </div>
          </div>

        </section>
      </main>
    </div >

  );
};

export default Login;

import React from "react";

const LogOutModal = ({ showDeleteModal, onYesClick, onNoClick,completedSprintCounts }) => {
  
    return (<>
        <section>
            <div className="deletedoc">
                <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
                <div className="modal modal-depth" id="deleteDoc-modal" style={{ display: showDeleteModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-l modal-dialog-centered modal-box">
                        <div className="modal-content box">
                            <div className="modal-body" style={{marginBottom:"0px"}}>
                                <div className="block1">
                                 {completedSprintCounts==0 || completedSprintCounts ? ` A total of ${completedSprintCounts} work items are released in this release.` :" Are you sure you want to logout?"} 
                                </div>
                                <div className="text-center yes-no-btn">
                                    <div > <button type="button" className="btn btn-background-color yes-btn" onClick={onYesClick}> {completedSprintCounts>=0 ?"OK":"Yes"}</button></div>
                                    
                                    {completedSprintCounts>=0 ?"":   <div ><button type="button" className="btn btn-background-color  no-btn" onClick={onNoClick}> No</button></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> </>
    );
};

export default LogOutModal;
import React, { useEffect, useContext, useState } from "react";
import {
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { AppReducerActions } from "../ReducerActions/AppReducerActions";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { PAGE_ROUTE_ID, ToastMessageType } from "../Helpers/Enums";
import { BreadToastMsg } from "../Helpers/HelperText";
import { AddLocalStorageData, GetLocalStorageData, RemoveLocalStorageData } from "../Helpers/Utilities";
import DeleteModal from "./DeleteModal";

const BreadcrumbComponent = ({ projectName, title, projectID }) => {
  
  const { appState, dispatchUIAction, changePageData } = useUIContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const pageParams = useParams();
  const { ShowToastMessage } = useContext(AppContext);
  const [showDirtyPopup, setShowDirtyPopup] = useState(false);
  const [path, setPath] = useState('');


  useEffect(() => {
    dispatchUIAction({
      type: AppReducerActions.UPDATE_BREADCRUMBS,
      pageName: location.pathname,
      projectName: projectName ? "/" + projectName : "",
      title: title ? "/" + title : "",
    });

    changePageData(false);
  }, [projectName, title]);

  const getRoutePath = (item) => {
    let routePath = item.route;
    let projectId = searchParams.get("ProjectId");
    let disabled = searchParams.get("disabled");
    switch (item.id) {
      case PAGE_ROUTE_ID.DASHBOARD:
        break;

      case PAGE_ROUTE_ID.OVERVIEW:
        routePath = `${item.route}/${pageParams.projectId || projectID || projectId || ""}`;
        break;

      case PAGE_ROUTE_ID.EDIT_ACTION_ITEM:
        const title = pageParams.actionItemIds || "";

        routePath = `${item.route}/${title}`;
        break;

      default:
        break;
    }
    return routePath;
  };

  const NavigateToSpecificPage = async (item) => {
    
    let isDirty = GetLocalStorageData("isDirty");
    if (isDirty === true) {
      let routePath = getRoutePath(item);
      const fullUrl = `${window.location.origin}${routePath}`;
      setShowDirtyPopup(true);
      setPath(fullUrl);
      AddLocalStorageData("pathvalue", fullUrl);
    } else {

      const url = window.location.href;
      //const [baseUrl, queryString] = url.split('?');
      //const queryParams = `?${queryString}`;

      let routePath = getRoutePath(item);

      if (location.pathname.includes(routePath)) {
        return;
      }

      const fullUrl = `${window.location.origin}${routePath}`;
      window.location.href = fullUrl;
      changePageData(false);
    }
  };
  const handleCopyLink = (e, item) => {
    e.preventDefault();
    const routePath = getRoutePath(item);
    let fullUrl = `${window.location.origin}${routePath}`;
    if (routePath.includes("EditActionItem")) {
      const url = window.location.href;
      const [baseUrl, queryString] = url.split('?');
      const queryParams = `?${queryString}`;
      fullUrl = `${window.location.origin}${routePath}`;
      // if (queryString !== undefined) {
      //   fullUrl += queryParams;
      // }
    }
    navigator.clipboard.writeText(fullUrl)
      .then(() => {
        ShowToastMessage(BreadToastMsg.LinkCopied, ToastMessageType.Success);
      })
      .catch((err) => {
        ShowToastMessage(BreadToastMsg.Failed, ToastMessageType.Fail);
      });
  };

  const onDirtyModalCloseClick = () => {
    // this.setState({
    //     showDirtyPopup: false,
    // });
    setShowDirtyPopup(false);
  };

  const onProceedClick = () => {
    
    setShowDirtyPopup(false);
    AddLocalStorageData("isDirty", false);
    let pathToNavigate = GetLocalStorageData("pathvalue");
    if (pathToNavigate !== undefined && pathToNavigate !== "" && pathToNavigate !== null) {
      window.location.href = pathToNavigate;
    }
  };

  return (
    <section className="breadcrumb-section" style={{ backgroundColor: "#b5a5c4" }}>
      <div className="container-fluid" >
        <ul className="breadcrumb">
          {showDirtyPopup && (
            <DeleteModal
              showDeleteModal={showDirtyPopup}
              isDirtyModal={true}
              onNoClick={onDirtyModalCloseClick}
              onYesClick={onProceedClick}
            />
          )}
          {appState.breadCrumbs.map((item) => {
            return (

              <li
                key={item.id}
                style={{
                  display: item.isActive ? "block" : "none",
                }}
                /* <summary>
                date: 21-03-2024
                Name: Mustakim Shaikh
                description:on right click it copies the link of clicked breadcrumb type.
                <summary>*/
                onClick={() => NavigateToSpecificPage(item)}
                onContextMenu={(e) => handleCopyLink(e, item)}
              >
                {item.pageName && item.pageName.split("/").map((segment, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <span className="separator">/&nbsp;</span>}
                    {location.pathname.includes("/ManageProjects") || location.pathname.includes("/MyTasks") ||
                      location.pathname.includes("/ManageUsers") || location.pathname.includes("/TimeLog") ?
                      <span style={{ color: "#56158b", fontFamily: 'Lato-Regular', fontWeight: "bold" }}>
                        {segment}</span> :
                      <a
                        href={getRoutePath(item)}
                        onClick={(e) => {
                          e.preventDefault();
                          NavigateToSpecificPage(item);
                        }}
                      >
                        {segment}
                      </a>}
                  </React.Fragment>))}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default BreadcrumbComponent;





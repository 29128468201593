import { useState } from "react";

const UserCommonCell = ({
  setEditUser,
  data,
  fieldName,
  onEditClicked,
  color,
}) => {

  const [showPassword, setShowPassword] = useState(false);

  
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
     

      <div style={{ padding: "5px 0px", userSelect: "text" }}>
        {fieldName === "EditButton" ? (
          <button
            data-bs-toggle="modal"
            data-bs-target="#userManagementModal"
            className="edit-button"
            onClick={() => {
              onEditClicked(data);
              setEditUser(data);
            }}
            title="Edit"
          >
            <i className="fa fa-2x fa-pencil-square-o" aria-hidden="true" />
          </button>
        ) : (
          <div>
            {fieldName === "fullName" ? (
             
                <div className="fullnames" style={{ alignItems: "center" }}>
                  <span style={{ color: "black" }}>{data[fieldName]}</span>
                </div>
             
            ) : fieldName === "roleName" ? (
              data[fieldName]
            ) : fieldName === "teamName" ? (
              data[fieldName]
            ) : fieldName === "password" ? (
              showPassword ? (
                <button onClick={handleShowPassword}>{data[fieldName]}</button>
              ) : (
                <button
                  onClick={handleShowPassword}
                  style={{ cursor: "pointer", alignContent: "center" }}
                >
                  <h1>•••••••</h1>
                </button>
              )
            ) : (
              data[fieldName]
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserCommonCell;

const ErrorField = ({
  show = true,
  message,
  style = { color: "#e30808", fontSize: "15px", fontWeight: "400",textWrap:'nowrap' },
}) => {
  if (!show) {
    return <></>;
  }

  return (
    <div className="error-input">
      <span style={style}>{message} </span>
    </div>
  );
};

export default ErrorField;

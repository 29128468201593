import { data } from "jquery";
import WebServiceWrapper from "./WebServiceWrapper";

export const ReportService = {

  SaveDailyReports,
  GetaddEditReportsDetails,
  GetAllActionItems, GetTaskDetails, SaveTimeLog, GetMiscellaneousTimeLog,
  GetWorklogDetail,
  GetWorklogDropDownData,
  DeleteWorkLogData,
  GetWorklogDetailById,
  GetTaskDetailsByWorkLogId,
  GetTaskDetailsForAdd,
  GetMyTasksForAdmin,
  UpdateActionItemAssignedOnDate,
  DeleteTaskForMyTask,
  SaveMiscOtherValue,
  DeleteMiscOthers,
};



function GetaddEditReportsDetails(EmployeeID, reportDate) {
  return WebServiceWrapper.Get(`/reports/GetaddEditReportsDetails?EmployeeID=${EmployeeID}&ReportDate=${reportDate}`);
}

function SaveDailyReports(data) {

  return WebServiceWrapper.PostWithHeader(`/reports/SaveDailyReports`, data, 6000, { "Content-Type": "multipart/form-data" })

}


function GetAllActionItems(projectId) {
  return WebServiceWrapper.Get(`/reports/GetAllActionItems?ProjectId=${projectId}`);
}

function GetTaskDetailsForAdd(actionItemId, employeeId) {

  return WebServiceWrapper.Get(`/reports/GetTaskDetailsOnAdd?actionItemId=${actionItemId}&employeeId=${employeeId}`);
}

function GetTaskDetails(actionItemId, workLogId, employeeId) {

  return WebServiceWrapper.Get(`/reports/GetTaskDetails?actionItemId=${actionItemId}&workLogId=${workLogId}&employeeId=${employeeId}`);
}


function SaveTimeLog(data) {
  return WebServiceWrapper.Post("/reports/SaveTimeLog", data);
}
function GetMiscellaneousTimeLog(employeeId,addEditReport) {

  return WebServiceWrapper.Get(`/reports/GetMiscellaneousTimeLog?employeeId=${employeeId}&addEditReport=${addEditReport}`);
}

function GetWorklogDetail(employeeId, roleId) {
  return WebServiceWrapper.Get(`/reports/GetWorklogDetail?EmployeeId=${employeeId}&RoleId=${roleId}`);
}

function GetWorklogDropDownData(EmployeeID, roleId, isKey,deletedProject) {
  return WebServiceWrapper.Get(`/reports/GetWorklogDropDownData?EmployeeID=${EmployeeID}&RoleId=${roleId}&isKey=${isKey}&deletedProject=${deletedProject}`);
}

function DeleteWorkLogData(workLogId) {
  return WebServiceWrapper.Get(`/reports/DeleteWorkLogDataByID?WorkLogID=${workLogId}`);
}


function GetWorklogDetailById(employeeId, workLogId, roleId) {
  return WebServiceWrapper.Get(`/reports/GetWorklogDetailById?EmployeeId=${employeeId}&workLogId=${workLogId}&RoleId=${roleId}`);
}


function GetTaskDetailsByWorkLogId(actionItemId, workLogId, employeeId) {

  return WebServiceWrapper.Get(`/reports/GetTaskDetailsByWorkLogId?actionItemId=${actionItemId}&workLogId=${workLogId}&employeeId=${employeeId}`);
}

function GetMyTasksForAdmin() {

  return WebServiceWrapper.Get(`/reports/GetMyTasksForAdmin`);
}

function DeleteTaskForMyTask(tasIdToRemove) {
  return WebServiceWrapper.Get(`/reports/DeleteTaskForMyTask?actionItemToDelete=${tasIdToRemove}`);
}

function UpdateActionItemAssignedOnDate(data) {
  return WebServiceWrapper.Post(`/reports/UpdateActionItemAssignedOnDate`, data)
}

function SaveMiscOtherValue(miscOtherValue, loggedInUser, miscOtherType) {
  // return WebServiceWrapper.Get(`/reports/SaveMiscOtherValue?miscOtherValue=${miscOtherValue}&loggedInUser=${loggedInUser}&isFromTrainingOther=${isTrainingOtherSelcted}&isFromMiscOther=${isMiscOtherSelected}`);
  return WebServiceWrapper.Get(`/reports/SaveMiscOtherValue?miscOtherValue=${miscOtherValue}&loggedInUser=${loggedInUser}&miscOtherType=${miscOtherType}`);
}


function DeleteMiscOthers(miscOthersId) {
  return WebServiceWrapper.Get(`/reports/DeleteMiscellenousOther?miscIdToDelete=${miscOthersId}`);

}
import React from "react";

const year=new Date().getFullYear()
const Footer = () => {
    return (
      
        <section className="admin-footer admin-footer-responsive">
          <div className="container-fluid">
            <div className="contactus-footer">
              <p>© {" "}
                {year}{" "}<a style={{ color: "rgb(103 181 172)", textDecoration: "none" }} href="https://www.edot-solutions.com/" target="_blank">eDOT-Solutions</a>. All Rights Reserved.</p>

            </div>
          </div>
        </section>
    );
  }
export default Footer;

export const AppReducerActions = {
  SHOW_TOAST: "SHOW_TOAST",
  SET_PROFILE_IMAGE: "SET_PROFILE_IMAGE",
  TOGGLE_LOADER: "TOGGLE_LOADER",
  SET_PROJECTID: "SET_PROJECTID",
  SET_REPORTID: "SET_REPORTID",
  SET_ACTIONITEM_ID: "SET_ACTIONITEM_ID",
  UPDATE_BREADCRUMBS: "UPDATE_BREADCRUMBS",
  PAGE_DATA_CHANGED: "PAGE_DATA_CHANGED",
  SET_ACTION_ITEM_CLEAR: "SET_ACTION_ITEM_CLEAR",
  SET_IS_DIRTY: "SET_IS_DIRTY",
};

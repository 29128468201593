import React, { useContext } from "react";
import { AppContext } from "../Contexts/AppContextProvider";

const ToastComponent = () => {
  const { appState } = useContext(AppContext);

  return (
    appState.showToast && (
      <div
        className="top-right"
        style={{
          minWidth: "250px" /* Set a default minimum width */,
          marginLeft: "-125px" /* Divide value of min-width by 2 */,
          color: "#fff" /* White text color */,
          textAlign: "center" /* Centered text */,
          borderRadius: "2px" /* Rounded borders */,
          padding: "16px" /* Padding */,
          position: "fixed" /* Sit on top of the screen */,
          zIndex: 999999999999/* Add a z-index if needed */,
          left: "60%" /* Center the snackbar */,
          bottom: "30px" /* 30px from the bottom */,
          boxShadow: "0px 0px 6px 2px #464646",
          background: appState.toastType
            ? "rgb(255, 0, 0)"
            : "rgb(25,135,84)",
          transform: "translateX(-50%)"
          /* Center horizontally */
          ,
        }}
      >
        {appState.toastMessage}
      </div>

    )
  );
};

export default ToastComponent;

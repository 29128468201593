//this file will contain wrapper functions for http get/post.
import axios from "axios";
import { Config } from "../Helpers/Config";
import {
  DecryptUsingAES256,
  EncryptUsingAES256,
  isStringEmpty,
} from "../Helpers/Utilities";
const defaultTimeout = 60000;
let postServiceTimeout = null;
let postServiceWithHeaderTimeout = null;
let getServiceTimeout = null;

class WebServiceWrapper {
  constructor() {
    let service = axios.create();
    // Add a request interceptor
    service.interceptors.request.use(this.HandleRequest, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    service.interceptors.response.use(
      this.HandleSuccessResponse,
      this.HandleErrorResponse
    );
    service.defaults.timeout = defaultTimeout;
    this.service = service;
  }
  buildUrl = (path) => {
    return `${Config.apiUrl}${path}`;
  };
  buildLongRunUrl = (path) => {
    return `${Config.longRunningUrl}${path}`;
  };

  /* <summary>
  date: 31-07-2023
  Name: GM
  description: Encrypt the request
  <summary>*/
  HandleRequest(req) {
    const ExcludeURLList = [
      "https://example.com/api/Common/commonFileuploaddata",
      "https://example.com/api/Users/UploadProfilePicture",
    ];
    //The avove array of url can be kept empty

    // Do something before request is sent

    let exludeFound = ExcludeURLList.filter((element) => {
      return req.url.includes(element);
    });

    if (!(exludeFound && exludeFound.length > 0)) {
      // const BASE_URL = "https://localhost:44406/"
      if (req.method == "get") {
        if (req.url.indexOf("?") > 0) {
          let encriptURL =
            req.url.substr(0, req.url.indexOf("?") + 1) +
            EncryptUsingAES256(
              req.url.substr(req.url.indexOf("?") + 1, req.url.length)
            );
          let cloneReq = req;
          cloneReq.url = encriptURL;
          return cloneReq;
        }
        return req;
      } else if (req.method == "post") {
        if (req.url.indexOf("?") > 0) {
          let cloneReq = req;
          let payload = EncryptUsingAES256(JSON.stringify(req.data));
          let encryptedPayload = {
            payload,
          };
          cloneReq.data = encryptedPayload;
          return cloneReq;
        } else if (
          req.header &&
          req.header["Content-Type"] == "multipart/form-data"
        ) {
          var object = {};

          var formData = new FormData();

          req.data.forEach((value, key) => {
            if (value instanceof File) {
              //add files directly to formdata

              formData.append(key, value);
            } else {
              //assign the property values to encrypt in next step

              object[key] = value;
            }
          });

          var json = JSON.stringify(object);

          let cloneReq = req;

          let payload = EncryptUsingAES256(json);

          formData.append("payload", payload);

          cloneReq.data = formData;

          return cloneReq;
        } else if (req.data || req.data.length > 0) {
          let cloneReq = req;

          let payload = EncryptUsingAES256(JSON.stringify(req.data));

           let encryptedPayload = {
            payload,
          };

          cloneReq.data = encryptedPayload;

          return cloneReq;
        }
        return req;
      }
    }

    return req;
  }

  /* <summary>
    date: 29-11-2022
    Name: Gm
    description: Handle Success Response from WebAPI
    <summary>*/
  HandleSuccessResponse(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const ExcludeURLList = [
      "https://example.com/api/Common/commonFileuploaddata",
      "https://example.com/api/Users/UploadProfilePicture",
      "https://example.com/api/Common/downloadattachedfile",
      // "https://localhost:44406/weatherforecast/GetForecastData"
      Config.apiUrl + "/reports/GenerateUserWorkLogReport",
    ];

    // Do something before request is sent
    let exludeFound = ExcludeURLList.filter((element) => {
      return response.config.url.includes(element);
    });

    if (!(exludeFound && exludeFound.length > 0)) {
      let cloneRes = response;

      let decryptedData = isStringEmpty(response.data)
        ? ""
        : JSON.parse(DecryptUsingAES256(response.data));
      cloneRes.data = decryptedData;
      let responsePayload = {
        status: 200,
        error: null,
        data: cloneRes.data,
        headers: cloneRes.headers,
      };
      return responsePayload;
    }

    let responsePayload = {
      status: 200,
      error: null,
      data: response.data,
      headers: response.headers,
    };
    return responsePayload;
  }

  /* <summary>
   date: 31-07-2023
    Name: Gm
    description: Handle  Error response from WebAPI 
    <summary>*/

  HandleErrorResponse = (error) => {
    try {
      let response = {
        status: error.response !== undefined ? error.response.status : error,
        data: error.response,
        error,
      };
      return response;
    } catch (error) {
      let response = {
        status: 500,
        data: null,
        error,
      };
      return response;
    }
  };

  async Get(path, timeout, responseType) {
    clearTimeout(getServiceTimeout);
    let source = axios.CancelToken.source();
    getServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    const response = await this.service.get(
      this.buildUrl(path),
      { responseType: responseType == undefined ? "json" : responseType },
      {
        cancelToken: source.token,
      }
    );
    clearTimeout(getServiceTimeout);
    getServiceTimeout = null;
    return response;
  }

  async Post(path, payload, timeout) {
    clearTimeout(postServiceTimeout);
    let source = axios.CancelToken.source();
    postServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    let response = await this.service.request({
      method: "POST",
      url: this.buildUrl(path),
      responseType: "json",
      data: payload,
    });
    clearTimeout(postServiceTimeout);
    postServiceTimeout = null;
    return response;
  }

  /* <summary>
    date:  31-07-2023
    Name: Gm
    description: Post API Call with custom Header Data
    <summary>*/
  async PostWithHeader(path, payload, timeout, header) {
    clearTimeout(postServiceWithHeaderTimeout);
    let source = axios.CancelToken.source();
    postServiceWithHeaderTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );
    let response = await this.service.request({
      method: "POST",
      url: this.buildUrl(path),
      responseType: "json",
      data: payload,
      cancelToken: source.token,
      header,
    });
    postServiceWithHeaderTimeout = null;
    return response;
  }

  async DownloadBlob(path, timeout) {
    clearTimeout(getServiceTimeout);
    let source = axios.CancelToken.source();
    getServiceTimeout = setTimeout(
      () => {
        source.cancel("timeout error");
      },
      timeout === undefined ? defaultTimeout : timeout
    );

    const url = this.buildUrl(path);
    const response = await this.service.get(url, {
      cancelToken: source.token,
      responseType: "blob",
    });
    clearTimeout(getServiceTimeout);
    getServiceTimeout = null;
    return response;
  }
}

export default new WebServiceWrapper();

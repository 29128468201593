import React, { useContext, useEffect, useState } from "react";
import {
  ActionItem,
  ActionItemTypes,
  AllowedFileFormats,
  HTTPResponse,
  MonthNames,
  PriorityList,
  ToastMessageType,
  toolbarOptions,
} from "../Helpers/Enums";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { EditorState, convertToRaw, convertToHTML, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  AllowPositiveNumbersWithTwoDecimal,
  ConvertFromHTML,
  GetLocalStorageData,
  GetLoggedInUserID,
  isStringEmpty,
  AddLocalStorageData,
  changePageTitle,
} from "../Helpers/Utilities";
import { ActionItemService } from "../WebApiServices/ActionItem.service";
import Select from "react-select";
import {
  CommonErrorMessages,
  ToastMessages,
  CreateActionItemErrorMsg,
} from "../Helpers/HelperText";
import ErrorField from "../Components/ErrorField";
import AddEditComments from "../Components/AddEditComments";
import MultipleFileUpload from "../Components/MultipleFileUpload";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { useParams, useLocation } from "react-router-dom";
import TimeBar from "../Components/TimeBar";
import { ProjectService } from "../WebApiServices/Project.service";
import { BacklogService } from "../WebApiServices/Backlog.service";
import DeleteModal from "../Components/DeleteModal";
import LetteredAvatar from "./Overview/LetteredAvatar";
import { SprintService } from "../WebApiServices/Sprint.service";
import ScrollPage from "../Components/ScrollPage";

const AddEditTask = () => {
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const { search } = useLocation();

  const { LogOut } = useUIContext();
  // const { actionItemId } = useParams();
  const { actionItemIds } = useParams();
  const [assigneeList, setAssigneeList] = useState([]);
  const [actionItemList, setActionItemList] = useState([]);
  const [sprintList, setSprintList] = useState([]);
  const {projectId:projectIdURL}=useParams();
  const [projectId, setProjectId] = useState(0);
  const [actionItemId, setActionItemId] = useState(0);
  const [isPageDisabled, setIsPageDisabled] = useState(Boolean);
  const [error, setError] = useState({
    title: false,
    statusId: false,
    typeId: false,
    priorityId: false,
    assigneeId: false,
    description: false,
    enchancement: false,
    rootCauseDescription: false,
    designEstimates: false,
    devEstimates: false,
    qaEstimates: false,
  });
  const [selectedSprint, setSelectedSprint] = useState({});
  const [titleError, setTitleError] = useState(false);
  const [actionItemDetails, setActionItemDetails] = useState({
    userId: "",
    actionItemId: "",
    color: "",
    title: "",
    description: "",
    assigneeId: "",
    priorityId: "",
    designEstimates: "",
    devEstimates: "",
    qaEstimates: "",
    actionItemTypeId: "",
    statusId: "",
    reporterId: "",
    selectedLinkedAI: "",
    workLogDetails: [],
    createdOn: "",
    editedOn: "",
    actionItemStatusId: "",
    rootCauseTypeId: "",
    rootCauseDescription: "",
    newSprintId: 0,
    subTaskItems: [],
  });
  const HandleSprintDropdownChange = (selectedOption, field) => {
    const updatedActionItemDetails = { ...actionItemDetails };
    const { value, label } = selectedOption;
    updatedActionItemDetails[field] = value;
    setSelectedSprint({ value, label });
    setActionItemDetails(updatedActionItemDetails);
  };
  const HandleDropdownChange = (e, id) => {
    
    const value = Number(e.value);
    let initials = getInitials(e.label);

    if (id === "selectedLinkedAI") {
      setActionItemDetails((prev) => ({
        ...prev,
        selectedLinkedAI: {
          value: e.value,
          label: e.label,
        },
      }));
    }
    else if (id === "assigneeId") {
      if (actionItemDetails.title.length > ActionItem.TitleMaxLength) {
        setTitleError(true);
        const titleValue = "{" + (initials != undefined ? initials : "") + "}" + actionItemDetails.title.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, '');
        setActionItemDetails((prev) => ({
          ...prev,
          title: titleValue,
          [id]: e,
        }));
      }
      else {
        const titleValue = "{" + (initials != undefined ? initials : "") + "}" + actionItemDetails.title.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, '');

        setTitleError(false);
        setActionItemDetails((prev) => ({
          ...prev,
          title: titleValue,
          [id]: e,
        }));
      }
     
    } else {
      setActionItemDetails((prev) => ({
        ...prev,
        [id]: e,
      }));
      setError((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };
  function getInitials(name) {
    if (typeof name === "string" && name.trim() !== "") {
      const nameParts = name.trim().split(" ");
      if (nameParts.length >= 2) {
        return `${nameParts[0][0]}${nameParts[1][0]}`;
      } else if (nameParts.length === 1) {
        return `${nameParts[0][0]}`;
      }
    }
    return ""; // Handle cases where name is undefined or doesn't have enough parts.
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    return `${day}/${monthName}/${year}`;
  };
  const HandleFieldChange = (e) => {
    const { id, value, checked } = e.target;
    let name =
      assigneeList.length > 0 &&
      actionItemDetails.assigneeDrpDwn.value > 0 &&
      assigneeList.find((item) => {
        return item.value === actionItemDetails.assigneeDrpDwn.value;
      });
    switch (id) {
      case "title":
        if (value.length > ActionItem.TitleMaxLength) {
          setTitleError(true);
          const testValue =
            "{" +
            (name.label != undefined ? getInitials(name.label) : "") +
            "}" +
            value.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, "");
          setActionItemDetails((prev) => ({
            ...prev,
            title: testValue,
          }));
        } else {
          const val = actionItemDetails.title.slice(0, 500);
          setTitleError(false);
          setActionItemDetails((prev) => ({
            ...prev,
            title:
              "{" + name.label != undefined &&
              getInitials(name.label) + "}" + value.replace(/\{.*?\}/g, ""),
          }));
        }
      case "description": {
        setActionItemDetails((prev) => ({
          ...prev,
          [id]: value,
        }));

        //ngg: sets error object to false for that field
        setError((prev) => ({
          ...prev,
          [id]: false,
        }));
        break;
      }
      case "enchancement": {
        setActionItemDetails((prev) => ({
          ...prev,
          [id]: checked,
        }));
        setError((prev) => ({
          ...prev,
          [id]: false,
        }));
        break;
      }
      case "designEstimates":
      case "devEstimates":
      case "qaEstimates": {
        setActionItemDetails((prev) => ({
          ...prev,
          [id]: AllowPositiveNumbersWithTwoDecimal(value),
        }));
        setError((prev) => ({
          ...prev,
          [id]: false,
        }));
        break;
      }
      default:
        break;
    }
  };
  const HandleRootDropdownChange = (selectedOption, field) => {
    const updatedActionItemDetails = { ...actionItemDetails };
    const { value } = selectedOption;
    updatedActionItemDetails[field] = value;
    setActionItemDetails(updatedActionItemDetails);
  };
  const [causeTypes, setCauseTypes] = useState("");

  useEffect(() => {
    getRootCauseTypes();
  }, []);
  const getRootCauseTypes = async () => {
    const resp = await ActionItemService.GetRootCauseTypes();
    const data = resp.data?.map((d) => ({
      value: d.rootCauseTypeId,
      label: d.type,
    }));
    if (resp.status === HTTPResponse.Unauthorized) {
      LogOut();
    }
    const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));
    setCauseTypes(sortedData);
  };

  const selectedCauseType = Array.isArray(causeTypes)
    ? causeTypes.find(
        (option) => option.value === actionItemDetails?.rootCauseTypeId
      )
    : null;
  return (
    <>
     
      <ScrollPage />
    </>
  );
};
  export default AddEditTask;


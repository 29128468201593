import WebServiceWrapper from "./WebServiceWrapper";

export const UserRolesPermission = {
  GetDefaultRolePermissions, GetUserSavedRolePermissions,SaveAdminRolesPermission, SavePLRolesPermission, SaveEmployeeRolesPermission,
  GenerateUserWorkLogReport
};


function GetDefaultRolePermissions() {
  return WebServiceWrapper.Get(`/rolepermission/GetDefaultRolesPermission`);
}

function GetUserSavedRolePermissions() {
  return WebServiceWrapper.Get(`/rolepermission/GetRolePermission`);
}

function SaveAdminRolesPermission(data) {
  return WebServiceWrapper.Post("/rolepermission/SaveAdminRolesPermission", data);
}

function SavePLRolesPermission(data) {
  return WebServiceWrapper.Post("/rolepermission/SaveProjectLeadRolesPermission", data);
}

function SaveEmployeeRolesPermission(data) {
  return WebServiceWrapper.Post("/rolepermission/SaveEmployeeLeadRolesPermission", data);
}

function GenerateUserWorkLogReport() {
  return WebServiceWrapper.Get(`/reports/GenerateUserWorkLogReport`);
}
import React, { useState, useEffect, useContext,useCallback } from 'react';
import {
    AddLocalStorageData,
    GetLocalStorageData,
    GetLoggedInUserID,
    RemoveLocalStorageData,
    ShowToastMessage,
} from "../Helpers/Utilities";
import { HTTPResponse, ToastMessageType, ROLE_TYPE, MaxCharacterCount } from "../Helpers/Enums";
import { ResetPasswordErrorMessage, ManageUser } from "../Helpers/HelperText";
import { ManageUsersService } from '../WebApiServices/ManageUsers.Service';
import { ColorPicker } from "primereact/colorpicker";
import { AppContext } from "../Contexts/AppContextProvider";
import DeleteModal from '../Components/DeleteModal';
import ErrorField from '../Components/ErrorField';
import { useNavigate } from "react-router-dom";

import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core'; // For zoom control
const Profile = () => {
    const { ShowToastMessage } = useContext(AppContext);
    const { ToggleLoader } = useContext(AppContext);
    // State variables for form inputs and other data
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    // SYJ Profile Image Edit/Delete
    const [profileImage, setProfileImage] = useState("");
    const [profileImageOnChange, setProfileImageOnChange] = useState("");
    const [stateToReload, setStateToReload] = useState(false);

    const [profileImageId, setProfileImageId] = useState("");
    const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
    const [isDeleteYesClicked, setisDeleteYesClicked] = useState(false);
    const navigate = useNavigate();

    const loggedInUserID = GetLoggedInUserID();
    const [color, setColor] = useState('');
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        newPassword: '',
        confirmPassword: '',
        currentPassword: false,
    });

    const roleId = Number(GetLocalStorageData("roleID"));
    const [password, setPassword] = useState('');
    const [profileData, setProfileData] = useState({
        employeeId: 0,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        currentPassword: "",
        roleId: '',
        teamId: '',
        color: '',
        phoneNo: '',
        joiningDate: '',
        designation: '',
        lastLoginDate: '',
        projects: [],
        leaves: [],
        userPic: "",
        profileImageId: 0
    });

    const [initialProfileData, setInitialProfileData] = useState({
        employeeId: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roleId: '',
        teamId: '',
        color: '',
        phoneNo: '',
        joiningDate: '',
        designation: '',
        lastLoginDate: '',
        projects: [],
        leaves: [],
        userPic: "",
        profileImageId: 0
    });
    const [originalFile, setOriginalFile] = useState(null);

    const validFileFormats = ["png, jpg, jpeg"];
    const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";

    const computeInitials = (firstName, lastName) => {
        const firstInitial = firstName.charAt(0).toUpperCase();
        const lastInitial = lastName.charAt(0).toUpperCase();
        return firstInitial + lastInitial;
    };

    const employeeId = GetLoggedInUserID();


    // useEffect to fetch profile data when the component mounts

    useEffect(() => {
        // Fetch initial profile data and set it in both profileData and initialProfileData
        fetchProfileData();
    }, []);

        const fetchProfileData = async () => {
            try {
                const datas = await ManageUsersService.GetProfileData(employeeId);
                if (datas.data && datas.data.length > 0) {

                    const firstData = datas.data[0];

                    setProfileData((prev) => ({
                        ...prev,

                        employeeId: firstData.employeeId || 0,
                        firstName: firstData.firstName || '',
                        lastName: firstData.lastName || '',
                        email: firstData.email || '',
                        teamId: firstData.teamName || '',
                        password: firstData.password || '',
                        roleId: firstData.roleName || '',
                        projects: firstData.projects || '',
                        color: firstData.color || '',
                        phoneNo: firstData.phoneNo || '',
                        joiningDate: firstData.joiningDate || '',
                        lastLoginDate: formatDates(firstData.lastLoginDate) || '',
                        leaves: firstData.workLogDetailData || [],
                        userPic: firstData.profileImageId > 0 ? firstData.userPic : null,
                        profileImageId: firstData.profileImageId,
                        designation: firstData.designation || '',
                        currentPassword: ""


                    }));
                    setProfileImageOnChange(firstData.userPic);
                    setProfileImageId(firstData.profileImageId);

                    AddLocalStorageData("imagePath", firstData.userPic);
                    setInitialProfileData({
                        firstName: firstData.firstName || '',
                        lastName: firstData.lastName || '',
                        email: firstData.email || '',
                        teamId: firstData.teamName || '',
                        password: firstData.password || '',
                        roleId: firstData.roleName || '',
                        projects: firstData.projects || '',
                        color: firstData.color || '',
                        phoneNo: firstData.phoneNo || '',
                        joiningDate: firstData.joiningDate || '',
                        lastLoginDate: formatDates(firstData.lastLoginDate) || '',
                        projects: firstData.projects || '',
                        leaves: firstData.workLogDetailData || [],
                        userPic: firstData.profileImageId > 0 ? firstData.userPic : null,
                        profileImageId: firstData.profileImageId,
                        designation: firstData.designation || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };
    //     const handleImageChange = (e) => {
    //         const file = e.target.files[0];
    //         if (file) {
    //             const reader = new FileReader();
    //             reader.onload = () => {
    //                 setImageSrc(reader.result);
    //                 setShowCropModal(true);
    //             };
    //             reader.readAsDataURL(file);
    //                 setProfileImage(file)
    //   setProfileImageOnChange(URL.createObjectURL(file));

    //    AddLocalStorageData("imagePath", URL.createObjectURL(file));
    //    setShowDeleteModal(false);
    //         }
    //     };
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setOriginalFile(file); // Store the original file
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
                setShowCropModal(true);
            };
            reader.readAsDataURL(file);
                  setProfileImage(file);
               setProfileImageOnChange(URL.createObjectURL(file));

        AddLocalStorageData("imagePath", URL.createObjectURL(file));
       setShowDeleteModal(false);
        }
    };
    
// const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     // You may want to perform additional validation here
    //     setProfileImage(file)
    //     setProfileImageOnChange(URL.createObjectURL(file));

    //     AddLocalStorageData("imagePath", URL.createObjectURL(file));
    //     setShowDeleteModal(false);
    // };



        const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
        }, []);
    
//         const handleCrop = useCallback(async () => {
//             try {
//                 const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
//                 setProfileImage(croppedImage);
//                 setProfileImageOnChange(URL.createObjectURL(croppedImage));
//                 setShowCropModal(false);

//                 setProfileImageOnChange(URL.createObjectURL(croppedImage));

//                 AddLocalStorageData("imagePath", URL.createObjectURL(croppedImage));
//                 setShowDeleteModal(false);
// debugger

                
//             } catch (error) {
//                 console.error('Error cropping image:', error);
//             }
//         }, [croppedAreaPixels, imageSrc]);
const handleCrop = useCallback(async () => {
    try {
        const croppedFile = await getCroppedImg(imageSrc, croppedAreaPixels, originalFile);
       
setProfileImage(croppedFile)
AddLocalStorageData("imagePath", URL.createObjectURL(croppedFile));
        setProfileImageOnChange(URL.createObjectURL(croppedFile));
        setShowCropModal(false);
    } catch (error) {
        console.error('Error cropping image:', error);
    }
}, [croppedAreaPixels, imageSrc, originalFile]);

        // const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
        //     const image = new Image();
        //     image.src = imageSrc;
        
        //     const canvas = document.createElement('canvas');
        //     const ctx = canvas.getContext('2d');
        
        //     canvas.width = croppedAreaPixels.width;
        //     canvas.height = croppedAreaPixels.height;
        
        //     ctx.drawImage(
        //         image,
        //         croppedAreaPixels.x,
        //         croppedAreaPixels.y,
        //         croppedAreaPixels.width,
        //         croppedAreaPixels.height,
        //         0,
        //         0,
        //         croppedAreaPixels.width,
        //         croppedAreaPixels.height
        //     );
        
        //     return new Promise((resolve, reject) => {
        //         canvas.toBlob((blob) => {
        //             if (!blob) {
        //                 reject(new Error('Failed to generate blob.'));
        //                 return;
        //             }
        //             resolve(blob);
        //         }, 'image/jpeg');
        //     });
        // };
        const getCroppedImg = async (imageSrc, croppedAreaPixels, originalFile) => {
            const image = new Image();
            image.src = imageSrc;
        
            return new Promise((resolve, reject) => {
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
        
                    canvas.width = croppedAreaPixels.width;
                    canvas.height = croppedAreaPixels.height;
        
                    ctx.drawImage(
                        image,
                        croppedAreaPixels.x,
                        croppedAreaPixels.y,
                        croppedAreaPixels.width,
                        croppedAreaPixels.height,
                        0,
                        0,
                        croppedAreaPixels.width,
                        croppedAreaPixels.height
                    );
        
                    canvas.toBlob((blob) => {
                        if (!blob) {
                            reject(new Error('Failed to generate blob.'));
                            return;
                        }
        
                        const croppedFile = new File([blob], originalFile.name, {
                            type: originalFile.type,
                            lastModified: originalFile.lastModified,
                        });
        
                        resolve(croppedFile);
                    }, 'image/jpeg');
                };
        
                image.onerror = (error) => reject(error);
            });
        };
        
      
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle empty date string
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatDates = (dateString) => {
        if (!dateString) return ''; // Handle empty date string
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${day}-${month}-${year}`;
    };
    function formatCustomDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const ordinalDay = addOrdinalSuffix(day);
        return `${ordinalDay} ${month} ${year}`;
    }

    // Function to add ordinal suffix to the day (e.g., 1st, 2nd, 3rd, etc.)
    function addOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
            return `${day}th`;
        }
        switch (day % 10) {
            case 1:
                return `${day}st`;
            case 2:
                return `${day}nd`;
            case 3:
                return `${day}rd`;
            default:
                return `${day}th`;
        }
    }
    const handleFirstNameChange = (event) => {
        const { value } = event.target;
        setProfileData(prev => ({ ...prev, firstName: value }));
        // Clear the error message when the user starts typing
        setErrors(prev => ({ ...prev, firstName: '' }));
    };

    const handleLastNameChange = (event) => {
        const { value } = event.target;
        setProfileData(prev => ({ ...prev, lastName: value }));
        // Clear the error message when the user starts typing
        setErrors(prev => ({ ...prev, lastName: '' }));
    };


    const NUMBER_REGE = /^\d*(\.\d{0,5})?$/s;


    const handlePhoneNumberChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue >= 0 && NUMBER_REGE.test(inputValue)) {
            setProfileData({ ...profileData, phoneNo: inputValue });

        }
    };

    // const [designation, setDesignation] = useState();

    // const handleDesignationChange = (event) => {
    //     // Update the designation when the user types
    //     setDesignation(event.target.value);
    // };

    const handleDesignationChange = (event) => {
        const { value } = event.target;
        setProfileData(prev => ({ ...prev, designation: value }));
        // Clear the error message when the user starts typing
        //setErrors(prev => ({ ...prev, firstName: '' }));
    };
    const handleDateChange = (event) => {
        setProfileData({ ...profileData, joiningDate: event.target.value });
    };


    // const handlePasswordChange = (e) => {
    //     const { name, value } = e.target;
    //     setProfileData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };
    const handleCurrentPasswordChange = (e) => {

        const currentPassword = e.target.value;
        setProfileData((prev) => ({
            ...prev, currentPassword: currentPassword

        }))
        setErrors((prev) => ({
            ...prev, currentPassword: false
        }));

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewPassword(value)
        if (name === 'newPassword') {
            setPassword(value);
            validatePassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
            validateConfirmPassword(value);
        }
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!password) {
            setErrors({ ...errors, newPassword: ResetPasswordErrorMessage.Password });
        } else if (!passwordRegex.test(password)) {
            setErrors({ ...errors, newPassword: ResetPasswordErrorMessage.PasswordMinLength });
        } else {
            setErrors({ ...errors, newPassword: '' });
        }
    };

    const validateConfirmPassword = (confirmPassword) => {
        if (!confirmPassword) {
            setErrors({ ...errors, confirmPassword: ResetPasswordErrorMessage.ConfirmPassword });
        } else if (password !== confirmPassword) {
            setErrors({ ...errors, confirmPassword: ResetPasswordErrorMessage.NotMatched });
        } else {
            setErrors({ ...errors, confirmPassword: '' });
        }
    };
    const handleCancel = () => {
        // Reset profileData to initialProfileData
        setProfileData({
            ...initialProfileData,
            color: profileData.color // Preserve the color from the current profileData
        });
        setPassword('');
        setConfirmPassword('');
        // Clear validation errors
        setErrors({});
        navigate("/ManageProjects");
    };




    const [showChangePassword, setShowChangePassword] = useState(false);

    const toggleChangePassword = () => {
        setShowChangePassword(!showChangePassword);
    };


    const togglePasswordVisibility = () => {
        const passwordField = document.getElementById("password-field");
        const fieldType = passwordField.getAttribute("type");
        passwordField.setAttribute("type", fieldType === "password" ? "text" : "password");
        const eyeIcon = document.querySelector("#password-field + .toggle-password");
        if (fieldType === "password") {
            eyeIcon.classList.remove("fa-eye-slash");
            eyeIcon.classList.add("fa-eye");
        } else {
            eyeIcon.classList.remove("fa-eye");
            eyeIcon.classList.add("fa-eye-slash");
        }
    };

    const toggleNewPasswordVisibility = () => {
        const newPasswordField = document.getElementById("new-password-field");
        const fieldType = newPasswordField.getAttribute("type");
        newPasswordField.setAttribute("type", fieldType === "password" ? "text" : "password");
        const eyeIcon = document.querySelector("#new-password-field + .toggle-password");
        if (fieldType === "password") {
            eyeIcon.classList.remove("fa-eye-slash");
            eyeIcon.classList.add("fa-eye");
        } else {
            eyeIcon.classList.remove("fa-eye");
            eyeIcon.classList.add("fa-eye-slash");
        }
    };

    const toggleConfirmPasswordVisibility = (fieldId) => {
        const passwordField = document.getElementById(fieldId);
        const fieldType = passwordField.getAttribute("type");
        passwordField.setAttribute("type", fieldType === "password" ? "text" : "password");
        const eyeIcon = document.querySelector(`#${fieldId} + .toggle-password`);
        if (fieldType === "password") {
            eyeIcon.classList.remove("fa-eye-slash");
            eyeIcon.classList.add("fa-eye");
        } else {
            eyeIcon.classList.remove("fa-eye");
            eyeIcon.classList.add("fa-eye-slash");
        }
    };




    const onCancelClick = () => {
        // Clear validation errors
        setErrors({});

        // Close the change password section
        setShowChangePassword(false);

        // Show the change password button if it was hidden
        const changePasswordButton = document.querySelector('.toggle-password-btn');
        if (changePasswordButton) {
            changePasswordButton.style.display = 'inline-block';
        }

        // Clear password fields
        setPassword('');
        setConfirmPassword('');
    };


    const handleColorChange = (newColor) => {
        if (!newColor.startsWith("#")) {
            newColor = `#${newColor}`;
        }
        setColor(newColor.toUpperCase())
    };


    const handleSave = async () => {
        try {
            // Perform null validation
            const errorsCopy = { ...errors };
            let hasError = false;

            if (!profileData.firstName) {
                errorsCopy.firstName = 'First Name is required';
                hasError = true;
            } else {
                errorsCopy.firstName = '';
            }

            if (!profileData.lastName) {
                errorsCopy.lastName = 'Last Name is required';
                hasError = true;
            } else {
                errorsCopy.lastName = '';
            }

            if (showChangePassword) {

                // if (profileData.currentPassword=="") {
                //     console.log("rrreeee",errors)

                //    setErrors((prev)=>({
                //     ...prev,currentPassword:true
                //    }))
                //    console.log("rrreeee",errors)


                // }
                if (profileData.currentPassword === "" || profileData.currentPassword != profileData.password) {
                    errorsCopy.currentPassword = true;
                    hasError = true;
                } else {
                    errorsCopy.password = '';
                }

                if (!profileData.password) {
                    errorsCopy.password = 'Current Password is required';
                    hasError = true;
                } else {
                    errorsCopy.password = '';
                }

                // Validate "New Password" field
                if (!password) {
                    errorsCopy.newPassword = 'New Password is required';
                    hasError = true;
                } else {
                    errorsCopy.newPassword = '';
                }

                // Validate "Confirm Password" field
                if (!confirmPassword) {
                    errorsCopy.confirmPassword = 'Confirm Password is required';
                    hasError = true;
                } else if (password !== confirmPassword) {
                    errorsCopy.confirmPassword = 'Passwords do not match';
                    hasError = true;
                } else {
                    errorsCopy.confirmPassword = '';
                }
            }
            // Set errors for other fields...
            setErrors(errorsCopy);
            const isPasswordEmpty = !errorsCopy.newPassword && !errorsCopy.confirmPassword;

            if (!isPasswordEmpty) {
                if (errorsCopy.newPassword || errorsCopy.confirmPassword) {
                    hasError = true;
                }
            }

            if (!hasError) {
                ToggleLoader(true);
                let formData = new FormData();
                const data = {

                    employeeId: profileData.employeeId,
                    firstName: profileData.firstName,
                    lastName: profileData.lastName,
                    phoneNo: profileData.phoneNo,
                    joiningDate: profileData.joiningDate,
                    color: color || profileData.color,
                    designation: profileData.designation,
                    //password: isPasswordEmpty ? profileData.password : newPassword,
                    password: !confirmPassword ? profileData.password : newPassword,
                    profileImageId: profileData.profileImageId > 0 ? profileData.profileImageId : 0
                };

                formData.append("ProfileData", JSON.stringify(data));
                let files = profileImage;

                if (files.size !== null && files.size !== undefined) {
                    formData.append("fileList", files);
                }

                const updatedProfile = await ManageUsersService.UpdateProfileData(formData);


                if (updatedProfile.status === HTTPResponse.OK) {
                    // Update the displayed profile data
                    setProfileData({
                        ...profileData,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phoneNo: data.phoneNo,
                        joiningDate: data.joiningDate,
                        color: data.color,
                        designation: data.designation,
                        password: isPasswordEmpty ? data.password : newPassword,
                    });
                    // Clear newPassword and confirmPassword fields
                    setPassword('');
                    setConfirmPassword('');
                    // Show toast message
                    AddLocalStorageData("color", data.color);
                    AddLocalStorageData("userName", `${data.firstName} ${data.lastName}`);
                    ShowToastMessage(ManageUser.ProfileUpdate, ToastMessageType.Success);
                }


                if (isDeleteYesClicked) {
                    
                    const profileIdToDelete = profileImageId;
                    if (profileIdToDelete > 0) {
                        // Attempt to delete the user profile image
                        const dataToDelete = await ManageUsersService.DeleteUserProfileImage(profileImageId);

                        // Handle response after deletion
                        if (dataToDelete.status === HTTPResponse.OK) {
                            // If deletion was successful, update the state accordingly
                            setisDeleteYesClicked(false);
                            RemoveLocalStorageData("imagePath");
                        }
                    }
                }

            }

            ToggleLoader(false);
            //await fetchProfileData();
            //  window.location.reload();
        }
        catch (error) {
            console.error('Error updating profile dsata:', error);
        }
    };



    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     // You may want to perform additional validation here
    //     setProfileImage(file)
    //     setProfileImageOnChange(URL.createObjectURL(file));

    //     AddLocalStorageData("imagePath", URL.createObjectURL(file));
    //     setShowDeleteModal(false);
    // };

    const handleDeleteImage = () => {
        setShowDeleteModal(true);
    };

    const OnDeleteModalYesClick = async () => {
        try {
            
            ToggleLoader(true);
            setProfileImageOnChange(null);
            setShowDeleteModal(false);
            setisDeleteYesClicked(true);
            ShowToastMessage(ManageUser.ProfilePicDelete, ToastMessageType.Success);

        } catch (error) {
            console.error('Error deleting profile Image:', error);
        } finally {
            ToggleLoader(false);
        }


    };

    const OnCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    return (
        <>
            {showDeleteModalPopUp && (
                <DeleteModal
                    showDeleteModal={showDeleteModalPopUp}
                    onYesClick={OnDeleteModalYesClick}
                    onNoClick={OnCloseDeleteModal}
                    textToDisplay={"Are you sure you want to delete?"}
                />
            )}
            <section className="title-block">
                <div className="container-fluid">
                    <div className="title-f-box">
                        <div className="title-banner">
                            <h3 className="page-title">
                                Profile
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
            <div>

                <main id="profile" className="main-body-content-project">
                    <section className="user-profile-body">
                        <div className="container-fluid">
                            <div className="user-profile">
                                <div className="row flex-switch">
                                    <div className="col-12 col-md-12 col-lg-8 col-xl-9">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>First Name <span className="mandatory-field">*</span></label>
                                                <input className="form-control" type="text" value={profileData.firstName} onChange={handleFirstNameChange}
                                                    maxLength={MaxCharacterCount.UserFNLN} />
                                                {errors.firstName && <span className="error-message">{errors.firstName}</span>}



                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>Last Name <span className="mandatory-field">*</span></label>
                                                <input className="form-control" type="text" value={profileData.lastName} maxLength={MaxCharacterCount.UserFNLN} onChange={handleLastNameChange} />
                                                {errors.lastName && <span className="error-message">{errors.lastName}</span>}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>Email <span className="mandatory-field">*</span></label>
                                                <input className="form-control" type="text" value={profileData.email} style={{ backgroundColor: '#e9ecef' }} onFocus={(e) => e.target.blur()} />
                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>Phone Number</label>
                                                <input className="form-control" type="text" maxLength={10} value={profileData.phoneNo} onChange={handlePhoneNumberChange} />
                                                {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>Team <span className="mandatory-field">*</span></label>
                                                <input className="form-control" type="text" value={profileData.teamId} style={{ backgroundColor: '#e9ecef' }} onFocus={(e) => e.target.blur()} />
                                                {errors.team && <span className="error-message">{errors.teamName}</span>}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mt-3" >
                                                {/* <label>Designation</label>
                                              
                                                <input className="form-control custom-input" type="text" value={profileData.roleId} style={{ backgroundColor: '#e9ecef' }} onFocus={(e) => e.target.blur()} /> */}
                                                <label>Designation</label>
                                                <input className="form-control" type="text" value={profileData.designation} onChange={handleDesignationChange} maxLength={MaxCharacterCount.Designation} />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>Joining Date</label>
                                                <input className="form-control" type="date" value={formatDate(profileData.joiningDate)} onChange={handleDateChange} min="2004-01-01" max={new Date().toISOString().split('T')[0]} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>Projects</label>
                                                <div className="bubble-sec" style={{ display: 'flex', whiteSpace: 'nowrap', marginTop: '10px' }}>
                                                    {roleId === ROLE_TYPE.ADMIN ? (
                                                        <div className="bubble-sec1">
                                                            <div className="bubble-display">
                                                                <div>All Projects</div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        profileData.projects.map(project => (
                                                            <div className="bubble-sec1" key={project.id} style={{ display: 'inline-block', marginRight: '10px' }}>
                                                                <div className="bubble-display">
                                                                    <div>{project.name}</div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                                                <label>My Leaves</label>
                                                {profileData.leaves.length > 0 ? (
                                                    <div className="leaves-sec" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                        {profileData.leaves.map((leave, index) => (
                                                            <div key={index} className="leaves-main">
                                                                <p className="leave-period">{formatCustomDate(leave.createdOn)} - {formatCustomDate(leave.createdOn)}</p>
                                                                <p className="leave-reason">{leave.misctaskName} - {leave.loggedHours} hours</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p>No leaves data available</p>
                                                )}
                                            </div>

                                        </div>


                                    </div>
                                    <div className="col-12 col-md-12 col-lg-4 col-xl-3 mt-3">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-12">
                                            <div className="ct-profile-fbox">
            <div className="ct-profile-sec">
                <div className="image-wrap">
                    {profileImageOnChange ? (
                        <img src={profileImageOnChange} className="img-fluid" alt="Profile" />
                    ) : (
                        <div className="user-profile-img-sec" style={{ backgroundColor: profileData.color }}>
                            <div className="user-profile-img">
                                <span>{computeInitials(profileData.firstName, profileData.lastName)}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="info">
                    <label htmlFor="myfile" className="edit-profile">
                        {profileImageOnChange ? (
                            <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ color: "white" }}></i>
                        ) : (
                            <i className="fa fa-plus-square-o" aria-hidden="true" style={{ color: "white" }}></i>
                        )}
                    </label>
                    {profileImageOnChange && <span className="div-line"></span>}
                    {profileImageOnChange && (
                        <span className="delete-profile" onClick={handleDeleteImage}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                    )}
                </div>
                <input
                    type="file"
                    accept="image/*"
                    id="myfile"
                    className="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                />
            </div>
            {showCropModal && (
    <div className="crop-modal">
        <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
        />
        <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
        />
        <div className="crop-controls">
            <button style={{zIndex:9999,color: "#fff",
    backgroundColor: "#483567",
    padding: "2px 13px",
    height: "33px",
    borderRadius:" 3px",
    border: "1px solid #483567"}} onClick={handleCrop}>Crop</button>
            <button style={{zIndex:9999,color: "#483567",
    backgroundColor: "#fff",
    padding: "2px 13px",
    height: "33px",
    borderRadius:" 3px",
    border: "1px solid #483567"}} onClick={() => setShowCropModal(false)}>Cancel</button>
        </div>
    </div>
)}
        </div>
                                                <div className="login-role-fbox">
                                                    <div>
                                                        <span className="login-role-label">Last Login: </span>
                                                        <span className="login-role-value">{profileData.lastLoginDate}</span>
                                                    </div>

                                                    <div>
                                                        <span className="login-role-label">Role: </span>
                                                        <span className="login-role-value">{profileData.roleId}</span>
                                                    </div>
                                                </div>
                                                <div className="assign-color-sec mt-3">
                                                    <div className="row">
                                                        <div className="col-5 col-sm-3 col-md-5 col-lg-5 col-xl-6 col-xxl-5">
                                                            <label>Assign Color</label>
                                                        </div>
                                                        <div className="col-7 col-sm-9 col-md-7 col-lg-7 col-xl-6 col-xxl-7">
                                                            {/* <input className="form-control" type="color"/> */}
                                                            <div className='custom-colorpicker'>
                                                                <ColorPicker
                                                                    value={profileData.color}
                                                                    onChange={(e) => handleColorChange(e.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-12 change-password-toggle">
                                                <div className="button-container">
                                                    <button className="toggle-password-btn" onClick={toggleChangePassword} style={{ display: showChangePassword ? 'none' : 'inline-block' }}>
                                                        Change Password
                                                    </button>
                                                    {showChangePassword && (
                                                        <div className="change-password-sec">
                                                            {/* <div className="mt-3">
                                                    <label>Current Password<span className="mandatory-fields">*</span></label>
                                                    <input id="password-field" type="password" className="form-control custom-input" name="currentPassword" placeholder="Current Password"  value={profileData.password} onChange={handlePasswordChange} />
                                                    <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                                
                                                </div> */}

                                                            {/* <div className="mt-3 change-password-label">
                                                                <label>Current Password<span className="mandatory-fields">*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <input id="password-field" type="password" className="form-control custom-input" name="currentPassword" placeholder="Current Password" 
                                                                    value={profileData.password} 
                                                                    onChange={handlePasswordChange} />
                                                                    <span onClick={togglePasswordVisibility} className="fa fa-fw fa-eye-slash field-icon toggle-password" ></span>
                                                                </div>

                                                            </div> */}
                                                            <div className="mt-3 change-password-label">
                                                                <label>Current Password<span className="mandatory-fields">*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <input id="password-field" type="password" className="form-control custom-input" name="currentPassword" placeholder="Current Password"
                                                                        value={profileData.currentPassword}
                                                                        onChange={(e) => handleCurrentPasswordChange(e)} />
                                                                    <span onClick={togglePasswordVisibility} className="fa fa-fw fa-eye-slash field-icon toggle-password" ></span>
                                                                </div>
                                                                <ErrorField
                                                                    show={errors.currentPassword}
                                                                    message={"Enter your correct current password"}
                                                                />

                                                            </div>
                                                            {/* <div className="mt-3">
                                                    <label>New Password<span className="mandatory-fields">*</span></label>
                                                    <input id="new-password-field" type="password" className="form-control custom-input" name="newPassword" placeholder="New Password"  />
                                                    <span toggle="#new-password-field" className="fa fa-fw fa-eye field-icon toggle-password" ></span>
                                                    {errors.newPassword && <span className="error-message">{errors.newPassword}</span>}
                                                </div> */}

                                                            <div className="mt-3 change-password-label">
                                                                <label>New Password<span className="mandatory-fields">*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <input id="new-password-field" type="password" className="form-control custom-input" name="newPassword" placeholder="New Password" value={password}
                                                                        maxLength={MaxCharacterCount.ProfilePassword} onChange={handleChange} />
                                                                    <span onClick={toggleNewPasswordVisibility} className="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
                                                                </div>
                                                                {errors.newPassword && <span className="error-message">{errors.newPassword}</span>}
                                                            </div>

                                                            {/* <div className="mt-3">
                                                    <label>Confirm Password<span className="mandatory-fields">*</span></label>
                                                    <input id="confirm-password-field" type="password" className="form-control custom-input" name="confirmPassword" placeholder="Confirm Password" />
                                                    <span toggle="#confirm-password-field" className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                                    {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}
                                                </div> */}

                                                            <div className="mt-3 change-password-label">
                                                                <label>Confirm Password<span className="mandatory-fields">*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <input id="confirm-password-field" type="password" maxLength={MaxCharacterCount.ProfilePassword} className="form-control custom-input" name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={handleChange} />
                                                                    <span onClick={() => toggleConfirmPasswordVisibility("confirm-password-field")} className="fa fa-fw fa-eye-slash field-icon toggle-password" ></span>
                                                                </div>
                                                                {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}
                                                            </div>

                                                            <div className="change-password-btn-sec mt-3">
                                                                <button className="change-password-btn" onClick={onCancelClick}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="save-cancel-sec ">
                                        <button className="save-btn custom-width" onClick={handleSave}>Save</button>
                                        <button className="cancel-btn " onClick={handleCancel}>Go back to Projects</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
};

export default Profile;

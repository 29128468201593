import WebServiceWrapper from "./WebServiceWrapper";

export const SprintOverviewService = {
  GetActionItems,
  AddingActionItems,
  AddEditSprint,
  GetNotStartedSprints,
  StartSprint,
  GetSprintReleaseData,
  GetCompletedSprints,
  NotCompletedActionItemsIds,
  SprintClosureDetailsUpdation,
  GetLastActiveSprintId,
};

function GetActionItems(projectId, isTrue) {
  return WebServiceWrapper.Get(`/backlog/GetActionItems?projectID=${projectId}&isFromWorkItemPage=${isTrue}`);
}

function AddingActionItems(payload) {
  return WebServiceWrapper.Post("/backlog/AddingActionItems", payload);
}

function AddEditSprint(sprintData) {
  return WebServiceWrapper.Post("/backlog/AddEditSprintData", sprintData);
}

function GetNotStartedSprints(projectId) {
  return WebServiceWrapper.Get(`/backlog/GetNotStartedSprints?projectID=${projectId}`);
}

function StartSprint(payload) {
  return WebServiceWrapper.Post("/backlog/StartSprint", payload);
}

function GetSprintReleaseData(projectId) {
  return WebServiceWrapper.Get(`/backlog/GetSprintReleaseData?projectID=${projectId}`);
}

function GetCompletedSprints(projectId) {
  return WebServiceWrapper.Get(`/backlog/GetCompletedSprints?projectID=${projectId}`);
}

function NotCompletedActionItemsIds(notCompletedActionItemIds) {
  return WebServiceWrapper.Post("/backlog/NotCompletedActionItemsIds", notCompletedActionItemIds);
}

function SprintClosureDetailsUpdation(selectedSprintData) {
  return WebServiceWrapper.Post("/backlog/SprintClosureDetailsUpdation", selectedSprintData);
}

function GetLastActiveSprintId(projectId) {
  return WebServiceWrapper.Get(`/sprint/GetLastActiveSprintId?projectID=${projectId}`);
}

import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const ScrollPage = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;

      if (scrollPosition > 100) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }

      if (windowHeight + scrollPosition < documentHeight - 100) {
        setShowScrollToBottom(true);
      } else {
        setShowScrollToBottom(false);
      }
    };

    handleScroll(); // Run the check immediately when the component mounts

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <div className="scroll-button-container">
      {showScrollToTop && (
        <div className="scroll-button" onClick={scrollToTop}>
          <FaArrowUp style={{ color: "#483567" }} />
        </div>
      )}
      {showScrollToBottom && (
        <div className="scroll-button" onClick={scrollToBottom}>
          <FaArrowDown style={{ color: "#483567" }} />
        </div>
      )}
    </div>
  );
};

export default ScrollPage;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ActionItemService } from "../WebApiServices/ActionItem.service";
import { SprintService } from "../WebApiServices/Sprint.service";
import { BacklogService } from "../WebApiServices/Backlog.service";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import {
  ActionItem,
  ActionItemTypes,
  AllowedFileFormats,
  HTTPResponse,
  PriorityList,
  ToastMessageType,
  toolbarOptions,
} from "../Helpers/Enums";
import {
  AllowPositiveNumbersWithTwoDecimal,
  GetLoggedInUserID,
  isStringEmpty,
  GetLocalStorageData,
  AddLocalStorageData,
  changePageTitle
} from "../Helpers/Utilities";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  CommonErrorMessages,
  CreateActionItemErrorMsg,
  ToastMessages,
} from "../Helpers/HelperText";
import ErrorField from "../Components/ErrorField";
import Select from "react-select";
import MultipleFileUpload from "../Components/MultipleFileUpload";
import { ProjectService } from "../WebApiServices/Project.service";
import { wrap } from "framer-motion";
import ScrollPage from "../Components/ScrollPage";

const CreateActionItem = () => {
  const { appState, ShowToastMessage, SetActionItemClear, ToggleLoader } =
    useContext(AppContext);
  const { LogOut } = useUIContext();
  const { actionItemClear } = appState;
  const { projectId } = useParams();

  const [projectName, setProjectName] = useState();
  const [isProcessing, setProcessing] = useState(false); //
  //SYJ Query Parameters
  const location = useLocation();
  const { sprintNames, sprint_Ids } = location.state || {};

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const customStyles = {
    menu: (base) => ({
      ...base,
      maxHeight: 120, // Set the maximum height for the dropdown
      overflow: "scroll", // Enable vertical scrolling
    }),
  };

  const [error, setError] = useState({
    title: false,
    description: false,
    assigneeId: false,
    priorityId: false,
    sprintId: false,
    designEstimates: false,
    devEstimates: false,
    qAEstimates: false,
    actionItemTypeId: false,
    actionItemStatusId: false,
    enchancement: false,
  });
  const [assigneeList, setAssigneeList] = useState([]);
  const [actionItemList, setActionItemList] = useState([]);
  const [sprintList, setSprintList] = useState([]);
  const navigate = useNavigate();
  const ACTION_ITEM_INITIAL = {
    userId: GetLoggedInUserID(),
    title: "",
    assigneeId: "",
    priorityId: "",
    sprintId: "",
    designEstimates: "",
    devEstimates: "",
    qAEstimates: "",
    actionItemTypeId: "",
    actionItemStatusId: "",
    description: EditorState.createEmpty(),
    selectedLinkedAI: "",
  };

  const [checkedBox, setCheckedBox] = useState(false);
  const [editorKey, setEditorKey] = useState(0); //[MS]: to clear the editor save..

  const [titleError, setTitleError] = useState(false);

  const [actionItemDetails, setActionItemDetails] = useState({
    userId: "",
    title: "",
    description: EditorState.createEmpty(),
    assigneeId: checkedBox ?? "",
    priorityId: "",
    sprintId: "",
    designEstimates: "",
    devEstimates: "",
    qAEstimates: "",
    actionItemTypeId: "",
    actionItemStatusId: "",
    enchancement: "",
    selectedLinkedAI: "",
  });

  const [clearAttachments, setClearAttachments] = useState(false);
  const [load, setLoad] = useState(true);
  const [maxFileSizeLimitMsg, SetMaxFileSizeLimitMsg] = useState(false);

  useEffect(() => {
    changePageTitle("Create Issue");
    if (sprintNames && sprint_Ids) {
      setActionItemDetails((prev) => ({
        ...prev,
        sprintId: { value: sprint_Ids, label: sprintNames },
      }));
    }
  }, [load]);


  useEffect(() => {
    setActionItemDetails((prev) => ({
      ...prev,
      title: actionItemDetails.title,
    }));
  }, [actionItemDetails.title, actionItemDetails.assigneeId]);


  const HandleFieldChange = (e) => {
    const { id, value, checked } = e.target;
    // Update the corresponding state based on the input field's id 
    let name = assigneeList.length > 0 && actionItemDetails.assigneeId.value > 0 && assigneeList.find(item => {
      return item.value === actionItemDetails.assigneeId.value
    });

    switch (id) {
      case "title":
        if (value.length > ActionItem.TitleMaxLength) {
          setTitleError(true);
          const testValue = "{" + (name.label != undefined ? getInitials(name.label) : "") + "}" + value.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, '');
          setActionItemDetails((prev) => ({
            ...prev,
            title: testValue,
          }));
        }
        else {
          const val = actionItemDetails.title.slice(0, 500);
          setTitleError(false);
          setActionItemDetails((prev) => ({
            ...prev,
            title: "{" + name.label != undefined && getInitials(name.label) + "}" + value.replace(/\{.*?\}/g, ''),
          }));
        }

      // case "description":
      case "actionItemStatusId": {
        setActionItemDetails((prev) => ({
          ...prev,
          [id]: value,
        }));
        setError((prev) => ({
          ...prev,
          [id]: false,
        }));
        break;
      }
      case "designEstimates":
      case "devEstimates":
      case "qAEstimates": {
        setActionItemDetails((prev) => ({
          ...prev,
          [id]: AllowPositiveNumbersWithTwoDecimal(value),
        }));
        setError((prev) => ({
          ...prev,
          [id]: false,
        }));
        break;
      }
      case "enchancement": {
        setActionItemDetails((prev) => ({
          ...prev,
          [id]: checked,
        }));
        setError((prev) => ({
          ...prev,
          [id]: false,
        }));
        break;
      }

      default:
        break;
    }
  };

  function getInitials(name) {
    if (typeof name === "string" && name.trim() !== "") {
      const nameParts = name.trim().split(" ");
      if (nameParts.length >= 2) {
        return `${nameParts[0][0]}${nameParts[1][0]}`;
      } else if (nameParts.length === 1) {
        return `${nameParts[0][0]}`;
      }
    }
    return ""; // Handle cases where name is undefined or doesn't have enough parts.
  }


  const HandleDropdownChange = (e, id) => {

    const value = Number(e.value);
    let initials = getInitials(e.label);

    if (id === "selectedLinkedAI") {
      setActionItemDetails((prev) => ({
        ...prev,
        selectedLinkedAI: {
          value: e.value,
          label: e.label,
        },
      }));
    }
    else if (id === "assigneeId") {
      if (actionItemDetails.title.length > ActionItem.TitleMaxLength) {
        setTitleError(true);
        const titleValue = "{" + (initials != undefined ? initials : "") + "}" + actionItemDetails.title.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, '');
        setActionItemDetails((prev) => ({
          ...prev,
          title: titleValue,
          [id]: e,
        }));
      }
      else {
        const titleValue = "{" + (initials != undefined ? initials : "") + "}" + actionItemDetails.title.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, '');

        setTitleError(false);
        setActionItemDetails((prev) => ({
          ...prev,
          title: titleValue,
          [id]: e,
        }));
      }
      // setActionItemDetails((prev) => ({
      //   ...prev,
      //   title: "{" + initials + "}" + actionItemDetails.title.replace(/\{.*?\}/g, ''),
      //   [id]: e,
      // }));
    } else {
      setActionItemDetails((prev) => ({
        ...prev,
        [id]: e,
      }));
      setError((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  const [documentFile, setDocumentFile] = useState([]);
  const [videoLink, setvideoLink] = useState([]);
  /* <summary>
  date: 20-09-2023
  Name: AP
  description: Vaidates field. return TRUE when validation doesnt hold right
  <summary>*/
  const validateField = (field, value) => {
    switch (field) {
      case "title":
      case "description":
      case "designEstimates":
      case "devEstimates":
      case "qAEstimates": {
        return isStringEmpty(value);
      }
      case "priorityId":
      case "sprintId":
      case "actionItemTypeId":
      case "actionItemStatusId":
      case "assigneeId": {
        return Number(value) === 0; //Number("") returns 0
      }

      default:
        return false;
    }
  };

  /* <summary>
  date: 20-09-2023
  Name: AP
  description: Validates entire form. Return TRUE if ready for save 
  <summary>*/
  const ValidateForm = () => {
    const {
      title,
      description,
      assigneeId,
      priorityId,
      designEstimates,
      devEstimates,
      qAEstimates,
      actionItemTypeId,
      actionItemStatusId,
      enchancement,
    } = actionItemDetails;
    let result = false;

    const contentAsString = JSON.stringify(actionItemDetails.description);
    const contentLength = contentAsString.length;

    const isEmptyDesc = contentLength === 848;

    let discValue = description;
    // .getCurrentContent().getPlainText().length == 0;

    setError((prev) => ({
      ...prev,
      title: isStringEmpty(title),
      //description: isStringEmpty(description),
      description: isEmptyDesc,
      assigneeId:
        assigneeId.value != undefined
          ? Number(assigneeId.value) == 0
          : isStringEmpty(assigneeId),
      priorityId: Number(priorityId) == 0,
      sprintId: false,
      designEstimates: isStringEmpty(designEstimates),
      devEstimates: isStringEmpty(devEstimates),
      qAEstimates: isStringEmpty(qAEstimates),
      actionItemTypeId: Number(actionItemTypeId) == 0,
      actionItemStatusId: Number(actionItemStatusId) == 0,
    }));

    if (actionItemDetails.title.length > ActionItem.TitleMaxLength) {
      setTitleError(true);
    }
    if (
      isStringEmpty(description) ||
      description == "<p></p>\n" ||
      isStringEmpty(title) ||
      (assigneeId.value == undefined && assigneeId == "") ||
      Number(priorityId.value) == 0 ||
      isStringEmpty(designEstimates) ||
      isStringEmpty(devEstimates) ||
      isStringEmpty(qAEstimates) ||
      Number(actionItemTypeId.value) == 0 ||
      Number(actionItemStatusId.value) == 0 ||
      maxFileSizeLimitMsg ||
      titleError
    ) {
      result = true;
    }

    return result;
  };
  useEffect(() => {
    SetActionItemClear(false);
    if (actionItemClear) setActionItemDetails(ACTION_ITEM_INITIAL);
    GetProjectName(); //await
    GetAssigneeList(); //await
    GetSprintList(); //await

    GetActionItemDetails();
  }, [projectId, actionItemClear]);
  const GetActionItemDetails = () => {
    if (!projectId) return;
  };

  async function GetProjectName() {
    let response = await ActionItemService.GetProjectName(projectId);
    if (response.status === HTTPResponse.OK) {
      setProjectName(response.data);
    }
    if (response.status === HTTPResponse.Unauthorized) {
      LogOut();
    }
  }

  async function GetAssigneeList() {
    let response = await ProjectService.GetAssignedEmployees(projectId, false);
    if (response.status === HTTPResponse.OK) {
      const employees = response?.data?.map((t) => ({
        value: t.employeeId,
        label: t.fullName,
        imagePath: t.imagePath,
      }));
      setAssigneeList(employees);
    }
  }
  async function GetSprintList() {
    let response = await SprintService.GetSprintListByProjectId(projectId);
    let actionItemData = await BacklogService.GetActionItems(projectId, true);

    if (response.status === HTTPResponse.OK) {
      const employees = response?.data?.map((t) => ({
        value: t.sprintId,
        label: t.name,
        id: t.sprintId,
      }));
      const sortedEmployees = employees.sort((a, b) => b.id - a.id);

      const newList = [
        { value: "", label: "Please Select" },
        ...sortedEmployees,
      ];
      setSprintList(newList);
    }
    if (actionItemData.status === HTTPResponse.OK) {
      setActionItemList(actionItemData.data);
    }

    if (
      actionItemData.status === HTTPResponse.Unauthorized ||
      response.status === HTTPResponse.Unauthorized
    ) {
      LogOut();
    }
  }
  /* <summary>
  date: 11-03-2024
  Name: Mustakim Shaikh
  description: to drag and drop files in description
  <summary>*/
  const [files, setFiles] = useState([]);

  const isValidFileType = (fileType) => {
    return AllowedFileFormats.includes(fileType.toLowerCase());
  };

  const [dragged, setDragged] = useState(false)
  const handleDroppedFiles = (files) => {

    // Filter out files with allowed types
    const allowedFiles = Array.from(files).filter((file) =>
      isValidFileType(file.type)
    );
    if (allowedFiles.length === 0) {
      ShowToastMessage(CreateActionItemErrorMsg.AllowedFileFormatTypes, ToastMessageType.Fail)

      return;
    }

    setFiles(files);

    const fileContentArray = [];

    // Handle dropped files here
    for (const file of allowedFiles) {
      if (file.type.startsWith("image/")) {
        // Handle image file
        fileContentArray.push(`<img src="${file.name}" alt="Dropped Image" />`);
        updateEditorContent(fileContentArray);

      }

      else {
        // Handle other file types or URLs
        fileContentArray.push(`${file.name}`);
        // Update editor content for other file types
        updateEditorContent(fileContentArray);
      }
    }
  };

  const updateEditorContent = (fileContentArray) => {

    let updatedEditorState = actionItemDetails.description;
    setDragged(true)

    Object.keys(fileContentArray).forEach((key) => {
      const content = fileContentArray[key];
      const currentContent = updatedEditorState.getCurrentContent();
      const contentStateWithFile = Modifier.insertText(
        currentContent,
        currentContent.getSelectionAfter(),
        "\n" + content,
        undefined,
        undefined
      );

      updatedEditorState = EditorState.push(
        updatedEditorState,
        contentStateWithFile,
        "insert-characters"
      );
    });

    const contentState = updatedEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Extracting text from rawContentState
    const text = rawContentState.blocks.map(block => block.text).join('\n');

    HandleEditorChange(text);

  };
  const userId = GetLocalStorageData("userId");

  /* <summary>
  
  date: 06-02-2024
  Name: MS
  description: To increase the count of actionItem on create button.
  <summary>*/
  let count = GetLocalStorageData("ActionItemCount");
  const OnSave = (e) => {
    const { id } = e.currentTarget;
    e.preventDefault();
    HandleAddActionItem(id);
  };
  //type-create/create anothers
  const HandleAddActionItem = async (type) => {
    try {
      if (!ValidateForm()) {
        setProcessing(true); // Set the button to processing state
        let formData = new FormData();
        const actionItemModel = {
          projectId: projectId,
          title: actionItemDetails.title,
          description: actionItemDetails.description,
          assigneeId: actionItemDetails.assigneeId.value,
          priorityId: actionItemDetails.priorityId.value,
          sprintId: actionItemDetails.sprintId.value,
          designEstimates: actionItemDetails.designEstimates,
          devEstimates: actionItemDetails.devEstimates,
          qAEstimates: actionItemDetails.qAEstimates,
          actionItemTypeId: actionItemDetails.actionItemTypeId.value,
          actionItemStatusId: 0, //SYJ here dynamic id will added from service side.
          reporterId: userId,
          createdBy: GetLoggedInUserID(),
          editedBy: GetLoggedInUserID(),
          videoLinkList: videoLink,
          enchancement: actionItemDetails.enchancement,
          selectedLinkedAI: actionItemDetails.selectedLinkedAI,
          actionItemCount: count,
        };

        formData.append("actionItemModel", JSON.stringify(actionItemModel));

        let files = documentFile;
        let ins = files.length;
        for (let x = 0; x < ins; x++) {
          if (files[x].size !== null && files[x].size !== undefined) {
            formData.append("fileList", files[x]);
          }
        }
        let response = await ActionItemService.Create(formData);
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
        ToggleLoader(true);

        if (response.status === HTTPResponse.OK) {

          ToggleLoader(false);
          setProcessing(false); //reset the button state  after processing
          setEditorKey((prevKey) => prevKey + 1); // Increment key to force remounting of the Editor component
          let toastMsg = ToastMessages.ItemAdded.replace(
            "[itemNo]",
            count
          ).replace("[itemDesc]", response.data.title);
          if (type === "btnCreateAnother") {
            setLoad(false);
            ShowToastMessage(toastMsg, ToastMessageType.Success);

            setActionItemDetails(ACTION_ITEM_INITIAL);
            setClearAttachments(true);
            count++;
            AddLocalStorageData("ActionItemCount", count);
          } else {
            setLoad(false);
            ShowToastMessage(toastMsg, ToastMessageType.Success);
            navigate(`/Overview/${projectId}`);
          }
        } else {
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
          throw response.error;
        }
      }
    } catch (error) {
      ToggleLoader(false);
    }
  };
  const NavigateToManageProjects = () => {
    navigate(`/Overview/${projectId}`);
  };

  const SetMaxError = (value) => {
    SetMaxFileSizeLimitMsg(value);
  };
  /*
  Name:Neychelle Godinho
 Date:6/10/2023
 Description:Gets Files list from multiplefileupload child component
  */
  const GetUploadedFiles = (files) => {
    setDocumentFile(files);
  };

  const GetvideoLinkList = (videoLink) => {
    setvideoLink(videoLink);
  };
  const selectedSprint = sprintList.find(
    (sprint) => sprint.label === sprintNames
  );
  /* <summary>
  date: 20-01-2024
  Name: Mustakim Shaikh
  description: Editor for styling description .
  <summary>*/
  const HandleEditorChange = (e) => {

    if (dragged) {
      const htmlContent = `<p>${e}<p>`;
      let updatedActionItemDetails = { ...actionItemDetails };

      updatedActionItemDetails.description = htmlContent;

      setActionItemDetails(updatedActionItemDetails);
    }
    else {
      const contentState = convertToRaw(e.getCurrentContent());
      const htmlContent = draftToHtml(contentState);

      const updatedActionItemDetails = { ...actionItemDetails };

      updatedActionItemDetails.description = htmlContent;
      const hasText =
        htmlContent.trim() != "<p></p>" && htmlContent.trim().length > 0;

      updatedActionItemDetails.error = {
        ...updatedActionItemDetails.error,
        description: hasText ? false : true, // Set error to true if no text
      };

      setError((prev) => ({
        ...prev,
        description: hasText ? false : true,
      }));

      setActionItemDetails(updatedActionItemDetails);
    }
  };

  return (
    <>
      <main className="main-body-content create-issue">

        <section className="breadcrumb-block">
          <BreadcrumbComponent projectName={GetLocalStorageData("projectName")} />
        </section>
        <section className="title-block">
          <div className="container">
            <div className="title-banner">
              <h3 className="page-title">Create Issue</h3>
            </div>
          </div>
        </section>
        {/* Create Issue Section */}
        <section className="create-issue-details">
          <div className="container">
            <div className="row">
              <div className="project-name">
                <label style={{ marginTop: "3px", marginLeft: "10px" }}>
                  Project:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={projectName}
                  disabled={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="issue-type">
                <label>
                  Issue Type:<span className="isRequiredField">*</span>
                </label>
                <div style={{ width: "247px" }}>
                  <Select
                    inputId="actionItemTypeId"
                    placeholder="Please Select"
                    value={actionItemDetails.actionItemTypeId} // set selected value
                    options={ActionItemTypes} // set list of the data
                    onChange={(e) =>
                      HandleDropdownChange(e, "actionItemTypeId")
                    } // assign onChange function
                    formatOptionLabel={(actionType) => (
                      <div>
                        <img
                          width={"20px"}
                          height={"20px"}
                          src={actionType.imagePath}
                          style={{ marginRight: "3px" }}
                          alt=""
                        />
                        <span>{actionType.label}</span>
                      </div>
                    )}
                  />
                  {error.actionItemTypeId && (
                    <div
                      className="invalid-message"
                      style={{ position: "relative" }}
                    >
                      <div className="invalid-login-input">
                        <ErrorField
                          show={error.actionItemTypeId}
                          message={CreateActionItemErrorMsg.IssueType}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="issue-box">
              <div className="row">
                <div className="col-md-12">
                  <div className="f-box " style={{ marginBottom: "21px" }}>
                    <div className="label-div" style={{ width: "100px" }}>
                      <label>
                        Title:<span className="isRequiredField">*</span>
                      </label>
                    </div>
                    <div className="single-text-input">
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        // maxLength={ActionItem.TitleMaxLength}
                        //maxLength={10}
                        value={actionItemDetails.title}
                        onChange={HandleFieldChange}
                      />
                      {error.title && (
                        <div
                          className="invalid-message"
                          style={{ position: "relative" }}
                        >
                          <div className="invalid-login-input">
                            <ErrorField
                              show={error.title == true}
                              message={CreateActionItemErrorMsg.Title}
                            />
                          </div>
                        </div>
                      )}
                      {titleError && (
                        <div
                          className="invalid-message"
                          style={{ position: "relative" }}
                        >
                          <div className="invalid-login-input">
                            <ErrorField
                              show={titleError}
                              message={CreateActionItemErrorMsg.TitleMaxLimitMsg}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row box-row assign-to-fdirection">
                    <div className="col-md-12 col-lg-4 issue-details Assign-to mt-2">
                      <div
                        className=" f-box label-div"
                        style={{ width: "100px", marginTop: "2px" }}
                      >
                        <label htmlFor="dropdown1">
                          Assign To:<span className="isRequiredField">*</span>
                        </label>
                      </div>
                      <div style={{ width: "400px" }}>
                        <Select
                          inputId="assigneeId"
                          placeholder="Please Select"
                          value={actionItemDetails.assigneeId}
                          options={assigneeList}
                          onChange={(e) =>
                            HandleDropdownChange(e, "assigneeId")
                          } // assign onChange function
                          formatOptionLabel={(emp) => (
                            <div>
                              {emp.imagePath && (
                                <img
                                  width={"30px"}
                                  height={"30px"}
                                  src={emp.imagePath}
                                  alt=""
                                />
                              )}
                              <span>{emp.label}</span>
                            </div>
                          )}
                        />
                        {error.assigneeId && (
                          <div
                            className="invalid-message"
                            style={{ position: "relative" }}
                          >
                            <div className="invalid-login-input">
                              <ErrorField
                                show={error.assigneeId == true}
                                message={CreateActionItemErrorMsg.AssignTo}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-4 issue-details Sprint mt-2">
                      <div className="label-div">
                        <label
                          className="sprint-label"
                          style={{ marginLeft: "20px", marginTop: "2px" }}
                          htmlFor="dropdown1"
                        >
                          Sprint:
                        </label>
                      </div>
                      <div style={{ width: "60%" }}>
                        <Select
                          inputId="sprintId"
                          placeholder="Please Select"
                          // value={ actionItemDetails.sprintId}
                          value={sprintList.find(
                            (sprint) => sprint.label === sprintNames
                          )}
                          options={sprintList}
                          onChange={(e) => HandleDropdownChange(e, "sprintId")}
                          isDisabled={sprintNames && sprint_Ids}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 issue-details Priority mt-2">
                      <div className="label-div">
                        <label
                          htmlFor="dropdown11"
                          style={{ marginTop: "2px" }}
                        >
                          Priority:<span className="isRequiredField">*</span>
                        </label>
                      </div>
                      <div style={{ width: "60%" }}>
                        <Select
                          inputId="priorityId"
                          placeholder="Please Select"
                          value={actionItemDetails.priorityId}
                          options={PriorityList}
                          onChange={(e) =>
                            HandleDropdownChange(e, "priorityId")
                          }
                          formatOptionLabel={(p) => (
                            <div>
                              <img
                                width={"20px"}
                                height={"20px"}
                                src={p.imagePath}
                                alt=""
                                style={{ marginRight: "3px" }}
                              />
                              <span>{p.label}</span>
                            </div>
                          )}
                        />
                        {error.priorityId && (
                          <div
                            className="invalid-message"
                            style={{ position: "relative" }}
                          >
                            <div className="invalid-login-input">
                              <ErrorField
                                show={error.priorityId == true}
                                message={CreateActionItemErrorMsg.PriorityList}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="box-row description">
                    <div className="description-label">
                      <label>Enhancement:</label>
                    </div>

                    <div className="form-check">
                      <input
                        style={{
                          marginLeft: "2px",
                          width: "20px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                        className="form-check-input"
                        name="enchancement"
                        type="checkbox"
                        checked={actionItemDetails.enchancement}
                        id="enchancement"
                        value={actionItemDetails.enchancement}
                        onClick={HandleFieldChange}
                      />
                    </div>
                    <div style={{ marginTop: "23px" }}>
                      <ErrorField
                        show={error.enchancement == true}
                        message={CommonErrorMessages.Required}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="box-row description">
                    <div className="description-label">
                      <label style={{ marginTop: "16px" }}>
                        Description:<span className="isRequiredField">*</span>
                      </label>
                    </div>
                    <div
                      onDrop={(e) => {
                        e.preventDefault();
                        const files = e.dataTransfer.files;

                        handleDroppedFiles(files);

                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <Editor
                          key={editorKey} // Key to force remounting of the Editor component
                          editorStyle={{
                            border: "1px solid #ced4da",
                            paddingLeft: "6px",
                            paddingRight: "6px",
                          }}
                          wrapperClassName="demo-wrapper "
                          editorClassName="editer-content editor-height"
                          toolbarClassName="toolbar-class candrag"
                          onEditorStateChange={(e) => {
                            HandleEditorChange(e);
                          }}
                          handlePastedFiles={(files) => {
                            handleDroppedFiles(files);
                          }}
                          toolbar={toolbarOptions}
                        />
                        {error.description && (
                          <div
                            className="invalid-message"
                            style={{ position: "relative" }}
                          >
                            <div className="invalid-login-input">
                              <ErrorField
                                show={error.description}
                                message={CreateActionItemErrorMsg.Description}
                              />
                            </div>
                          </div>
                        )}
                      </div></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="role-estimates">
                    <div className="estimate">
                      <label style={{ paddingTop: "8px" }}>Estimates:</label>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="estimate design">
                        <label style={{ paddingTop: "8px" }}>
                          Design:<span className="isRequiredField">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control"
                            type="text"
                            id="designEstimates"
                            value={actionItemDetails.designEstimates}
                            onChange={HandleFieldChange}
                            maxLength={4}
                          />
                          {error.designEstimates && (
                            <div
                              className="invalid-message"
                              style={{ position: "relative" }}
                            >
                              <div className="invalid-login-input">
                                <ErrorField
                                  style={{ textWrap: "wrap!important" }}
                                  show={error.designEstimates == true}
                                  message={CreateActionItemErrorMsg.DesignEst}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="estimate development">
                        <label style={{ paddingTop: "8px" }}>
                          Development:<span className="isRequiredField">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control"
                            type="text"
                            id="devEstimates"
                            value={actionItemDetails.devEstimates}
                            onChange={HandleFieldChange}
                            maxLength={4}
                          />
                          {error.devEstimates && (
                            <div
                              className="invalid-message"
                              style={{ position: "relative" }}
                            >
                              <div className="invalid-login-input">
                                <ErrorField
                                  style={{ textWrap: "wrap!important" }}
                                  show={error.devEstimates == true}
                                  message={CreateActionItemErrorMsg.DevEst}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="estimate QA">
                        <label style={{ paddingTop: "8px" }}>
                          QA:<span className="isRequiredField">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control"
                            type="text"
                            id="qAEstimates"
                            value={actionItemDetails.qAEstimates}
                            onChange={HandleFieldChange}
                            maxLength={4}
                          />
                          {error.qAEstimates && (
                            <div
                              className="invalid-message"
                              style={{ position: "relative" }}
                            >
                              <div className="invalid-login-input">
                                <ErrorField
                                  style={{ textWrap: "wrap!important" }}
                                  show={error.qAEstimates == true}
                                  message={CreateActionItemErrorMsg.QaEst}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="file-attachment">
                    <label>Attachment:</label>
                    <MultipleFileUpload
                      DontShowInComment={true}
                      getFileList={GetUploadedFiles}
                      getvideoLinkList={GetvideoLinkList}
                      videoLinkList={[]}
                      fileList={[]}
                      type={"Create"}
                      clearAttachments={clearAttachments} // Pass the clearAttachments state
                      maxFileSizeLimit={SetMaxError}
                      files={files}
                    />
                  </div>
                </div>

                <div className="col-md-12" style={{ marginTop: "20px" }}></div>
                <div className="col-md-12 issue-details">
                  <label
                    style={{
                      display: "flex",
                      marginRight: "5px",
                      alignItems: "center",
                    }}
                  >
                    Linked to parent:
                  </label>
                  <div style={{ width: "350px" }}>
                    <Select
                      styles={customStyles}
                      inputId="selectedLinkedAI"
                      placeholder="Please Select"
                      value={actionItemDetails.selectedLinkedAI}
                      options={actionItemList.map((lead) => ({
                        value: lead.actionItemId,
                        label: lead.key + ":" + lead.title,
                      }))}
                      onChange={(e) =>
                        HandleDropdownChange(e, "selectedLinkedAI")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="create-issue-block">
                <div className="checkbox" style={{ paddingTop: "0px" }}>
                  <button
                    id="btnCreateAnother"
                    name="btncreateAnother"
                    className="save-project-btn"
                    onClick={OnSave}
                    style={{ height: "35px" }}
                    disabled={isProcessing}// Disable when processing
                  >
                    <span>Create another</span>
                  </button>
                </div>
                <div>
                  <button
                    id="btnCreate"
                    name="btncreate"
                    className="issue-create-btn"
                    onClick={OnSave}
                    disabled={isProcessing} // Disable when processing
                  >
                    <span>Create</span>
                  </button>
                </div>
                <div>
                  <button
                    className="issue-cancel-btn"
                    onClick={NavigateToManageProjects}
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ScrollPage />
      </main>
    </>
    // );
  );
};

export default CreateActionItem;

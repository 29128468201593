
const Loader = () => {
    return (
      
    <body className="loader-body">
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    </body>
  );
};

export default Loader;

import WebServiceWrapper from "./WebServiceWrapper";

export const ProjectService = {
  GetAllProjects,
  UpdateProjectStatus,
  GetLeadNames,
  UpdateProjectDetails,
  GetActionItemStatusNames,
  UpdateActionItemStatusItems,
  DeleteActionItemStatus,
  AddProjectAndActionItemStatus,
  GetReleases,
  UpdateReleasesItems,
  UpdateReleasesStatus,
  GetProjectPhaseDetails, GetEmpAndAdmin,
  GetReleaseForProjectAndSpecificPhase, AddPhasesForSpecificProject,
  GetAssignedEmployees, AssignEmployeeToTheProject, UpdateProjectsAssignedToEmployee,UpdateProjectCategories,

  GetProjectsAssignedToEmployee,
  DeleteProject,
  GetAllProjectsByUserRole,AddLastVisitedProject
};
function GetAllProjects() {
  return WebServiceWrapper.Get("/projectDetails/GetProjects");
}

function GetAllProjectsByUserRole(userRoleId, userID) {
  return WebServiceWrapper.Get(`/projectDetails/GetProjectsByRole?roleId=${userRoleId}&employeeId=${userID}`);
}


function UpdateProjectStatus(id, newStatus, userId) {
  const data = {
    id: id,
    userId: userId,
    newStatus: newStatus,
  };
  return WebServiceWrapper.Post("/projectDetails/UpdateProjectStatus", data);
}
function GetLeadNames() {

  return WebServiceWrapper.Get("/projectDetails/GetLeadsName");
}

function UpdateProjectDetails(projectWithLead) {
  return WebServiceWrapper.Post(
    "/projectDetails/UpdateProjectDetails",
    projectWithLead
  );
}
function GetEmpAndAdmin() {

  return WebServiceWrapper.Get("/projectDetails/GetEmpAndAdmin");
}

function GetActionItemStatusNames(projectId) {
  return WebServiceWrapper.Get(
    `/projectDetails/GetActionItemStatusNames/${projectId}`
  );
}

function UpdateActionItemStatusItems(payload) {
  return WebServiceWrapper.Post(
    "/projectDetails/UpdateActionItemStatusItems",
    payload
  );
}

function DeleteActionItemStatus(actionItemStatusId, projectId) {
  const data = { actionItemStatusId: actionItemStatusId, projectId: projectId };

  return WebServiceWrapper.Post("/projectDetails/DeleteActionItemStatus", data);
}

function AddProjectAndActionItemStatus(request) {
  return WebServiceWrapper.Post(
    "/projectDetails/AddEditProjectDetails",
    request
  );
}
function GetReleases(projectId) {
  return WebServiceWrapper.Get(
    `/projectDetails/GetReleases?projectId=${projectId}`
  );
}
function UpdateReleasesItems(releases) {
  return WebServiceWrapper.Post(
    "/projectDetails/UpdateReleasesItems",
    releases
  );
}
function UpdateReleasesStatus(releaseId, newStatus, userId) {
  const data = { releaseId: releaseId, newStatus: newStatus, userId: userId }
  return WebServiceWrapper.Post("/projectDetails/UpdateReleasesStatus", data);
}

function GetProjectPhaseDetails(projectId) {
  return WebServiceWrapper.Get(
    `/projectDetails/?projectId=${projectId}`
  );
}

function GetReleaseForProjectAndSpecificPhase(projectId) {
  return WebServiceWrapper.Get(
    `/projectDetails/GetReleaseForProjectAndSpecificPhase?projectId=${projectId}`
  );
}
function AddPhasesForSpecificProject(projectPhases) {
  return WebServiceWrapper.Post(
    "/projectDetails/AddPhasesForSpecificProject?projectId", projectPhases
  );
}

function GetAssignedEmployees(projectId, isTrue) {
  return WebServiceWrapper.Get(
    `/projectDetails/GetAssignedEmployees?projectId=${projectId}&&isAssigneeModal=${isTrue}`
  );
}

function AssignEmployeeToTheProject(employeeList) {
  return WebServiceWrapper.Post("/projectDetails/AssignEmployeeToTheProject", employeeList);
}

function GetProjectsAssignedToEmployee(employeeId) {
  return WebServiceWrapper.Get(`/projectDetails/GetProjectsAssignedToEmployee?employeeId=${employeeId}`);
}

function UpdateProjectsAssignedToEmployee(employeeId, projectIds, loggedInUserId) {
  const data = {
    employeeId: employeeId, projectIds: projectIds, loggedInUserId: loggedInUserId
  }
  return WebServiceWrapper.Post("/projectDetails/UpdateProjectsAssignedToEmployee", data);
}
function DeleteProject(projectId) {
  return WebServiceWrapper.Get(`/projectDetails/DeleteProject?projectId=${projectId}`);

}
function UpdateProjectCategories(categories) {
  return WebServiceWrapper.Post(
    "/projectDetails/UpdateProjectCategories",
    categories
  );
}
//Mustakim
function AddLastVisitedProject(employeeId,projectId) {
  return WebServiceWrapper.Get(`/projectDetails/AddLastVisitedProject?EmployeeId=${employeeId}&&ProjectId=${projectId}`);
  // &&ProjectId?=${projectId}
}

import WebServiceWrapper from "./WebServiceWrapper";

export const GlobalEstimationService = {
  SaveTshirtEstimates,
  GetTshirtSizeEstimate,
  DeleteTshirtSizeEstimate,
  SaveGeneral,
  GetGeneralData,GetTshirtSize
  //GetWorkItemTypeFromDB,AddWorkItemType,GetDefaultTasks,DeleteWorkItemType
};

function SaveTshirtEstimates(data) {
  return WebServiceWrapper.Post("/globalEstimation/SaveTshirtEstimates", data);
}

function SaveGeneral(data) {
  return WebServiceWrapper.Post("/globalEstimation/SaveGeneral", data);
}

function GetTshirtSizeEstimate(projectId) {
  return WebServiceWrapper.Get(`/globalEstimation/GetTshirtSizeEstimate`);
}
function DeleteTshirtSizeEstimate(tshirtSizeEstimatesId) {
  return WebServiceWrapper.Get(
    `/globalEstimation/DeleteTshirtSizeEstimate?tshirtSizeEstimatesId=${tshirtSizeEstimatesId}`
  );
}

function GetGeneralData() {
  return WebServiceWrapper.Get(`/globalEstimation/GetGeneralData`);
}

function GetTshirtSize() {
  return WebServiceWrapper.Get(`/globalEstimation/GetTshirtSize`);
}

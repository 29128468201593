import WebServiceWrapper from "./WebServiceWrapper";

export const SprintService = {
  SprintInfo,
  GetSprintListByProjectId,
  GetActiveSprints,
  updateActionItemStatus,
  GetSprintDates,
  GetActiveSprintListByProjectId,GetLastColumnForProjectActionItem,GetSprintHistoryListBySprintId
};

function SprintInfo() {
  return WebServiceWrapper.Get("/sprint");
}
function GetSprintListByProjectId(projectId, isTrue) {
  return WebServiceWrapper.Get(
    `/sprint/GetSprintListByProjectId?projectId=${projectId}&isCompleted=${isTrue}`
  );
}


function GetActiveSprintListByProjectId(projectId) {
  return WebServiceWrapper.Get(
    `/sprint/GetAllActiveSprintListByProjectId?projectId=${projectId}`
  );
}

function GetActiveSprints(projectId) {
  return WebServiceWrapper.Get(
    `/sprint/GetActiveSprints?projectId=${projectId}`
  );
}

function updateActionItemStatus(payload) {
  return WebServiceWrapper.Post(
    "/sprint/UpdateActionItemStatus",(payload)
  );
}


function GetSprintDates(sprintId) {

  return WebServiceWrapper.Get(
    `/sprint/GetSprintDates?sprintId=${sprintId}`
  );
}

function GetLastColumnForProjectActionItem(projectId) {

  return WebServiceWrapper.Get(
    `/sprint/GetLastColumnForProjectActionItem?projectId=${projectId}`
  );
}

function GetSprintHistoryListBySprintId(sprintId) {

  return WebServiceWrapper.Get(
    `/sprint/GetSprintHistoryListBySprintId?sprintId=${sprintId}`
  );
}
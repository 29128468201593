import React, { useEffect } from "react";
import packageJson from "../package.json";
import { Route, Routes, useLocation } from "react-router-dom";
import "./custom.css";
import AppContextProvider from "./Contexts/AppContextProvider";
import Login from "./Pages/Login";
import { AddLocalStorageData, GetLocalStorageData } from "./Helpers/Utilities";
import PrivateRoute from "./Components/PrivateRoute";
import Projects from "./Pages/Projects";
import CreateActionItem from "./Pages/CreateActionItem";
import AddEditReport from "./Pages/AddEditReport";
import ManageUsers from "./Pages/ManageUsers";
import EditActionItem from "./Pages/EditActionItem";
import ActiveSprints from "./Pages/Overview/ActiveSprints";
import Overview from "./Pages/Overview";
import AddProject from "./Pages/AddProject";
import ResetPassword from "./Pages/ResetPassword";
import ErrorPage from "./Pages/ErrorPage";
import TimeLog from "./Pages/TimeLog";
import Profile from "./Pages/Profile";
import MyTasks from "./Pages/MyTasks";
import RolesPermissions from "./Pages/RolesPermissions";
import RolesAndPermissions from "./Pages/RolesAndPermissios";
import AddEditEmployee from "./Pages/AddEditEmployee";
import AddEditTask from "./Pages/AddEditTask";
import Calendar from "./Pages/Calendar";
import TimeTracker from "./Pages/TimeTracker";
// import Quest from "./Pages/Quest";
import GlobalEstimation from "./Pages/GlobalEstimation/GlobalEstimation";

const App = () => {
  const location = useLocation();

  /* <summary>
date: 14-09-2023
Name: AP(code from doc created by GM)
description: Effect to clear the cache
<summary>*/

  useEffect(() => {
    const Caching = () => {
      const version = GetLocalStorageData("version");

      if (version !== packageJson.version) {

        AddLocalStorageData("version", packageJson.version);

        if ("caches" in window) {
          caches.keys().then((names) => {
            // Delete all the cache files

            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }

        // Makes sure the page reloads. Changes are only visible after you refresh.

        window.location.reload(true);
      }
    };

    Caching();

    return () => { };
  }, []);
  return (
    <AppContextProvider>
      <Routes location={location} key={location.pathname}>
        <Route path="ResetPassword" element={<ResetPassword />}></Route>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<PrivateRoute />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/ManageUsers" element={<ManageUsers />} />
          <Route path="/ManageProjects" element={<Projects />} />
          <Route path="/AddTimeLogging" element={<AddEditReport />} />
          <Route path="/EditTimeLogging" element={<AddEditReport />} />
          <Route path="/Overview" element={<Overview />} />
          <Route path="/Overview/:projectId" element={<Overview />} />
          <Route path="/ActiveSprints" element={<ActiveSprints />} />
          <Route path="/CreateActionItem/:projectId" element={<CreateActionItem />} />
          <Route path="/EditProject" element={<AddProject />}></Route>
          <Route path="/AddProject" element={<AddProject />}></Route>
          <Route path="/EditActionItem" element={<EditActionItem />} />
          <Route path="/TimeLog" element={<TimeLog/>}/>
          <Route path="/Profile" element={<Profile/>}/>
          <Route path="/EditEmployee" element={<AddEditEmployee />}></Route>
          <Route path="/AddEmployee" element={<AddEditEmployee />}></Route>
          <Route path="/RolesPermissions" element={<RolesPermissions/>}/>
          <Route path="/RolesAndPermissions" element={<RolesAndPermissions/>}/>          
          <Route path="/AddActionItem/:projectId" element={<EditActionItem />} />
          <Route path="/AddTask/:projectId" element={<AddEditTask />} />
          <Route path="/Calendar" element={<Calendar />}></Route>
          <Route path="/MonthlyTimeTracker" element={<TimeTracker />}></Route>
          <Route path="GlobalEstimation" element={<GlobalEstimation/>}></Route>
          {/* <Route path="/Quest" element={<Quest />}></Route> */}




          <Route
            path="/EditActionItem/:actionItemIds"
            element={<EditActionItem />}
          />
           {/* <Route path="/EditActionItem" element={<EditActionItem />} /> */}
          <Route path="/MyTasks" element={<MyTasks/>}/>
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </AppContextProvider>
  );
};
export default App;

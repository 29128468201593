import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../../Contexts/AppContextProvider";
import LetteredAvatar from "./LetteredAvatar";
import { AddLocalStorageData } from "../../Helpers/Utilities";
const Card = ({
  item,
  onDragStart, onDragOver, onDrop,
  prioritypath,
  tasktypepath,
  assigneeFirstName,
  assigneeLastName,
  projectId,
  onClick,
  bgcolor,
  prioritytitle,
  tasktitle,
  isDisabled,
  actionItemStatusId, lastColumn,isOverview
}) => {

  const navigate = useNavigate();
  const { SetActionItemId } = useContext(AppContext);
  const NavigateToEditActionItem = (item, actionItemId) => {

    AddLocalStorageData("actionItemTitle", item.key.toString())
    SetActionItemId(actionItemId);
    navigate(
      `/EditActionItem/${actionItemId}`
      //?ProjectId=${projectId}&disabled=${isDisabled}`

    );


  };


  const Avatarname = assigneeFirstName + " " + assigneeLastName;
  return (
    <div
      className="card card-wrap-responsive task-hover"
      draggable
      onDragStart={(e) => onDragStart(e, item)}
      onDragOver={onDragOver} // Allow dragging over
      onDrop={onDrop} // Handle drop event
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        margin: "4px 5px",
        padding: "3px 5px", // Add padding to the card
        justifyContent: "space-between",
        position: "relative",
        //marginLeft:isOverview? "22px":"",
        cursor: isDisabled ? "default" :item.isDefault?"no-drop":  "move",
      }}
    >
    {/* {isOverview && (  <div className="add-sub-item"> <img
                              src=" Assets/images/add-sub-item.png"
                              alt="Add sub"
                            /></div>)} */}

     
      <div style={{ display: 'flex' }}>
        {" "}

        <div><img className="cardItem" src={tasktypepath} alt={tasktitle} title={tasktitle} /></div>
        {"  "}
        <div className="overview-priority-spacing"><img className="cardItem" src={prioritypath} alt={prioritytitle} title={prioritytitle} /></div>
        {"  "}
        <a className="task-link"
          // onClick={(e) => NavigateToEditActionItem(item, item.key)}
          style={{
            cursor: "pointer",
            color: "blue",
            fontWeight: 500,
            textDecoration: actionItemStatusId === lastColumn ? "line-through" : "underline",
          }}
        
         
          target="_blank"
          rel="noreferrer" // Add this attribute to mitigate security risk
          href={
            "/EditActionItem/" +
            item.key
          }
          // style={{
          //   cursor: "pointer",
          //   color: "blue",
          //   textDecoration:
          //     task.actionStatusTypeId ===
          //       lastColumn
          //       ? "line-through"
          //       : "underline",
          //   fontWeight: "normal"
          // }}
      
        
      
        >
          {item.key}
        </a>{" "}
        <span className="overview-task-name-spacing" style={{ color: "black" }}>{item.title}</span>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="assigneeName" style={{ paddingRight: "10px" }}>
          {" "}
          {/* Add padding */}
          <div className="avatarletter">
            <LetteredAvatar name={Avatarname} bgcolor={bgcolor} />
          </div>
        </div>
        <div className="detail Time" style={{ fontSize: '10px', alignContent:"center",fontWeight:'bold' }}>
          {/* <p> */}
          {(item.designEstimates + item.devEstimates + item.qaEstimates).toFixed(2).replace(/\.?0+$/, "")}h
          {/* </p> */}
        </div>
      </div>
    </div>
  );
};

export default Card;

import WebServiceWrapper from "./WebServiceWrapper";

export const ManageUsersService= {
    GetAllUsers, UpdateUserStatus, GetProfileData, UpdateProfileData, AddEditUser, DeleteUserProfileImage, DeleteEmployee, GetUserById
};
//AddUser, UpdateUser

function GetAllUsers() {
  return WebServiceWrapper.Get("/manageUsers/GetAllUsers");
}

function GetUserById(employeeId) {
  return WebServiceWrapper.Get(`/manageUsers/GetUserById?userId=${employeeId}`);
}

// function AddUser(user) {
//   return WebServiceWrapper.Post("/manageUsers/AddUser",user);
// }

// function UpdateUser(modifiedUser){
//   return WebServiceWrapper.Post("/manageUsers/UpdateUser",modifiedUser);
// }

// function AddEditUser(modifiedUser) {
//  return WebServiceWrapper.Post("/manageUsers/AddEditUserData", modifiedUser);
// }

function AddEditUser(modifiedUser) {
  return WebServiceWrapper.PostWithHeader("/manageUsers/AddEditUserData", modifiedUser, 60000, {
    "Content-Type": "multipart/form-data",
  });
}

function UpdateUserStatus(employeeId, newStatus, userId) {
  const data = {
    userId: userId,
    employeeId: employeeId,
    newStatus: newStatus,
  };
  return WebServiceWrapper.Post("/manageUsers/UpdateUserStatus", data);
}


function GetProfileData(employeeId) {
   return WebServiceWrapper.Get(`/manageUsers/GetProfileData?employeeId=${employeeId}`);
 
 }
 function DeleteUserProfileImage(profilePicId) {
   return WebServiceWrapper.Get(`/manageUsers/DeleteUserProfileImage?profilePicId=${profilePicId}`);
 
 }

function UpdateProfileData(updateEmployee) {
  return WebServiceWrapper.PostWithHeader("/manageUsers/UpdateProfileData", updateEmployee, 60000, {
    "Content-Type": "multipart/form-data",
  });
}

function DeleteEmployee(employeeId) {
   return WebServiceWrapper.Get(`/manageUsers/DeleteEmployee?employeeId=${employeeId}`);
 
 }


import React, { useState } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);

  // Custom event click handler
  const handleEventClick = (clickInfo) => {
    setSelectedDate(clickInfo.event.start.toISOString().split('T')[0]); // Save the clicked date
    setSelectedEvents(clickInfo.event.extendedProps.events); // Save the events for the clicked date
  };

  // Custom function to render the event content
  const renderEventContent = (arg) => {
    return (
      <div className="rec">
        <div>{arg.timeText}</div>
        <div>{arg.event._def.title}</div>
        {arg.event.extendedProps.events.map((record, index) => (
          <div key={index}>{record}</div>
        ))}
      </div>
    );
  };

  return (
    <> 
    <div className="calendar-container">
      <div className="calendar">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={[
            { title: 'Event 1', date: '2024-05-10', events: ['Record 1', 'Record 2', 'Record 3'] },
            { title: 'Event 2', date: '2024-05-15', events: ['Record 180', 'Record 2787', 'Record 787'] },
            // Add more events as needed
          ]}
          eventClick={handleEventClick} // Attach the custom event click handler
          eventContent={renderEventContent} // Attach the custom event content 
        />
      </div>
      <div className="custom-row" style={{textAlign:'center'}}>
        <h8 style={{ gridColumnStart: 7 }}>Week Total</h8>
        {[1, 2, 3, 4, 5].map((_, index) => (
          <div key={index} className="week-total">10</div>
        ))}
      </div>
     
    </div>
    <div className="records">
    {selectedDate && (
      <div>
        <h2>Records for {selectedDate}</h2>
        <ul>
          {selectedEvents.map((event, index) => (
            <li key={index}>{event}</li>
          ))}
        </ul>
      </div>
    )}
  </div></>
  );
};

export default Calendar;

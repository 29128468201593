export const ToastTimeout = 8000;
export const HTTPResponse = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  NotAllowed: 405,
  Conflict: 409,
  InternalServerError: 500,
};

export const FileIcons = {
  EXCEL: "../Assets/images/xls.png",
  PDF: "../Assets/images/pdf.png",
  WORD: "../Assets/images/word.png",
  IMAGE: "../Assets/images/img.png",
  VIDEO: "../Assets/images/video.png",
  PNG: "../Assets/images/png.png",
  WEBM: "../Assets/images/webm.png",
  TEXT: "../Assets/images/text.png",
};
export const AppContextInitialState = {
  showToast: false,
  isLoading: false,
  profileImage: undefined,
  projectId: undefined,
  projectName: undefined,
  reportId: undefined,
  reportDate: undefined,
  roleId: undefined,
  actionItemId: undefined,
  actionItemClear: false,
  logout: undefined,
  isDirty: false,
};
export const LetteredAvatarMsg = {
  TwoLetters: 2,
  OneLetter: 1,
};
export const ToastMessageType = {
  Success: 0,
  Fail: 1,
};

export const MaxCharacterCount = {
  Empty: 0,
  LoginEmail: 50,
  LoginPassword: 50,
  EditReportEditor: 500,
  projectName: 25,
  projectClientName: 25,
  swimlaneName: 25,
  UserName: 3,
  ReportTimeSpent: 4,
  UserFNLN: 25,
  UserEMail: 50,
  RootCauseDescription: 250,
  EditCommentsEditor: 2000,
  Designation: 25,
  ProfilePassword: 25,
  PhaseAndRelease: 100,
  Category: 50,
  WorkItem: 50, EstDescription: 250, EstComments: 250
};
export const MinPasswordLength = {
  resetPasswordLength: 8
}
export const ActionItem = {
  TitleMaxLength: 500,
  estimatesMaxLength: 4,
}
//the numbers are not in order because ActionItemTypeId is not in order in db.
export const ActionItemTypes = [
  { value: 1, label: "Feature", imagePath: "../Assets/images/WorkItemFeature.png" },
  {
    value: 2,
    label: "Task",
    imagePath: "../Assets/images/WorkItemTask.png",
  },
  { value: 6, label: "Bug", imagePath: "../Assets/images/WorkItembug.png" },
  { value: 7, label: "Code Review", imagePath: "../Assets/images/WorkItemCode Review.png" },
  { value: 8, label: "Meeting/Training", imagePath: "../Assets/images/WorkItemMeeting.png" },
  { value: 9, label: "Project Management", imagePath: "../Assets/images/WorkItemProject Planning.png" },
  { value: 10, label: "Project Setup/Deployment", imagePath: "../Assets/images/WorkItemProject Setup-Deployment.png" },
  { value: 11, label: "QA", imagePath: "../Assets/images/WorkItemqa 1.png" },


];

export const WorkItemTaskCreation = [
  { value: 99, label: 'Task Creation' },
  { value: 999, label: 'Add other' }//MS:to differentitate for add other option in WorkDone dropdown
];

export const ACTION_ITEM_TYPE = {
  1: "../Assets/images/WorkItemFeature.png",
  2: "../Assets/images/WorkItemTask.png",
  6: "../Assets/images/WorkItembug.png",
  7: "../Assets/images/WorkItemCode Review.png",
  8: "../Assets/images/WorkItemMeeting.png",
  9: "../Assets/images/WorkItemProject Planning.png",
  10: "../Assets/images/WorkItemProject Setup-Deployment.png",
  11: "../Assets/images/WorkItemqa 1.png",
};

export const ACTION_ITEM_TYPE_TITLE = {
  1: "Feature",
  2: "Task",
  3: "Build",
  5: "other",
  6: "Bug",
  7: "Code Review",
  8: "Meeting",
  9: "Project Management",
  10: "Project Setup/Deployment",
  11: "QA"
};

export const MISC_OTHER_OPTIONS = [
  { value: 1, label: "Training" },
  { value: 2, label: "Meeting" },
  { value: 3, label: "Downtime" },
  { value: 4, label: "Event" },
  { value: 5, label: "Leave" },
  { value: 6, label: "ISMS" },
  { value: 7, label: "Other" },
];

export const TASK_PRIO = {
  1: "../Assets/images/low-priority.png",
  2: "../Assets/images/moderate-priority.png",
  3: "../Assets/images/high.png",
  4: "../Assets/images/critical.png",
};
export const MonthNames = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sept",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};
export const PriorityList = [
  { value: 4, label: "Critical", imagePath: "../Assets/images/critical.png" },
  { value: 3, label: "High", imagePath: "../Assets/images/high.png" },
  {
    value: 2,
    label: "Medium",
    imagePath: "../Assets/images/moderate-priority.png",
  },
  {
    value: 1,
    label: "Low",
    imagePath: "../Assets/images/low-priority.png",
  }

];
export const ActionItemStatusList = [
  { value: 1, label: "To Do" },
  { value: 2, label: "In Progress" },
  { value: 3, label: "Ready For Dev" },
  { value: 4, label: "Ready For Prod" },
  { value: 5, label: "Done" },
];


export const StatusList = [
  { label: "To Do", imagePath: "../Assets/images/to-do.png" },
  { label: "Testing", imagePath: "../Assets/images/testing.png" },
  {
    label: "Done",
    imagePath: "../Assets/images/done.png",
  },
  {
    label: "In Progress",
    imagePath: "../Assets/images/work-in-progress.png",
  },
  {
    label: "Dev",
    imagePath: "../Assets/images/dev.png",
  },
  {
    label: "Prod",
    imagePath: "../Assets/images/Prod.png",
  }

];
/* <summary>
date: 26-06-2024
Name: Mustakim 
description: Status list for Misc type
<summary>*/
export const StatusListMiscType = [
  { label: "To Do", imagePath: "../Assets/images/to-do.png" },
  {
    label: "Done",
    imagePath: "../Assets/images/done.png",
  },
  {
    label: "In Progress",
    imagePath: "../Assets/images/work-in-progress.png",
  },


];
export const RootCauseTypeList = [
  { value: 1, label: "Code Issues", imagePath: "../Assets/images/code-issues.png" },
  { value: 2, label: "Compatibility Issues", imagePath: "../Assets/images/compatability-issues.png" },
  {
    value: 3,
    label: "Data Issues",
    imagePath: "../Assets/images/data-issues.png",
  },
  {
    value: 4,
    label: "Environmental Issues",
    imagePath: "../Assets/images/environmental-issues.png",
  },
  {
    value: 5,
    label: "Incorrect Requirement Specifications",
    imagePath: "../Assets/images/incorrect-requirement-specifications.png",
  },
  {
    value: 6,
    label: "Merge Issues",
    imagePath: "../Assets/images/merge-issues.png",
  },
  {
    value: 7,
    label: "Network issues",
    imagePath: "../Assets/images/network-issues.png",
  },
  {
    value: 8,
    label: "Performance Issues",
    imagePath: "../Assets/images/performance-issues.png",
  }, {
    value: 9,
    label: "Requirement Miss",
    imagePath: "../Assets/images/requirement-miss.png",
  },
  {
    value: 10,
    label: "Responsive issues",
    imagePath: "../Assets/images/responsive-issues.png",
  },
  {
    value: 11,
    label: "Security Issues",
    imagePath: "../Assets/images/security-issues.png",
  },
  {
    value: 12,
    label: "Tester Error",
    imagePath: "../Assets/images/tester-error.png",
  }

];

export const PriorityListTitle = {
  1: "Low",
  2: "Medium",
  3: "High",
  4: "Critical"
};
export const AllowedFileFormats = [
  "image/png",
  "image/jpeg",
  ".csv",
  ".doc",
  ".docx",
  "application/pdf",
  "application/PDF",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".txt", "text/plain",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
// Maintain lower case
export const ProjectIDDependentPages = [
  "overview",
  "createactionitem",
  "editactionitem",
];

export const DefaultAGGridProps = {
  ROWS_PER_PAGE: 10,
  ROW_HEIGHT: 80,
  TABLE_HEIGHT: 32 * 10 + 100,
  MONTHLY_QUIZ_ROWS_PER_PAGE: 12,
};

export const PAGE_ROUTE_ID = {
  HOME: 1,
  PROJECT: 2,
  // PROJECTNAME: 3,
  OVERVIEW: 4,
  MANAGE_USER: 5,
  EDIT_ACTION_ITEM: 6,
  REPORT: 7,
  ADD_REPORT: 8,
  TIME_TRACKER: 9,
  ADD_PROJECT: 10,
  EDIT_PROJECT: 11,
  CREATE_ACTION: 12,
  ADD_EMP: 13,
  EDIT_EMP: 14,
  MyTasks: 15,
  ROLES_PERMISSION: 16,
  EDI_REPORT: 17,
  ESTIMATION: 18
  //TIME_TRACKER:17,
};

export const BREADCRUM_TYPE = {
  DEFAULT: 0,
  HOME: 1,
  PROJECT: 2,
  OVERVIEW: 3,
  MANAGE_USER: 4,
  EDIT_ACTION_ITEM: 5,
  CREATE_ACTION: 6,
  REPORT: 7,
  ADD_REPORT: 8,
  TIME_TRACKER: 9,
  ADD_PROJECT: 10,
  EDIT_PROJECT: 11,
  ADD_EMP: 13,
  EDIT_EMP: 14,
  MYTASKS: 15,
  ROLES_PERMISSION: 16,
  EDI_REPORT: 17,
  ESTIMATION: 18,
  //TIME_TRACKER:17,
};
export const ckEditorRemoveButtons =
  "ImageButton,Button,Undo,SelectAll,Spell Checker,Checkbox,Redo,Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Form,Radio,TextField,Textarea,Select,HiddenField,About,CopyFormatting,RemoveFormat,Language,Flash,Iframe";
export const BREADCRUMB_PAGE_ROUTES = [
  // HOME ROUTE
  // {
  //   id: PAGE_ROUTE_ID.HOME,
  //   breadCrumbType: BREADCRUM_TYPE.DEFAULT,
  //   pageName: "Home",
  //   route: "/Home",
  //   isActive: true,
  //   isSelected: false,
  // },

  // PROJECT ROUTE
  {
    id: PAGE_ROUTE_ID.DEFAULT,
    breadCrumbType: BREADCRUM_TYPE.DEFAULT,
    //BREADCRUM_TYPE.PROJECT,
    pageName: "Manage Projects",
    route: "/ManageProjects",
    isActive: true,
    isSelected: false,
  },
  // PROJECTNAME ROUTE
  {
    id: PAGE_ROUTE_ID.PROJECTNAME,
    // breadCrumbType: BREADCRUM_TYPE.PROJECT,
    // pageName: "PROJECT_NAME",
    // route: "/ManageProjects",
    isActive: true,
    isSelected: false,
  },
  // OVERVIEW ROUTE
  {
    id: PAGE_ROUTE_ID.OVERVIEW,
    breadCrumbType: BREADCRUM_TYPE.PROJECT,
    pageName: "/Sprint Overview",
    route: "/Overview",
    isActive: true,
    isSelected: false,
  },
  // EDIT ACTION ITEM ROUTE
  {
    id: PAGE_ROUTE_ID.EDIT_ACTION_ITEM,
    breadCrumbType: BREADCRUM_TYPE.PROJECT,
    pageName: "EDIT_ACTION_ITEM",
    route: "/EditActionItem",
    isActive: true,
    isSelected: false,
  },

  // CREATE ACTION ROUTE
  {
    id: PAGE_ROUTE_ID.CREATE_ACTION,
    breadCrumbType: BREADCRUM_TYPE.PROJECT,
    pageName: "/Add Work Item",
    route: "/AddActionItem",
    isActive: true,
    isSelected: false,
  },

  // MANAGE USER ROUTE
  {
    id: PAGE_ROUTE_ID.MANAGE_USER,
    breadCrumbType: BREADCRUM_TYPE.MANAGE_USER,
    pageName: "Manage Employees",
    route: "/ManageUsers",
    isActive: true,
    isSelected: false,
  },

  {
    id: PAGE_ROUTE_ID.ROLES_PERMISSION,
    breadCrumbType: BREADCRUM_TYPE.MANAGE_USER,
    pageName: "/Roles & Permissions",
    route: "/RolesPermissions",
    isActive: true,
    isSelected: false,
  },

  // REPORTS ROUTE
  {
    id: PAGE_ROUTE_ID.REPORT,
    breadCrumbType: BREADCRUM_TYPE.REPORT,
    pageName: "Time Logs",
    route: "/TimeLog",
    isActive: true,
    isSelected: false,
  },

  //ADD REPORTS USER ROUTE
  {
    id: PAGE_ROUTE_ID.ADD_REPORT,
    breadCrumbType: BREADCRUM_TYPE.REPORT,
    pageName: "/Add Time Log",
    route: "/AddTimeLogging",
    isActive: true,
    isSelected: false,
  },

  //EDIT REPORTS USER ROUTE

  {
    id: PAGE_ROUTE_ID.EDI_REPORT,
    breadCrumbType: BREADCRUM_TYPE.REPORT,
    pageName: "/Edit Time Log",
    route: "/EditTimeLogging",
    isActive: true,
    isSelected: false,
  },

  {
    id: PAGE_ROUTE_ID.TIME_TRACKER,
    breadCrumbType: BREADCRUM_TYPE.REPORT,
    pageName: "/Monthly Time Tracker",
    route: "/MonthlyTimeTracker",
    isActive: true,
    isSelected: false,
  },

  {
    id: PAGE_ROUTE_ID.ADD_PROJECT,
    breadCrumbType: BREADCRUM_TYPE.PROJECT,
    pageName: "/Add Project",
    route: "/AddProject",
    isActive: true,
    isSelected: false,
  },

  {
    id: PAGE_ROUTE_ID.EDIT_PROJECT,
    breadCrumbType: BREADCRUM_TYPE.PROJECT,
    pageName: "/Edit Project",
    route: "/EditProject",
    isActive: true,
    isSelected: false,
  },

  /* <summary>
  date: 13-04-2024
  Name: MS
  description:  MyTasks page
  <summary>*/
  {
    id: PAGE_ROUTE_ID.MyTasks,
    breadCrumbType: BREADCRUM_TYPE.MYTASKS,
    //BREADCRUM_TYPE.PROJECT,
    pageName: "My Tasks",
    route: "/MyTasks",
    isActive: true,
    isSelected: false,
  },

  //ADD REPORTS USER ROUTE
  {
    id: PAGE_ROUTE_ID.ADD_EMP,
    breadCrumbType: BREADCRUM_TYPE.MANAGE_USER,
    pageName: "/Add Employee",
    route: "/AddEmployee",
    isActive: true,
    isSelected: false,
  },

  //EDIT REPORTS USER ROUTE
  {
    id: PAGE_ROUTE_ID.EDIT_EMP,
    breadCrumbType: BREADCRUM_TYPE.MANAGE_USER,
    pageName: "/Edit Employee",
    route: "/EditEmployee",
    isActive: true,
    isSelected: false,
  },
  // {
  //   id: PAGE_ROUTE_ID.TIME_TRACKER,
  //   breadCrumbType: BREADCRUM_TYPE.TIME_TRACKER,
  //   pageName: "/Time Tracker",
  //   route: "/Cal",
  //   isActive: true,
  //   isSelected: false,
  // },
  {
    id: PAGE_ROUTE_ID.ESTIMATION,
    breadCrumbType: BREADCRUM_TYPE.ESTIMATION,
    pageName: "/Estimation",
    route: "/GlobalEstimation",
    isActive: true,
    isSelected: false,
  },
  // {
  //   id: PAGE_ROUTE_ID.TSHIRT_SIZE,
  //   breadCrumbType: BREADCRUM_TYPE.TSHIRT_SIZE,
  //   pageName: "/T Shirt Size",
  //   route: "/GlobalEstimation",
  //   isActive: true,
  //   isSelected: false,
  // },
];

export const Team = [
  { value: 1, label: "Design" },
  { value: 2, label: "Dev" },
  { value: 3, label: "QA" },
  { value: 4, label: "IT" },
  { value: 5, label: "Admin" },


];
export const Roles = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Employee" },
  { value: 3, label: "Project Lead" },

];
export const TEAM_TYPE = {
  DESIGN: 1,
  DEV: 2,
  QA: 3,
};
export const STATUS_TYPE = {

  ACTIVE: 1,
  INACTIVE: 0

}
export const ROLE_TYPE = {
  ADMIN: 1,
  EMPLOYEE: 2,
  LEAD: 3,
};

export const ZINDEX_LEVELS = {
  LEVEL_1: Number.MAX_SAFE_INTEGER,
  LEVEL_2: 999_999,
};




export const ROLE_ID = {
  DESIGN: 1,
  DEV: 2,
  QA: 3
}

export const toolbarOptionsEditor = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'remove',
    'history',
    'fontFamily',
  ],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    className: 'custom-class',
  },
  fontSize: {
    options: [12, 14, 16, 18, 24, 30, 36, 48],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right', 'justify'],
  },

  link: {
    inDropdown: false,
  },
  fontFamily: {
    inDropdown: true,
    options: ['Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Verdana', 'Calibri', 'Cambria', 'Georgia',
      'Palatino', 'Garamond', 'Book Antiqua', 'Arial Black', 'Comic Sans MS', 'Impact', 'Lucida Sans Unicode',],
    className: 'custom-class',
  },
};


export const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'remove',
    'history',
    'fontFamily',
  ],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    className: 'custom-class',
  },
  fontSize: {
    options: [12, 14, 16, 18, 24, 30, 36, 48],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right', 'justify'],
  },

  link: {
    inDropdown: false,
  },
  fontFamily: {
    inDropdown: true,
    options: ['Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Verdana', 'Calibri', 'Cambria', 'Georgia',
      'Palatino', 'Garamond', 'Book Antiqua', 'Arial Black', 'Comic Sans MS', 'Impact', 'Lucida Sans Unicode',],
    className: 'custom-class',
  },
  // embedded: {
  //   defaultSize: {
  //     height: 'auto',
  //     width: 'auto',
  //   },
  // },
  // emoji: {
  //   // eslint-disable-next-line
  //   icon: () => <span>😀</span>,
  // },
  // image: {
  //   icon: () => <FontAwesomeIcon icon={faImage} />,
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   urlEnabled: true,
  //   uploadEnabled: true,
  //   alignmentEnabled: true,
  //   uploadCallback: undefined,
  //   previewImage: false,
  //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
  //   alt: { present: false, mandatory: false },
  //   defaultSize: {
  //     height: 'auto',
  //     width: 'auto',
  //   },
  // },
  // remove: {
  //   icon: () => <FontAwesomeIcon icon={faEraser} />,
  // },
  // history: {
  //   icon: () => <FontAwesomeIcon icon={faHistory} />,
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  //   options: ['undo', 'redo'],
  // },
};



export const ESTIMATION_AREA = {
  General: 1,
  Coding: 2,
  Database: 3,
  Testing: 4
};
export const ESTIMATION_AREA_OPTIONLIST = [
  { label: "General", value: 1 },
  { label: "Coding", value: 2 },
  { label: "Database", value: 3 },
  { label: "Testing", value: 4 }
];

import CryptoJS from "crypto-js";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);


export const sanitizeHTML = (htmlContent) => {
  return DOMPurify.sanitize(htmlContent)
}



/* <summary>
date: 08-09-2023
Name: AP
description: Get decrypted userID in action from local storage
<summary>*/
export const GetLoggedInUserID = () => {
  const userId = GetLocalStorageData("userId");
  return Number(userId);
};
/* <summary>
date: 22-06-2024
Name: Mustakim 
description: to convert to hrs:min format.eg) 1.23 as 1h:23m
<summary>*/
export const formatLoggedHours = (loggedHours) => {
  if (loggedHours === null || loggedHours === undefined) return '';

  const [hoursPart, minutesPart] = loggedHours.toString().split('.');

  if (hoursPart > 0 && minutesPart !== undefined) {
    const formattedMinutes = minutesPart.padEnd(2, '0').substring(0, 2); // Ensure only two digits for minutes
    return `${hoursPart}h:${formattedMinutes}m`;
  } else if (hoursPart > 0) {
    return `${hoursPart}h`;
  } else if (minutesPart !== undefined) {
    const formattedMinutes = minutesPart.padEnd(2, '0').substring(0, 2); // Ensure only two digits for minutes
    return `${formattedMinutes}m`;
  } else {
    return '0m';
  }
};

export const formatCorrectHours = (loggedHours) => {
  if (loggedHours === null || loggedHours === undefined) return '';

  const [hoursPart, minutesPart = '0'] = loggedHours.toString().split('.');

  // Convert to total minutes
  const totalMinutes = parseInt(hoursPart, 10) * 60 + parseInt(minutesPart.padEnd(2, '0').slice(0, 2), 10);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours === 0) {
    return `${minutes}m`;
  } else if (minutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h:${String(minutes).padStart(2, '0')}m`;
  }
};
export const WeeklyFormatCorrectHours = (loggedHours) => {
  if (loggedHours === null || loggedHours === undefined) return '';

  const [hoursPart, minutesPart] = loggedHours.toString().split('.');

  if (hoursPart > 0 && minutesPart !== undefined) {
    const formattedMinutes = minutesPart.padEnd(2, '0').substring(0, 2); // Ensure only two digits for minutes
    return `${hoursPart}h:${formattedMinutes}m`;
  } else if (hoursPart > 0) {
    return `${hoursPart}h`;
  } else if (minutesPart !== undefined) {
    const formattedMinutes = minutesPart.padEnd(2, '0').substring(0, 2); // Ensure only two digits for minutes
    return `${formattedMinutes}m`;
  } else {
    return '0m';
  }
};


// export const formatLoggedHours =  (decimalHours) => {

//   const hours = Math.floor(decimalHours);
//   const minutes = Math.round((decimalHours - hours) * 100) || 0;
//   return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
//   //return `${hours}:${minutes}`

// };
/* <summary>
date: 08-09-2023
Name: AP
description: Add data to local storage in encrypted form
<summary>*/
export const AddLocalStorageData = (storageKey, value) => {
  const encryptedValue = EncryptUsingAES256(JSON.stringify(value));
  const encryptedKey = EncryptUsingAES256(storageKey);
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  }
  localStorage.setItem(encryptedKey, encryptedValue);
};
/* <summary>
  date: 08-09-2023
  Name: AP
  description:get decrypted data from local storage
  <summary>*/
export const GetLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return JSON.parse(DecryptUsingAES256(encryptedValue));
    }
  } catch (error) { }
  return encryptedValue;
};

/* <summary>
date: 08-09-2023
Name: AP
description:  Encryption using CryptoJS
<summary>*/
export const EncryptUsingAES256 = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};
/* <summary>
date: 08-09-2023
Name: AP
description: Decryption using CryptoJS
<summary>*/
export const DecryptUsingAES256 = (decString) => {
  const decrypted = CryptoJS.AES.decrypt(decString, key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
/* <summary>
date: 08-09-2023
Name: AP
description: Check if string is empty/undefined/null
<summary>*/
export const isStringEmpty = (text) => {
  return !text || text.trim().length === 0;
};
/* <summary>
date: 08-09-2023
Name: AP
description: Email validation
<summary>*/
export const IsEmailValid = (emailAddress) => {
  const emailRegexString =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  return emailRegexString.test(emailAddress);
};
/* <summary>
date: 08-09-2023
Name: AP
description: Validates password(Criteria: atleast 1 upper, lower case, 1 character, 1 number and length not less than 8)
<summary>*/
export const IsPasswordValid = (passowrd) => {
  const passwordRegexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  return passwordRegexString.test(passowrd);
};
/* <summary>
date: 14-09-2023
Name: AP
description: Allows only alpha-numeric characters and whitespaces
<summary>*/
export const ValidateInputField = (text) => {
  const regexString = /^[a-zA-Z0-9\s]*$/;
  return regexString.test(text);
};
/* <summary>
date: 25-09-2023
Name: AP
description: Restricts to positive numbers with 2 decimal places only
<summary>*/
export const AllowPositiveNumbersWithTwoDecimal = (value) => {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return value.match(regex)[0];
};

/* <summary>
date: 4-10-2023
Name: NG
description:method for converting html to editor state
<summary>*/
export const ConvertFromHTML = (html) => {
  try {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    } else {
      return EditorState.createEmpty();
    }
  } catch (error) {
    return EditorState.createEmpty();
  }
};

/* <summary>
date: 4-10-2023
Name: NG
description:method for converting  editor state to html 
<summary>*/
export const ConvertToHtml = (editorState) => {
  try {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState, "", true);
    return markup;
  } catch (error) {
    return "";
  }
};

/* <summary>
date: 11-04-2023
Name: GM
description: get decrypted data from local storage
<summary>*/
export const RemoveLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  localStorage.removeItem(encryptedKey);
};


/* <summary>
date: 22/02/2023
Name: SYJ
description: method to show page name on hover of browser tab
<summary> */
export function changePageTitle(pageName) {
  function insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return string;
  }
  const title = "eDOT Pulse";
  var path = pageName === "" ? window.location.pathname : pageName;
  var page = path.split("/").pop();
  page = insertSpaces(page)
  if (page != "")
    document.title = page; 
  //+ " - " + title;
}

//SYJ - Added Comman way to Use it.
export function formatCustomDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const ordinalDay = addOrdinalSuffix(day);
  return `${ordinalDay} ${month} ${year}`;
}

// Function to add ordinal suffix to the day (e.g., 1st, 2nd, 3rd, etc.)
function addOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export const stripHTMLTags = (html) => {
  // Create a temporary DOM element to parse the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Function to recursively extract text content
  const extractText = (node) => {
    let text = '';

    node.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        if (child.tagName === 'LI') {
          if (node.tagName === 'OL') {
            // Add numbered list item
            const itemNumber = Array.from(node.children).indexOf(child) + 1;
            text += `\n${itemNumber}. ${extractText(child)}`;
          } else {
            // Add bullet for unordered list item
            text += `\n• ${extractText(child)}`;
          }
        } else {
          text += extractText(child); // Recursively extract text for other elements
        }
      }
    });

    return text;
  };

  let extractedText = extractText(tempDiv);

  // Replace common HTML entities
  extractedText = extractedText.replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");

  // Normalize whitespace
  extractedText = extractedText.replace(/\s+/g, ' ').trim();

  return extractedText;
};

// export const HTMLCellRenderer = (html) => {
//   // Sanitize the HTML to prevent XSS attacks
//   const sanitizedHTML = DOMPurify.sanitize(html);
//   //alert(sanitizedHTML);

//   var data = <div style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: html }} />
//   console.log(data);
//   return data;
// };
export const calculateRows = (text) => {
  const charCount = text.length;
  return Math.ceil(charCount / 32) || 1; // Minimum of 1 row
};
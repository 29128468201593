import React, { useContext, useEffect, useState } from "react";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import DatePicker from "react-datepicker";
import {
  HTTPResponse,
  ToastMessageType,
  MaxCharacterCount,
  toolbarOptions,
  AllowedFileFormats,
  WorkItemTaskCreation,
  ROLE_TYPE,
} from "../Helpers/Enums";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { ActionItemService } from "../WebApiServices/ActionItem.service";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  ConvertToHtml,
  sanitizeHTML,
  ConvertFromHTML,
  GetLoggedInUserID,
  GetLocalStorageData,
} from "../Helpers/Utilities";
import { CommonErrorMessages, ToastMessages } from "../Helpers/HelperText";
import ErrorField from "../Components/ErrorField";
import Select, { components } from "react-select";
import AddMiscellenousOther from "./AddMiscellenousOther";
import DeleteModal from "./DeleteModal";

const AddEditComments = ({
  isOpen,
  setShowModal,
  workLogDetail,
  projectId,
  actionItemId,
  handleDroppedFiles,
  workItemType,
  onCommentsSubmit,
  isMainPageSave,previousCommentData,isCommentEdit
}) => {
  const NUMBER_REGEX = /^\d*(\.\d{0,5})?$/s;
  const { ShowToastMessage } = useContext(AppContext);
  const [isAddOtherOpen, setIsAddOtherOpen] = useState(false);
  const { LogOut } = useUIContext();
  const [addEditWorkLogDetails, setAddEditWorkLogDetails] = useState({
    workLogDetailId: 0,
    startDate: new Date(),
    timeSpent: { hours: "00", minutes: "00" },
    workDone: 0,
    description: EditorState.createEmpty(),
    issuesFixed: 0,
  });
  const [loadOptions, setLoadOptions] = useState(Boolean);
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({
    timeSpent: false,
    startDate: false,
    workDone: false,
    issuesFixed: false,
    description: false,
  });
  const HandleFieldChange = (e) => {
    const { name, value } = e.target;
    if (NUMBER_REGEX.test(value)) {
      setAddEditWorkLogDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  };
  const [optionIdToDelete, setOptionIdToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDefaultOptions, setIsDefaultOptions] = useState([]);
  // const convertHoursToHHMM = (decimalHours) => {
  //
  //   const hours = Math.floor(decimalHours);
  //   const minutes = Math.round((decimalHours - hours) * 60);
  //   return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  // };
  useEffect(() => { }, [addEditWorkLogDetails.timeSpent]);
  const convertHoursToHHMM = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 100) || 0;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
    //return `${hours}:${minutes}`
  };
  // const handleTimeChange = (e) => {
  //
  //   const value=e.target.value
  //   if (value === null || value === '') {
  //     value = '00:00'; // Set to default value if empty
  //   }
  //   setAddEditWorkLogDetails((prev) => ({
  //     ...prev,
  //     timeSpent: value,
  //   }));
  // };
  //   const handleTimeChange = (e) => {
  //     const { id, value } = e.target;
  // if(value.length>2) return;
  //     let hours = addEditWorkLogDetails.timeSpent.hours;
  //     let minutes = addEditWorkLogDetails.timeSpent.minutes;

  //     if (id === 'hrs') {
  //       hours = value;
  //     } else if (id === 'min') {
  //       minutes = value;
  //     }

  //     setAddEditWorkLogDetails((prev) => ({
  //       ...prev,
  //       timeSpent: {
  //         hours: hours
  //         //.padStart(2, '0')
  //         , // Ensure two-digit format
  //         minutes: minutes
  //         // .padStart(2, '0')
  //         , // Ensure two-digit format
  //       },
  //     }));
  //     setErrors((prev)=>({
  //      ...prev, timeSpent:false
  //     }))
  //   };
  const handleTimeChange = (e) => {
    const { id, value } = e.target;

    // Ensure the value is a valid number
    const numericValue = parseInt(value, 10);
    if (isNaN(numericValue)) return;

    let hours = addEditWorkLogDetails.timeSpent.hours;
    let minutes = addEditWorkLogDetails.timeSpent.minutes;

    // Update hours or minutes based on the input id
    if (id === "hrs" && numericValue >= 0 && numericValue <= 99) {
      hours = numericValue.toString().padStart(2, "0");
    } else if (id === "min" && numericValue >= 0 && numericValue <= 59) {
      minutes = numericValue.toString().padStart(2, "0");
    }

    setAddEditWorkLogDetails((prev) => ({
      ...prev,
      timeSpent: {
        hours: hours,
        minutes: minutes,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      timeSpent: false,
    }));
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  /* <summary>
date: 17-04-2024
Name: Mustakim
description: Handled date picker issue where one previous date was selected..
<summary>*/
  // useEffect(() => {
  //   if (workLogDetail) {
  //
  //     const [day, month, year] = workLogDetail.loggedDateStr.split('-');

  //     const nd = new Date(year, month - 1, day);
  //           setAddEditWorkLogDetails((prev) => ({
  //       ...prev,
  //       timeSpent: workLogDetail.loggedHours,
  //       startDate:nd,
  //       description: ConvertFromHTML(workLogDetail.comments),
  //       workLogDetailId: workLogDetail.workLogDetailId,
  //     }));
  //
  //   }
  // }, []);
  // useEffect(() => {
  //   if (workLogDetail) {
  //
  //     setAddEditWorkLogDetails((prev) => ({
  //       ...prev,
  //      // timeSpent: workLogDetail.loggedHours,
  //      timeSpent: convertHoursToHHMM(workLogDetail.loggedHours),

  //       startDate: new Date(workLogDetail.loggedOn),
  //       description: ConvertFromHTML(workLogDetail.comments),
  //       workLogDetailId: workLogDetail.workLogDetailId,
  //       workDone:workLogDetail.workDone
  //     }));
  //   }
  // }, [workLogDetail]);

  useEffect(() => {
    if (actionItemId === 0 && workLogDetail !== undefined) {
      const { hours, minutes } = convertHoursToHHMM(workLogDetail.loggedHours);
      const hrsMin = convertHoursToHHMM(workLogDetail.loggedHours);
      const [hrs, min] = hrsMin.split(":");
      setAddEditWorkLogDetails((prev) => ({
        ...prev,
        timeSpent: { hours: hrs, minutes: min },
        startDate:
          workLogDetail.loggedOn === undefined
            ? new Date()
            : new Date(workLogDetail.loggedOn),
        description: ConvertFromHTML(workLogDetail.comments),
        workLogDetailId: workLogDetail.workLogDetailId,
        workDone: workLogDetail.workDone,
        issuesFixed: workLogDetail.issuesFixed,
      }));
    } else if (actionItemId !== 0 && workLogDetail !== undefined) {
      const { hours, minutes } = convertHoursToHHMM(workLogDetail.loggedHours);
      const hrsMin = convertHoursToHHMM(workLogDetail.loggedHours);
      const [hrs, min] = hrsMin.split(":");
      setAddEditWorkLogDetails((prev) => ({
        ...prev,
        timeSpent: { hours: hrs, minutes: min },
        startDate: new Date(workLogDetail.loggedOn),
        description: ConvertFromHTML(workLogDetail.comments),
        workLogDetailId: workLogDetail.workLogDetailId,
        workDone: workLogDetail.workDone,
        issuesFixed: workLogDetail.issuesFixed,
      }));
    }
    if (actionItemId === 0 && workLogDetail === undefined) {
      const { hours, minutes } = convertHoursToHHMM(0.15);
      const hrsMin = convertHoursToHHMM(0.15);
      const [hrs, min] = hrsMin.split(":");
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
        }-${currentDate.getDate()}`;

      setAddEditWorkLogDetails((prev) => ({
        ...prev,
        timeSpent: { hours: hrs, minutes: min },
        startDate: new Date(),
        description: ConvertFromHTML("<p>Task created</p>"),
        workLogDetailId: 0,
        workDone: 99,
      }));
    }
  }, [workLogDetail]);
const EditPreviousComment=()=>{
  if (actionItemId !== 0 && previousCommentData !== undefined) {
    const { hours, minutes } = convertHoursToHHMM(previousCommentData.loggedHours);
    const hrsMin = convertHoursToHHMM(previousCommentData.loggedHours);
    const [hrs, min] = hrsMin.split(":");
    setAddEditWorkLogDetails((prev) => ({
      ...prev,
      timeSpent: { hours: hrs, minutes: min },
      startDate: new Date(previousCommentData.loggedOn),
      description: ConvertFromHTML(previousCommentData.comments),
      workLogDetailId: previousCommentData.workLogDetailId,
      workDone: previousCommentData.workDone,
      issuesFixed: previousCommentData.issuesFixed,
    }));
  }
}
  const ValidateField = () => {
    const isDescEmpty = sanitizeHTML(
      ConvertToHtml(addEditWorkLogDetails.description)
    );
    const commentsEmpty = '<p dir="auto"></p>\n';

    setErrors((prev) => ({
      ...prev,
      timeSpent:
        addEditWorkLogDetails.timeSpent === "" ||
        (addEditWorkLogDetails.timeSpent.hours === "00" &&
          addEditWorkLogDetails.timeSpent.minutes === "00"),

      startDate:
        addEditWorkLogDetails.startDate == undefined ||
        addEditWorkLogDetails.startDate == null,
      workDone:
        addEditWorkLogDetails?.workDone == undefined ||
        addEditWorkLogDetails?.workDone == 0 ||
        addEditWorkLogDetails.workDone == null,
      issuesFixed:
        addEditWorkLogDetails?.workDone === 17 &&
        addEditWorkLogDetails?.issuesFixed === 0,
      description: isDescEmpty === commentsEmpty,
    }));
    if (
      addEditWorkLogDetails.timeSpent === "" ||
      (addEditWorkLogDetails.timeSpent.hours === "00" &&
        addEditWorkLogDetails.timeSpent.minutes === "00") ||
      addEditWorkLogDetails.startDate == undefined ||
      addEditWorkLogDetails.startDate == null ||
      addEditWorkLogDetails?.workDone == 0 ||
      addEditWorkLogDetails.workDone == undefined ||
      addEditWorkLogDetails.workDone == null ||
      (addEditWorkLogDetails?.workDone === 17 &&
        addEditWorkLogDetails.issuesFixed === 0) ||
      isDescEmpty === commentsEmpty
    ) {
      return false;
    } else {
      return true;
    }
  };

  const HandleDateChange = (date) => {
    setAddEditWorkLogDetails((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const HandleEditorChange = (e) => {
    setAddEditWorkLogDetails((prev) => ({
      ...prev,
      description: e,
    }));
    setErrors((prev) => ({
      ...prev,
      description: false,
    }));
  };

  /* <summary>
  date: 11-03-2024
  Name: Mustakim Shaikh
  description: to allow only specific files on drag and drop
  <summary>*/
  const isValidFileType = (fileType) => {
    return AllowedFileFormats.includes(fileType.toLowerCase());
  };
  //Editer changes
  const handleDroppedFilesLocal = (files) => {
    const allowedFiles = Array.from(files).filter((file) =>
      isValidFileType(file.type)
    );

    if (allowedFiles.length === 0) {
      //ShowToastMessage(CreateActionItemErrorMsg.AllowedFileFormatTypes,ToastMessageType.Fail)

      return;
    }
    const createRenamedFile = (originalFile, newName) => {
      return new File([originalFile], newName, { type: originalFile.type });
    };

    // Function to rename file if it matches the condition
    function renameFileIfNeeded(file) {
      if (file.name === "image.png") {
        // const currentDate=new Date();
        // const currentTime=currentDate.getTime();
        // const formattedDate=`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}`;
        // const newName = `image.${formattedDate}-${currentTime}.png`;
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
          }-${currentDate.getDate()}`;
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, 0);

        const newName = `Screenshot.${formattedDate}-${hours}-${minutes}-${seconds}.png`;

        return createRenamedFile(file, newName);
      }
      return file;
    }

    // Apply renaming to allowed files
    const renamedFiles = allowedFiles.map(renameFileIfNeeded);

    handleDroppedFiles(renamedFiles);
    // handleDroppedFiles(allowedFiles);

    const fileContentArray = [];

    // Handle dropped files here
    for (const file of allowedFiles) {
      let fileName = file.name;
      if (fileName === "image.png") {
        const currentDate = new Date();
        const currentTime = currentDate.getTime();
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, 0);
        const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
          }-${currentDate.getDate()}-${hours}-${minutes}-${seconds}`;
        fileName = `Screenshot.${formattedDate}.png`;
      }
      if (file.type.startsWith("image/")) {
        // Handle image file
        fileContentArray.push(
          // <img src="
          `${fileName}`
          // " alt="Dropped Image" />
        );
      }
      //  else if (file.type.startsWith("text/")) {
      //   // Handle text file
      //   // You can customize this based on your needs
      //   const reader = new FileReader();
      //   reader.onload = (e) => {
      //     fileContentArray.push(`<p>${e.target.result}</p>`);
      //   };
      //   reader.readAsText(file);
      // }
      else {
        // Handle other file types or URLs
        // fileContentArray.push(`<a href="${URL.createObjectURL(file)}" target="_blank">${file.name}</a>`);
        fileContentArray.push(`${file.name}`);
      }
    }

    const currentContent =
      addEditWorkLogDetails.description.getCurrentContent();
    const contentStateWithFiles = Modifier.insertText(
      currentContent,
      currentContent.getSelectionAfter(),
      " " + fileContentArray.join("\n"),
      undefined,
      undefined
    );

    // Update the editor state with the new content
    const newEditorState = EditorState.push(
      addEditWorkLogDetails.description,
      contentStateWithFiles,
      "insert-characters"
    );

    // Update the editor state
    HandleEditorChange(newEditorState);
  };

  const SaveComment = async () => {
    try {
      if (ValidateField()) {
        const incomingDate = new Date(addEditWorkLogDetails.startDate);
        const utcDate = new Date(
          incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000
        );
        const formattedDate = utcDate.toISOString();
        const convertHHMMToDecimal = (hhmm) => {
          const [hours, minutes] = hhmm.split(":");
          // return hours + (minutes / 60);
          return `${hours}.${minutes}`;
        };

        // For display purposes, format minutes with leading zeros

        const min = addEditWorkLogDetails.timeSpent.minutes;
        const hrs = addEditWorkLogDetails.timeSpent.hours;
        const formattedMinutes = min.padStart(2, "0");
        const LoggedHours = convertHHMMToDecimal(`${hrs}:${formattedMinutes}`);
        //const imagePath= GetLocalStorageData("imagePath")
        const data = {
          //  loggedByPath: imagePath,
          ProjectId: projectId,
          ActionItemId: actionItemId,
          WorkLogDetailId: addEditWorkLogDetails.workLogDetailId,
          LoggedBy: GetLoggedInUserID(),

          Comments: sanitizeHTML(
            ConvertToHtml(addEditWorkLogDetails.description)
          ),

          LoggedOn: actionItemId !== 0 ? formattedDate : incomingDate,

          LoggedHours: Number(LoggedHours),
          WorkDone: addEditWorkLogDetails.workDone,
          IssuesFixed: Number(addEditWorkLogDetails.issuesFixed),
        };
        if (
          actionItemId !== undefined &&
          actionItemId !== null &&
          actionItemId !== 0
        ) {
          let response = await ActionItemService.SaveComments(data);
          if (response.status === HTTPResponse.OK) {            
            return setShowModal("save"), isMainPageSave();
          }
          if (response.status === HTTPResponse.Unauthorized) {
            LogOut();
          }
        } else {
          onCommentsSubmit(data);
          //isMainPageSave();
          return setShowModal("save");
        }
      }
    } catch (error) {
      console.log("error", error);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  // const HandleDropdownChange = (e) => {
  //   setAddEditWorkLogDetails((prev) => ({
  //     ...prev, workDone: e.value
  //   }))

  //   setErrors((prev) => ({
  //     ...prev,
  //     workDone: false
  //   }))
  // };
  const HandleDropdownChange = (e) => {
    if (e === null) {
      setAddEditWorkLogDetails((prev) => ({
        ...prev,
        workDone: 0,
      }));
    } else {
      if (e.value !== 999) {
        setAddEditWorkLogDetails((prev) => ({
          ...prev,
          workDone: e.value,
        }));

        setErrors((prev) => ({
          ...prev,
          workDone: false,
        }));
      } else {
        isOpen = false;
        setIsAddOtherOpen(true);
        setAddEditWorkLogDetails((prev) => ({
          ...prev,
          workDone: 0, // Reset or keep the workDone value empty
        }));
      }
    }
  };

  //SYJ -03/06/2024- Bind Dynamic WorkItemValues from DB for selected workItemType
  useEffect(() => {
    const fetchData = async () => {
      let workItemTypeRes;
      try {
        workItemTypeRes = await ActionItemService.GetWorkItemTypeFromDB();

        const Task = [];
        const Bug = [];
        const CodeReview = [];
        const Meeting = [];
        const Requirements = []; //option is changed to Project Management
        const ProjectDeployment = [];
        const QA = [];
        const Feature = [];

        // Clear arrays before populating
        Task.length = 0;
        Bug.length = 0;
        CodeReview.length = 0;
        Meeting.length = 0;
        Requirements.length = 0;
        ProjectDeployment.length = 0;
        Feature.length = 0;
        QA.length = 0;

        const workItemTypeOptions = {
          1: Feature,
          2: Task,
          6: Bug,
          7: CodeReview,
          8: Meeting,
          9: Requirements,
          10: ProjectDeployment,
          11: QA,
        };

        const newOption = WorkItemTaskCreation;
        const newOptions = WorkItemTaskCreation.slice(0, 1);
        const addOtherOption = WorkItemTaskCreation.slice(1, 2);


        if ([7, 8, 9, 10, 11].includes(workItemType)) {
          workItemTypeRes.data.forEach((item) => {
            const { key, value } = item; // key is the category name, value is the list of subvalues

            const arrayToUpdate =
              workItemTypeOptions[getWorkItemTypeIdByKey(parseInt(key))];

            if (arrayToUpdate) {
              arrayToUpdate.push(...addOtherOption);
              value.forEach((subItem) => {
                arrayToUpdate.push({
                  value: subItem.value,
                  label: subItem.key,
                  isDefault: subItem.isDefault,
                });
              });
            }
          });

          // setOptions((prevOptions) => [...prevOptions, newOption]);

          if (workItemType && workItemType in workItemTypeOptions) {
            setOptions([workItemTypeOptions[workItemType]]);
          }
        }
        else if (workItemType) {
          workItemTypeRes.data.forEach((item) => {
            const { key, value } = item; // key is the category name, value is the list of subvalues

            const arrayToUpdate =
              workItemTypeOptions[getWorkItemTypeIdByKey(parseInt(key))];

            if (arrayToUpdate) {
              arrayToUpdate.push(...newOption);
              value.forEach((subItem) => {
                arrayToUpdate.push({
                  value: subItem.value,
                  label: subItem.key,
                  isDefault: subItem.isDefault,
                });
              });
            }
          });

          // setOptions((prevOptions) => [...prevOptions, newOption]);

          if (workItemType && workItemType in workItemTypeOptions) {
            setOptions([workItemTypeOptions[workItemType]]);
          }
        }
        else {
          const arrayToUpdate = [];
          arrayToUpdate.push(newOptions);
          setOptions(arrayToUpdate);
        }
      } catch (error) {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        return;
      }
    };

    fetchData();
  }, [workItemType, loadOptions]);


  // Helper function to map key to workItemType
  const getWorkItemTypeIdByKey = (key) => {
    switch (key) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 6:
        return 6;
      case 7:
        return 7;
      case 8:
        return 8;
      case 9:
        return 9;
      case 10:
        return 10;
      case 11:
        return 11;
      default:
        return null;
    }
  };

  // useEffect(() => {

  //   const Task = [];
  //   const Bug = [];
  //   const CodeReview = [];
  //   const Meeting = [];
  //   const Requirements = [];

  //   let wokItemTypeRes = ActionItemService.GetWorkItemTypeFromDB();

  //   const workItemTypeOptions = {
  //     2: Task,
  //     3: Bug,
  //     4: CodeReview,
  //     5: Meeting,
  //     6: Requirements
  //   };

  //   if (workItemType && workItemType in workItemTypeOptions) {
  //     setOptions([workItemTypeOptions[workItemType]]);
  //   }
  // }, [workItemType]);

  // const Task = [
  //   { value: 1, label: "Documentation" },
  //   {
  //     value: 2,
  //     label: "Research",
  //   },
  //   {
  //     value: 3,
  //     label: "Dev QA meeting",
  //   },
  //   {
  //     value: 4,
  //     label: "Coding",
  //   },
  //   {
  //     value: 5,
  //     label: "Design",
  //   },
  //   {
  //     value: 6,
  //     label: "Unit Testing",
  //   },
  //   {
  //     value: 7,
  //     label: "QA Testing",
  //   },
  //   {
  //     value: 8,
  //     label: "Performance/Optimization",
  //   },
  // ];
  // const Bug = [
  //   { value: 9, label: "Documentation" },
  //   {
  //     value: 10,
  //     label: "Research",
  //   },
  //   {
  //     value: 11,
  //     label: "Dev QA meeting",
  //   },
  //   {
  //     value: 12,
  //     label: "Coding",
  //   },

  //   {
  //     value: 13,
  //     label: "Unit Testing",
  //   },
  //   {
  //     value: 14,
  //     label: "QA Testing",
  //   },
  //   {
  //     value: 15,
  //     label: "Performance/Optimization",
  //   },
  // ];
  // const CodeReview = [
  //   { value: 16, label: "SonarQube Scan" },
  //   {
  //     value: 17,
  //     label: "Code Smells",
  //   },
  // ];
  // const Meeting = [
  //   { value: 18, label: "Daily Standup" },
  //   {
  //     value: 19,
  //     label: "Dev QA meeting",
  //   },
  //   {
  //     value: 20,
  //     label: "Meeting with project lead",
  //   },
  // ];

  // const Requirements = [
  //   { value: 21, label: "Scope Documentation" },
  //   {
  //     value: 22,
  //     label: "Design",
  //   },
  //   {
  //     value: 23,
  //     label: "Scenario Writing ",
  //   },
  //   { value: 24, label: "Test link upload" },
  //   {
  //     value: 25,
  //     label: "Test link execution",
  //   },
  //   {
  //     value: 26,
  //     label: "Database Setup",
  //   },
  // ];

  const styles = {
    control: (base) => ({
      ...base,
      width: "300px",
    }),
  };
  const closeModal = () => {
    setIsAddOtherOpen(false);
  };
  // useEffect(()=>{
  //   debugger
  //   if(addEditWorkLogDetails.workDone===999){
  //     isOpen=false
  //     setIsAddOtherOpen(true);

  //   }
  // },[addEditWorkLogDetails])

  const loadWorkDone = () => {
    setLoadOptions(!loadOptions);
    // setAddEditWorkLogDetails((prev)=>({
    //   ...prev,
    //   workDone:0
    // }))
  };

  const GetOtherWorkItemValue = (data) => {
    setAddEditWorkLogDetails((prev) => ({
      ...prev,
      workDone: data,
    }));
    setIsAddOtherOpen(false);
    // setAddEditWorkLogDetails((prev)=>({
    //   ...prev,
    //   workDone:0
    // }))
  };

  const roleId = GetLocalStorageData("roleID");
  // const CustomOption = (props) => {
  //   const { data, innerRef, innerProps } = props;

  //   const handleDeleteClick = (e) => {
  //     e.stopPropagation(); // Prevent the select dropdown from closing
  //     props.onDeleteOption(data);
  //   };

  //   return (
  //     <div ref={innerRef} {...innerProps} className="custom-option" style={{ display: "flex", flex: 1 }}>
  //       <components.Option {...props} />
  //       {roleId === ROLE_TYPE.ADMIN  && !nonDeletableOptions.includes(data.label) && (
  //         <span
  //           className="delete-icon"
  //           onClick={handleDeleteClick}
  //           style={{ alignContent: "center" }}
  //         ><i
  //             className="fa fa-trash"
  //             aria-hidden="true"
  //             style={{ color: "#e6072c", fontSize: "20px", marginRight: "15px", marginLeft: "5px" }}
  //             title="Delete"
  //           />
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  // const handleDeleteOption = (optionToDelete) => {
  //   setMiscIdToDelete(optionToDelete.value);
  //   setShowDeleteModal(true);
  // };

  // const OnDeleteModalYesClick = async () => {
  //   try {
  //     let idToDelete = miscIdToDelete;
  //     if (idToDelete > 0) {
  //       var deleteRes = await
  //       console.log("deleteRes", deleteRes);
  //       if (deleteRes.data === "IsDeleted") {
  //         ShowToastMessage(ToastMessages.MiscOtherDelete, ToastMessageType.Success);
  //       }
  //       // const updatedMisc = misc.map((category) => ({
  //       //   ...category,
  //       //   options: category.options.filter((option) => option.value !== optionToDelete.value),
  //       // }));
  //       // setMisc(updatedMisc);
  //     }
  //     GetMiscellaneousTimeLog();
  //     setShowDeleteModal(false);
  //   }
  //   catch (error) {
  //     ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //   }
  // };

  // const OnCloseDeleteModal = () => {
  //   setShowDeleteModal(false);
  // };

  const handleDeleteOption = (optionToDelete) => {
    setOptionIdToDelete(optionToDelete.value);
    setShowDeleteModal(true);
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;

    const handleDeleteClick = (e) => {
      e.stopPropagation(); // Prevent the select dropdown from closing
      props.onDeleteOption(data);
    };

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="custom-option"
        style={{ display: "flex", flex: 1 }}
      >
        <components.Option {...props} />
        {roleId === ROLE_TYPE.ADMIN && data.isDefault === false && (
          <span
            className="delete-icon"
            onClick={handleDeleteClick}
            style={{ alignContent: "center" }}
          >
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{
                color: "#e6072c",
                fontSize: "20px",
                marginRight: "15px",
                marginLeft: "5px",
              }}
              title="Delete"
            />
          </span>
        )}
      </div>
    );
  };
  const OnCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const confirmDelete = async () => {
    try {
      if (optionIdToDelete) {
        const response = await ActionItemService.DeleteWorkItemType(optionIdToDelete);
        if (response.status === HTTPResponse.OK) {
          loadWorkDone();
          setShowDeleteModal(false);
          ShowToastMessage(ToastMessages.MiscOtherDelete)
        }
      }
    }
    catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      return;
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      SaveComment();
    }
  };

  return (
    <>
      {isOpen && (
        <section className="log-hours-modal">
          <div
            className="modal custom-depth"
            id="EditVideoModal"
            tabIndex={-1}
            style={{
              display: "block",
              paddingRight: "17px",
              backgroundColor: "#00000094",
            }}
            aria-labelledby="ModalFormLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ maxWidth: "720px" }}
            >
              <div >

                {showDeleteModal && (

                  <DeleteModal
                    showDeleteModal={showDeleteModal}
                    onNoClick={OnCloseDeleteModal}
                    textToDisplay={"Are you sure you want to delete?"}
                    isFromWorkDone={true}
                    onYesClick={confirmDelete}
                  />
                )}
              </div>
              <AddMiscellenousOther
                isOpen={isAddOtherOpen}
                // otherMisType={miscSelectedlabelToOthersModal}
                setShowModal={closeModal}
                getWorkItemValue={GetOtherWorkItemValue}
                // isTrainingOtherSelcted={isTrainingMiscOther}
                // isMiscOtherSelected={isMiscOther}
                isFromAddEditComments={workItemType}
                loadWorkDoneOptions={loadWorkDone}
              />
              <div className="modal-content">
                <div className="modal-body">
                  <div className="container">
                    <div className="row modal-headerr">
                      <div className="col-10 col-md-10 col-lg-10">
                        <label className="log-work-label">Log Work</label>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2">
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            return setShowModal("close");
                          }}
                        />
                      </div>
                    </div>
                    <div className="log-hours-main">
                      <div className="row">
                        <div
                          className="col-12 col-md-4 col-lg-4 mt-3"
                          style={{ width: "120px" }}
                        >
                          {/* <label htmlFor="" style={{ fontWeight: "bold" }}>
                          Time Spent:{" "}
                        </label>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          *
                        </span> */}
                          <label style={{ fontWeight: "bold" }}>
                            Time Spent{" "}
                            <span className="mandatory-field">*</span>
                          </label>
                        </div>
                        <div className="col-12 col-md-8 col-lg-8 mt-3">
                          {/* <input
                          type="text"
                          maxLength={MaxCharacterCount.ReportTimeSpent}
                          name="timeSpent"
                          className="form-control"
                          value={addEditWorkLogDetails.timeSpent}
                          onChange={(e) => {
                            HandleFieldChange(e);
                          }}
                        /> */}
                          {/* <TimePicker
            onChange={handleTimeChange}
            value={addEditWorkLogDetails.timeSpent}
            disableClock={true}
            format="HH:mm"
            maxDetail="minute"
            clearIcon={null}
            
          /> */}
                          {/* <div className="hrsMin">
                          <input style={{ width: '25px' }} type="number" id="hrs" min={0} max={99} onChange={handleTimeChange} value={addEditWorkLogDetails.timeSpent.hours} />
                          <span style={{ marginRight: '8px' }} >:</span>
                          <input style={{ width: '25px' }} type="number" id="min" min={0} max={59} step="15" onChange={handleTimeChange} value={addEditWorkLogDetails.timeSpent.minutes} />
                        </div> */}

                          <div className="time-details-fbox">
                            <div className="hrsMin">
                              <input
                                style={{ width: "25px" }}
                                type="number"
                                id="hrs"
                                min={0}
                                max={99}
                                //disabled={workLogDetail != undefined}
                                onChange={handleTimeChange}
                                onFocus={handleFocus}
                                value={addEditWorkLogDetails.timeSpent.hours}
                              />
                              <span style={{ marginRight: "8px" }}>:</span>
                              <input
                                style={{ width: "25px" }}
                                type="number"
                                id="min"
                                min={0}
                                max={59}
                                step="15"
                                //disabled={workLogDetail != undefined}
                                onChange={handleTimeChange}
                                onFocus={handleFocus}
                                value={addEditWorkLogDetails.timeSpent.minutes}
                              />
                            </div>{" "}
                            <label
                              style={{ marginBottom: "0px", color: "#727272" }}
                            >
                              Enter exact time spent in Hours:Mins.
                            </label>{" "}
                          </div>
                          <ErrorField
                            show={errors.timeSpent}
                            message={CommonErrorMessages.validTime}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-12 col-md-6 col-lg-4 mt-3"
                          style={{ width: "120px" }}
                        >
                          {/* <label htmlFor="" style={{ fontWeight: "bold" }}>
                          Date:{" "}
                        </label>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          *
                        </span> */}
                          <label style={{ fontWeight: "bold" }}>
                            Date <span className="mandatory-field">*</span>
                          </label>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            <DatePicker
                              className=" form-control "
                              dateFormat="dd-MM-yyyy"
                              selected={addEditWorkLogDetails.startDate}
                              onChange={(e) => {
                                HandleDateChange(e);
                              }}
                              maxDate={new Date()}
                              showIcon={true}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              popperClassName="ag-custom-component-popup"
                              scrollableYearDropdown={true}
                              showYearDropdown
                              showMonthDropdown
                              todayButton="Today"
                            />
                          </label>
                          <ErrorField
                            show={errors.startDate}
                            message={CommonErrorMessages.Required}
                          />
                        </div>
                      </div>

                      {/* {workItemType != 1 && ( */}
                      <div className="row">
                        <div
                          className="col-12 col-md-6 col-lg-4 mt-3"
                          style={{ width: "120px" }}
                        >
                          {/* <label htmlFor="" style={{ fontWeight: "bold" }}>
                          Work Done:{" "}
                        </label>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          *
                        </span> */}
                          <label style={{ fontWeight: "bold" }}>
                            Work Done <span className="mandatory-field">*</span>
                          </label>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            <Select
                              inputId="workDone"
                              placeholder="Please Select"
                              styles={styles}
                              options={
                                options.length > 0
                                  ? options[0]
                                    .filter(
                                      (option) =>
                                        roleId === 1 || option.value !== 999
                                    )
                                    .sort((a, b) => {
                                      if (a.value === 99) return -1; // 99 value option remains at the start
                                      if (b.value === 99) return 1;
                                      if (a.value === 999) return 1; // 999 value option remains at the end
                                      if (b.value === 999) return -1;
                                      return a.label.localeCompare(b.label);
                                    })
                                  : []
                              }
                              value={options
                                .flat()
                                .find(
                                  (a) =>
                                    a.value === addEditWorkLogDetails?.workDone
                                )}
                              onChange={(e) => HandleDropdownChange(e)}
                              isClearable={
                                addEditWorkLogDetails?.workDone !== undefined ||
                                (!addEditWorkLogDetails?.workDone === null &&
                                  addEditWorkLogDetails?.value > 0)
                              }
                              //components={{ Option: (props) => <CustomOption {...props} onDeleteOption={handleDeleteOption} /> }} //Mustakim - Custome Options to add Delete Functionality
                              components={{
                                Option: (props) => (
                                  <CustomOption
                                    {...props}
                                    onDeleteOption={handleDeleteOption}
                                  />
                                ),
                              }}
                            />
                          </label>
                          <ErrorField
                            show={errors.workDone}
                            message={CommonErrorMessages.WorkDone}
                          />
                        </div>
                      </div>
                      {/* )} */}

                      {addEditWorkLogDetails?.workDone === 17 && (
                        <div className="row">
                          <div
                            className="col-12 col-md-5 col-lg-7 mt-3"
                            style={{ width: "215px" }}
                          >
                            {/* <label htmlFor="" style={{ fontWeight: "bold" }}>
                          Number of Issues fixed today:{" "}
                        </label>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          *
                        </span> */}
                            <label style={{ fontWeight: "bold" }}>
                              Number of Issues fixed today{" "}
                              <span className="mandatory-field">*</span>
                            </label>
                          </div>
                          <div className="col-12 col-md-2 col-lg-1 mt-3">
                            {/* <label>
                          <Select
                            inputId="workDone"
                            placeholder="Please Select"
                            styles={styles}
                            isDisabled={workLogDetail != undefined}
                            options={options[0]}
                            value={options.flat().find((a) => a.value === addEditWorkLogDetails?.workDone)}
                            onChange={(e) => HandleDropdownChange(e)}
                          />
                        </label> */}
                            <input
                              type="text"
                              //maxLength={MaxCharacterCount.ReportTimeSpent}
                              name="issuesFixed"
                              className="form-control"
                              value={addEditWorkLogDetails.issuesFixed}
                              disabled={workLogDetail != undefined}
                              onChange={(e) => {
                                HandleFieldChange(e);
                              }}
                              style={{ textAlign: "center", width: "75px" }}
                            />
                            <ErrorField
                              show={errors.issuesFixed}
                              message={CommonErrorMessages.IssuesFixed}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div
                          className="col-12 col-md-6 col-lg-4 mt-3"
                          style={{ width: "119px" }}
                          
                        >
                          {/* <label htmlFor="" style={{ fontWeight: "bold" }}>
                          Comment:{" "}
                        </label> */}
                          <label style={{ fontWeight: "bold" }}>
                            Comments <span className="mandatory-field">*</span>
                          </label>
                        </div>
                        <div
                          className="col-12 col-md-9 col-lg-8 mt-3"
                          style={{ width: "530px" }}
                        >
                          <div
                            className="col-md-12 mt-2 no-cursor"
                            style={{ width: "", padding: "0px" }}
                          >
                            <div
                              onDrop={(e) => {
                                e.preventDefault();
                                const files = e.dataTransfer.files;
                                handleDroppedFilesLocal(files);
                              }}
                              onDragOver={(e) => {
                                e.preventDefault();
                              }}
                              //onKeyDown={handleKeyDown}
                            >
                              <Editor
                              spellCheck={true}
                                editorState={addEditWorkLogDetails.description}
                                editorStyle={{
                                  border: "1px solid #ced4da",
                                  lineHeight: "1",
                                }}
                                wrapperClassName="demo-wrapper "
                                editorClassName="editer-content editor-height"
                                toolbarClassName="toolbar-class candrag"
                                onEditorStateChange={(e) => {
                                  HandleEditorChange(e);
                                }}
                                handleBeforeInput={(val) => {
                                  const textLength =
                                    addEditWorkLogDetails.description
                                      .getCurrentContent()
                                      .getPlainText().length;
                                  if (
                                    val &&
                                    textLength >=
                                    MaxCharacterCount.EditCommentsEditor
                                  ) {
                                    return "handled";
                                  }
                                  return "not-handled";
                                }}
                                handlePastedText={(val) => {
                                  const textLength =
                                    addEditWorkLogDetails.description
                                      .getCurrentContent()
                                      .getPlainText().length;
                                  return (
                                    val.length + textLength >=
                                    MaxCharacterCount.EditCommentsEditor
                                  );
                                }}
                                handlePastedFiles={(files) => {
                                  handleDroppedFilesLocal(files);
                                }}
                                toolbar={toolbarOptions}
                                style={{ height: "600px" }} // Set the desired height here
                              />

                              <ErrorField
                                show={errors.description}
                                message={CommonErrorMessages.CommentsEmpty}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-12 col-md-6 col-lg-4 mt-3"
                          style={{ width: "119px" }}
                        >
                         
                        </div>
                 {actionItemId !=0 && previousCommentData !=undefined  && !isCommentEdit && (       <div
                          className="col-12 col-md-9 col-lg-8 mt-3"
                          style={{ width: "530px" }}
                        >
                          <div className="edit-previous"  onClick={EditPreviousComment}>Edit previous comment</div>
                        </div>)}
                      </div>
                      
                      <br />

                      <div
                        className="log-cancel-div"
                        style={{ padding: "0px !important" }}
                      >
                        <button
                          type="button"
                          onClick={SaveComment}
                          className="log-btn"
                          style={{ marginRight: "12px", padding: "3px 10px" }}
                        >
                          Save
                        </button>

                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            return setShowModal("close");
                          }}
                          className="log-btn"
                          style={{
                            backgroundColor: "white",
                            border: "1px solid #483567 ",
                            color: " #483567",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

      )}
    </>
  );
};

export default AddEditComments;

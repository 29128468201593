import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isStringEmpty } from "../Helpers/Utilities";
import ErrorField from "../Components/ErrorField";
import {
  ToastMessages,
  ResetPasswordErrorMessage,
} from "../Helpers/HelperText";
import { LoginService } from "../WebApiServices/Login.service";
import { HTTPResponse, MinPasswordLength, ToastMessageType } from "../Helpers/Enums";
import { AppContext } from "../Contexts/AppContextProvider";
import { useNavigate } from "react-router-dom";
const ResetPassword = () => {

  /* <summary>
  date: 20-12-2024
  Name: Mustakim Shaikh
  description: reset password 
  <summary>*/
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [searchParams] = useSearchParams();
  const gUID = searchParams.get("GUID");
  const gUIDToString = gUID.toString();
  const [active, setActive] = useState(true)

  const INITIAL_FIELDERROR = {
    passwordEmpty: false,
    confirmPasswordEmpty: false,
    passwordMismatch: false,
    passwordInvalid: false,
    passwordLength: false,

  };
  const [fieldError, setFieldError] = useState(INITIAL_FIELDERROR);
  const [linkInactive, setLinkInactive] = useState(false);

  //SYJ - Modified On change for the Input
  const HandleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password": {
        setFields((prev) => ({
          ...prev,
          [name]: value,
        }));
        Validate("password", value);
        break;
      }
      case "confirmPassword": {
        setFields((prev) => ({
          ...prev,
          [name]: value,
        }));
        Validate("confirmPassword", value);
        break;
      }
      default:
        break;
    }
  };

  //SYJ - Validation Message Show/Hide Update.
  //SYJ - Removed not required code & Fixed Whatever Scenario's issues Found. 
  const Validate = (field, value) => {
    let isValid = true;
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  
    if (isStringEmpty(value)) {
      setFieldError((prev) => ({
        ...prev,
        [field + "Empty"]: true,
      }));
      isValid = false;
    } else {
      setFieldError((prev) => ({
        ...prev,
        [field + "Empty"]: false,
      }));
  
      // Check password validity
      if (field === "password") {
        if (value.length < MinPasswordLength.resetPasswordLength || !/[A-Z]/.test(value) || !/[a-z]/.test(value) || !/\d/.test(value) || !/[!@#$%^&*(),.?":{}|<>]/.test(value) || /\s/.test(value)) {
          setFieldError((prev) => ({
            ...prev,
            passwordLength: true,
          }));
          isValid = false;
        } else {
          setFieldError((prev) => ({
            ...prev,
            passwordLength: false,
          }));
        }

        if (!isStringEmpty(fields.password) &&  value !== fields.confirmPassword) {
          setFieldError((prev) => ({
            ...prev,
            passwordMismatch: true,
          }));
          isValid = false;
        } else {
          setFieldError((prev) => ({
            ...prev,
            passwordMismatch: false,
          }));
        }
      } else if (field === "confirmPassword") {
        if (!isStringEmpty(fields.password) && value !== fields.password) {
          setFieldError((prev) => ({
            ...prev,
            passwordMismatch: true,
          }));
          isValid = false;
        } else {
          setFieldError((prev) => ({
            ...prev,
            passwordMismatch: false,
          }));
        }
      }
    }
  
    return isValid;
  };
  
  const OnResetButtonClicked = (e) => {
    e.preventDefault();
    if (Validate("password", fields.password) || Validate("confirmPassword", fields.confirmPassword)) {
      HandleReset();
    }
  };
  useEffect(() => {
    GetEmployeeInfo();
  }, []);
  const GetEmployeeInfo = async () => {
    ToggleLoader(true);

    const resp = await LoginService.GetEmployeeInfo(gUIDToString);  

    if (resp.data.fullName == undefined || resp.data.fullName==="") {
      setLinkInactive(true);
      ToggleLoader(false);

    } else {
      setName(`Welcome ${resp.data.fullName}`);
      ToggleLoader(false);

    }
  };
  const HandleReset = async () => {
    try {
      ToggleLoader(true);
      const sendNewPassword = {
        gUID: gUIDToString,
        updatePassword: fields.password,
      };

      const response = await LoginService.ResetPassword(sendNewPassword);
     
      if (response.status === HTTPResponse.OK) {
        setFields({ password: "", confirmPassword: "" })
        setActive(false)
        ToggleLoader(false);
        navigate("/")
        ShowToastMessage(
          ToastMessages.PasswordConfirmation,
          ToastMessageType.Success
        );
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.ResetPasswordError, ToastMessageType.Fail);
    }
  };

  return (
    <>
     {linkInactive ? (
      <>
                     <title>Link Expired</title>
                     <section className="link-expired">
                       <div className="container-fluid">
                         <div className="row custom-height">
                           <div className="col-12 col-md-5 col-lg-5 link-expired-info-sec">
                             <div className="link-expired-header">
                               <h3>Link Expired!</h3>
                             </div>
                             <div className="link-expired-text">
                               <p>
                                 It looks like it's too late to reset the password, or you have
                                 already set your password. Don't worry, if you have not reset your
                                 password, you can go to the login page and click the Forgot
                                 Password link again.{" "}
                               </p>
                             </div>
                             <div>
                               <button className="back-to-login-btn" onClick={()=>navigate("/")}>Take me to Login page</button>
                             </div>
                           </div>
                           <div className="col-12 col-md-7 col-lg-7 link-expired-img-sec">
                             <img src="../Assets/images/link-expired-img.png" alt="" />
                             

                           </div>
                         </div>
                       </div>
                     </section></>
                      ) : (
      <div className="mainn" style={{ minHeight: '93vh' }}>
        <div
          className="container py-5 custom-container"
          style={{ height: "100%" }}
        >
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card bg-white text-black"
                style={{ borderRadius: "1rem", minHeight: 100 }}
              >
                <div className="card-body p-4">
                  <div className="mb-md-2 mt-md-2 pb-2">
                   
                      <>
                        <h6 className="fw-bold mb-4 login-heading">
                          <label>{name}</label>
                        </h6>
                        <div className="wrap-input1000 validate-input">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="input1000"
                            name="password"
                            required=""
                            id="password-id"
                            placeholder="Enter New Password"
                            value={fields.password}
                            onChange={HandleFieldChange}
                            style={{ padding: "0 7px 0 0px" }}
                          />

                          <span onClick={() => setShowPassword(!showPassword)}>
                            {" "}
                            <i
                              className={
                                showPassword
                                  ? "fa fa-eye eye-icon"
                                  : "fa eye-icon fa-eye-slash"
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "15px",
                              }}
                              id="togglePassword"
                            ></i>{" "}
                          </span>
                        </div>
                        <div>
                          <ErrorField style={{}}
                            show={
                              fieldError.passwordEmpty ||
                              fieldError.passwordInvalid ||
                              fieldError.passwordLength
                            }
                            message={
                             
                              fieldError.passwordEmpty
                                ? ResetPasswordErrorMessage.Password
                                : fieldError.passwordLength
                                  ? ResetPasswordErrorMessage.PasswordMinLength
                                  : ""

                            }
                          />
                        </div>

                        <div className="wrap-input1000 validate-input">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="input1000"
                            name="confirmPassword"
                            required
                            id="confirm-password-id"
                            placeholder="Re-Enter New Password"
                            value={fields.confirmPassword}
                            onChange={HandleFieldChange}
                            style={{ padding: "0 7px 0 0px" }}
                          />

                          <span
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {" "}
                            <i
                              className={
                                showConfirmPassword
                                  ? "fa fa-eye eye-icon"
                                  : "fa eye-icon fa-eye-slash"
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "15px",
                              }}
                              id="togglePassword1"
                            ></i>{" "}
                          </span>
                        </div>
                        <div>
                          <ErrorField
                            show={
                              fieldError.confirmPasswordEmpty ||
                              fieldError.passwordMismatch
                            }
                            message={
                              fieldError.passwordMismatch
                                ? ResetPasswordErrorMessage.NotMatched
                                : fieldError.confirmPasswordEmpty
                                  ? ResetPasswordErrorMessage.ConfirmPassword
                                  : ""
                            }
                          />
                        </div>
                        <div className="text-center mt-3">
                          <center>
                            <div className="save-cancel-div">
                              <button
                                className="save-project-btn"
                                type="submit"
                                onClick={OnResetButtonClicked}
                              >
                                Reset Password
                              </button>
                            </div>
                          </center>
                        </div>
                        <br />
                      </>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   )}
    </>
  );
};

export default ResetPassword;

import React, { useState, useEffect, useContext } from "react";
import { ProjectService } from "../WebApiServices/Project.service";
import { HTTPResponse, ToastMessageType } from "../Helpers/Enums";
import CheckboxDropdown from "./CheckBoxDropdown";
import { ProjectErrorMessage, ToastMessages } from "../Helpers/HelperText";
import ErrorField from "./ErrorField";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  AddLocalStorageData,
  RemoveLocalStorageData,
} from "../Helpers/Utilities";
import Select from 'react-select';

const AssignEmployeeModal = ({
  closeModal,
  projectIds,
  allEmployees,
  onEmployeeUpdate,
  editMode,
  IsStyle,
  //selectedEmployees,

  //handleEmployeeSelectionChange,

}) => {
  
  const { ShowToastMessage } = useContext(AppContext);
  const { LogOut } = useUIContext;
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [noEmployeeSelected, setNoEmployeeSelected] = useState(false);

  // const options = allEmployees.map((employee) => ({
  //   value: employee.id,
  //   label: employee.name,
  //   isChecked: employee.isChecked,
  // }));

  // const selectedOptions = selectedEmployees.map((selected) => ({
  //   value: selected.employeeId,
  //   label: selected.fullName,
  //   isChecked: selected.isChecked,
  // }));

  // const customStyles = IsStyle ? {
  //   // Define your custom styles here if needed
  // } : {};

  const getProjectAssignedEmployee = async () => {
    try {
      const resp = await ProjectService.GetAssignedEmployees(projectIds, true);
      if (resp.status === HTTPResponse.OK) {
        const employeeIdAndName = resp.data
        //.filter((e)=>e.roleId !=1)
         .map((employee) => ({
          employeeId: employee.employeeId,
          fullName: employee.fullName,
          projectEmployeeBridgeId: employee.projectEmployeeBridgeId,
        }));

        let filter = allEmployees.find(dd => dd.isChecked);

        const updatedEmployeeIdAndName = employeeIdAndName.map((employee) => ({
          ...employee,
          isChecked: filter && filter.id === employee.employeeId
        }));

        setSelectedEmployees(updatedEmployeeIdAndName);
      }
      if (resp.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  useEffect(() => {
    getProjectAssignedEmployee();
  }, [projectIds]);
  /* <summary>
date: 05-02-2024
Name: MS
description: To retain employee list after selecting and closing modal without saving
<summary>*/
  const closeAndSave = () => {
    if (!editMode) {
      AddLocalStorageData("newlySelectedEmployee", newlySelectedEmployees);
    }
  };
  const [newlySelectedEmployees, setNewlySelectedEmployees] = useState([]);

  // const getLocalSelectedEmployees = () => {
  //   const localSelectedEmployees = GetLocalStorageData("newlySelectedEmployee");

  //   if (localSelectedEmployees) {
  //     setNewlySelectedEmployees(localSelectedEmployees);
  //     setSelectedEmployees(localSelectedEmployees);
  //   }
  // };
  // useEffect(() => {
  //   getLocalSelectedEmployees();
  // }, []);
  const handleEmployeeSelectionChange = (selectedOptions) => {
    const renamedItems = selectedOptions
    .filter(option => option !== undefined) 
    .map((option) => ({
      employeeId: option.releaseId,
      fullName: option.name,
      isChecked: option.isChecked,
    }));

    setSelectedEmployees(renamedItems);
    setNewlySelectedEmployees(renamedItems);
    setNoEmployeeSelected(false);
  };

  const validate = () => {
    if (selectedEmployees.length === 0) {
      setNoEmployeeSelected(true);
      return false;
    } else {
      setNoEmployeeSelected(false);
      return true;
    }
  };

  const handleUpdateEmployee = async (selectedEmployees) => {
    try {
      if (validate()) {
        const projectId = projectIds;
        const employeesWithProjectId = selectedEmployees.map((employee) => ({
          ...employee,
          projectId: projectId,
        }));

        onEmployeeUpdate(employeesWithProjectId);
        const resp = await ProjectService.AssignEmployeeToTheProject(
          employeesWithProjectId
        );
        if (resp.status === HTTPResponse.Unauthorized) {
          LogOut();
        }

        RemoveLocalStorageData(newlySelectedEmployees);

        closeModal();
      }
    } catch {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const handleButtonClick = () => {
    closeModal();
    closeAndSave();
  };
  return (
    <div className="user-management-modal">
      <div
        className="modal fade show"
        id="userManagementModal"
        tabIndex={-1}
        aria-labelledby="ModalFormLabel"
        aria-hidden="true"
        style={{
          display: "block",
          backgroundColor: "#00000094",
          zIndex: "999!important",
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-custom">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container">
                <div className="row modal-headerr">
                  <div
                    className="col-10 col-md-10 col-lg-10"
                  >
                    <label className="modal-head-label">Assign Employee</label>
                  </div>
                  <div className="col-2 col-md-2 col-lg-2">
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleButtonClick}
                    />
                  </div>
                </div>
                <div className="user-management-main">
                  <div className="row">
                    <div className="col-1 col-md-1 col-lg-1"></div>
                  </div>

                  <div style={{ margin: "10px" }}>
                    <label>
                      Assign To <span className="isRequiredField"></span>
                    </label>
                    <CheckboxDropdown
                      optionsFilter={() => null}
                      onChanges={() => null}
                      loading={() => null}
                      AddStyle={IsStyle}
                      isFromEmployee={false}
                      releasesSelected={
                        // newlySelectedEmployees.length > 0
                        //   ? newlySelectedEmployees.map((employee) => ({
                        //       releaseId: employee.employeeId,
                        //       name: employee.fullName,
                        //     }))
                        //   : 
                        selectedEmployees.map((selected) => ({
                          releaseId: selected.employeeId,
                          name: selected.fullName,
                          isChecked: selected.isChecked,
                        }))
                      }
                      options={allEmployees.map((employee) => ({
                        releaseId: employee.id,
                        name: employee.name,
                        isChecked: employee.isChecked,
                      }))}
                      selectEmployee={true}
                      onChange={(dd) => {
                        handleEmployeeSelectionChange(dd);
                      }}
                    />
                    
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <ErrorField
                      show={noEmployeeSelected}
                      message={ProjectErrorMessage.EmployeeNotSelected}
                    />
                  </div>
                </div>
                <div className="save-cancel-div">
                  <button
                    className="save-project-btn"
                    style={{ marginRight: "4px" }}
                    onClick={() => {
                      handleUpdateEmployee(selectedEmployees);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignEmployeeModal;

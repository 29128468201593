import React, { useState,useContext } from "react";
import ErrorField from "./ErrorField";
import {  ForgotPasswordMsg, ResetPasswordErrorMessage, ToastMessages } from "../Helpers/HelperText";
import { LoginService } from "../WebApiServices/Login.service";
import { HTTPResponse, MaxCharacterCount, ToastMessageType } from "../Helpers/Enums";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";

const ForgotPassword = ({ onClose }) => {
  const { ToggleLoader } = useContext(AppContext);
  const { LogOut } = useUIContext();
  const { ShowToastMessage } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    email: false,
    emailNotpresent: false,
  });
  const validate = () => {
    let result = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim() || !emailRegex.test(email)) {
      setError((prev) => ({
        ...prev,
        email: true,
      }));
      result = true;
    } else {
      setError((prev) => ({
        ...prev,
        email: false,
      }));
    }

    return result;
  };

  const ResetPassword = async () => {
    try {
      if (!validate()) {
        ToggleLoader(true);

        let response = await LoginService.SendEmailForPasswordRecovery(email);

        if(response.data===ResetPasswordErrorMessage.UserInactive){
          ShowToastMessage(ToastMessages.InactiveResetPassword, ToastMessageType.Fail);
          onClose();

        }
        else if (response.data===ResetPasswordErrorMessage.Sucess) {
          ShowToastMessage(ToastMessages.ResetEmail, ToastMessageType.Success);
          onClose();
        }
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        } else {
          setError((prev) => ({
            ...prev,
            emailNotpresent: true,
          }));
        }

        ToggleLoader(false);
      }
    } catch {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const handleCloseForgotPasswordModal = () => {
    onClose();
  };

  const handleEmailInputChange = (e) => {
    setEmail(e.target.value);
    setError((prev) => ({
      ...prev,
      email: false,
    }));
  };
  return (
    <div className="user-management-modal">
      <div
        className="modal fade show"
        id="userManagementModal"
        tabIndex={-1}
        aria-labelledby="ModalFormLabel"
        aria-hidden="true"
        style={{
          display: "block",
          backgroundColor: "#00000094",
          zIndex: "999!important",
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{minHeight:'200px'}}>
            <div className="modal-body">
              <div className="container">
                <div className="row modal-headerr">
                  <div
                    className="col-10 col-md-10 col-lg-10"
                   // style={{ marginBottom: "-7px" }}
                  >
                    <label className="modal-head-label">Forgot Password</label>
                  </div>
                  <div className="col-2 col-md-2 col-lg-2">
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseForgotPasswordModal}
                    />
                  </div>
                </div>
                <div className="user-management-main"style={{height:'107px'}}>
                  <div className="row">
                    <div className="col-1 col-md-1 col-lg-1"></div>
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-23"
                    style={{ margin: "0px" }}
                    data-validate="Email is required"
                  >
                    <label  style={{fontSize:'15px '}}>Email</label>
                    <input
                      className="input100"
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      maxLength={MaxCharacterCount.UserEMail}
                      value={email}
                      onChange={handleEmailInputChange}
                    />
                   <i
                        className="fa fa-envelope"
                        style={{
                          position: "absolute",
                          top: "40px",
                          left:"10px",
                          fontSize: "16pt",
                          color: "gray"
                        }}
                      ></i>
                  </div>
                  <span>
                    <ErrorField
                      show={error.email}
                      message={ForgotPasswordMsg.Email}
                    ></ErrorField>
                    <ErrorField
                      show={!error.email && error.emailNotpresent}
                      message={ForgotPasswordMsg.FalsyEMail}
                    ></ErrorField>
                  </span>
                </div>
                <div className="save-cancel-div">
                  <button
                    className="save-project-btn"
                    style={{ marginRight: "4px" }}
                    onClick={ResetPassword}
                  >
                    Reset
                  </button>
                  <button
                    className="cancel-project-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseForgotPasswordModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import { useLocation, useNavigate } from "react-router-dom";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { HTTPResponse, ROLE_TYPE, ToastMessageType, DefaultAGGridProps, MonthNames, ACTION_ITEM_TYPE, ACTION_ITEM_TYPE_TITLE, TASK_PRIO, PriorityListTitle, StatusList } from "../Helpers/Enums";
import { ReportService } from "../WebApiServices/Report.service";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "react-datepicker/dist/react-datepicker.css"
import {
    AddLocalStorageData,
    GetLocalStorageData,
    GetLoggedInUserID,
    RemoveLocalStorageData,
    changePageTitle,
    formatCorrectHours,
    formatLoggedHours,
    stripHTMLTags,
} from "../Helpers/Utilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastMessages } from "../Helpers/HelperText";
import Select from "react-select";
import AGGrid from "../Components/AGGrid";
import DeleteModal from "../Components/DeleteModal";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { ManageUsersService } from "../WebApiServices/ManageUsers.Service";
import { UncontrolledTooltip } from "reactstrap";
import { UserRolesPermission } from "../WebApiServices/RolesPermission.service";
import LetteredAvatar from "./Overview/LetteredAvatar";

// Function to strip HTML tags using regular expressions
// const stripHTMLTags = (html) => {
//     // return html.replace(/<[^>]*>?/gm, '');
//     return html.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ');

// };


const TimeLog = () => {
    useEffect(() => {
        changePageTitle("Daily Time Logs");
        RemoveLocalStorageData("key");
        RemoveLocalStorageData("newlySelectedProjects");
    });
    const name = GetLocalStorageData("userName");
    const { LogOut } = useUIContext();
    const roleId = Number(GetLocalStorageData("roleID"));
    const userId = Number(GetLocalStorageData("userId"));
    const [employeeId, setEmployeeId] = useState(GetLoggedInUserID());
    const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
    const [filteredDate, setFilteredDate] = useState(new Date());
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const location = useLocation();


    const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
    const [hideTaskNameFeild, setHideTaskNameFeild] = useState(false);

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedMisc, setSelectedMisc] = useState(null)

    const [totalWorkLogData, setTotalWorkLogData] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [worklogDetails, setWorklogDetails] = useState([]);
    const [filteredWorklogDetails, setFilteredWorklogDetails] = useState([]);
    const [workLogIdToDelete, setWorkLogIdToDelete] = useState([]);
    const [gridUpdateColumns, setGridUpdatedColumns] = useState([]);

    const [employees, setEmployees] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const navigate = useNavigate();

    //SYJ - User Roles Permission
    const [adminPermission, setAdminPermission] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [projectLeadPermission, setProjectLeadPermission] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [employeePermission, setEmployeePermission] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,
            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });



    const TaskIdLinkRenderer = ({ value, taskTitle, projectID, isFromDeletedProject }) => {

        return value ? (
            <a className={isFromDeletedProject == true ? "disabled" : ""}
                target="_blank"
                rel="noreferrer"
                href={
                    "/EditActionItem/" +
                    taskTitle

                }
            >
                {value}
            </a>
        ) : null;
    };
   
    const gridColumns = (adminPermission, employeePermission, projectLeadPermission) => {
        return [
            {
                headerName: 'Employee Name', field: "employeeName", headerClass: "header", filter: false,
                sortable: false, tooltipField: "employeeName",
                hide: (roleId === ROLE_TYPE.ADMIN ||roleId === ROLE_TYPE.LEAD) ? false : true,
                 flex:roleId===(ROLE_TYPE.ADMIN ||ROLE_TYPE.LEAD)? 1:"",
                cellRendererFramework: EllipsisCellRendererEmpName,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
            },
            {
                headerName: 'Date', field: "date", headerClass: "header", filter: false,
                sortable: false, tooltipField: "date",
                width: 130,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
            },
            {
                headerName: 'Project', field: "projectKey", headerClass: "header", filter: false,
                sortable: false, tooltipField: "projectKey",
                width: 120,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
            },

            // {
            //     headerName: 'Work Item Type', field: "miscName", headerClass: "header", filter: false,
            //     sortable: false, tooltipField: "miscName",
            //     flex: 1,
            //     cellStyle: {
            //         textOverflow: "ellipsis",
            //         whiteSpace: "pre",
            //         overflow: "hidden",
            //     },
            // },
            {
                headerName: 'Work Done', field: "workDone", headerClass: "header", filter: false,
                sortable: false, tooltipField: "workDone",
                flex:roleId===ROLE_TYPE.ADMIN? 1:"",
                width:roleId===ROLE_TYPE.ADMIN?"":roleId===ROLE_TYPE.LEAD?230: 250,
                cellRendererFramework: EllipsisCellRendererWorkdone,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    paddingTop: "5px",
                    wordBreak: "break-word",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
            },
            {
                headerName: 'Task ID', field: "taskId", headerClass: "header", filter: false,
                sortable: false, tooltipField: "taskId",
                width:roleId===ROLE_TYPE.EMPLOYEE?190: 130,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
                cellRenderer: (params) => (

                    <div>
                        <TaskIdLinkRenderer value={params.data.taskId} projectID={params.data.projectID} taskTitle={params.data.taskId} isFromDeletedProject={params.data.isFromDeletedProject} />
                    </div>

                )
            },
            {
                headerName: 'Task Name', headerClass: "header", filter: false,
                sortable: false,
              width:roleId===ROLE_TYPE.EMPLOYEE?385:300,
                cellRendererFramework: EllipsisCellRenderers,
                tooltipField: "taskName",
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
                field: "taskName",
            },
            {
                headerName: 'Comment',
                field: "comment",
                headerClass: "header",
                filter: false,
                sortable: false,
                width:roleId===ROLE_TYPE.ADMIN?285:ROLE_TYPE.LEAD?310: 530,
                tooltipField: "comment",
                cellRendererFramework: EllipsisCellRenderer,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
            },

            {
                headerName: 'Hrs:Mins', field: "hours", headerClass: "header", filter: false,
                sortable: false, tooltipField: "hours",
                width:roleId===ROLE_TYPE.ADMIN?114:roleId===ROLE_TYPE.LEAD? 140:190,
                cellStyle: {
                    textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    wordBreak: "break-word",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.45,
                },
                valueFormatter: (params) => formatCorrectHours(params.value)
            },
            {
                headerName: "",
                width: 25,
                filter: false,
                sortable: false,
                pinned: "right",
                unSortIcon: false,
                hide:  roleId === ROLE_TYPE.LEAD && projectLeadPermission.TimeLog.edit === 0
                    || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.TimeLog.edit === 0,
                cellRenderer: (params) => {
                    const shouldRender = params.data.miscellaneousTimeLogId !== null && (roleId === ROLE_TYPE.ADMIN ||
                        params.data.loggedBy === GetLocalStorageData("userId"));

                    if (shouldRender) {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    verticalAlign: "center",
                                    height: "88%",
                                }}
                                onClick={(e) => handleEditTimLog(e, params)}
                            >
                                <button
                                    className="edit-button"
                                    onClick={(e) => handleEditTimLog(e, params)}
                                >
                                    <i
                                        className="fa fa-2x fa-pencil-square-o"
                                        aria-hidden="true"
                                        style={{ color: "#380657", marginLeft: "5px",marginTop:"2px",fontSize:'22px    ' }}
                                        title="Edit"
                                    />
                                </button>
                            </div>
                        );
                    }

                    return null;
                },
                headerClass: "header",
            },
            {
                headerName: "",
                width: 25,
                filter: false,
                sortable: false,
                pinned: "right",
                unSortIcon: false,
                cellRenderer: (params) => {
                    const shouldRender = params.data.miscellaneousTimeLogId !== null && (roleId === ROLE_TYPE.ADMIN ||
                        params.data.loggedBy === GetLocalStorageData("userId"));

                    if (shouldRender) {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    verticalAlign: "center",
                                    height: "75%",cursor:'pointer',
                                }}
                                onClick={(e) => handleDelete(e, params)}
                            >
                                     <img
                                     title="Delete"
                          src="Assets/images/delete-icon.png"
                          style={{  height: "18px" }}
                        />
                            </div>
                        );
                    }

                    return null;
                },
                headerClass: "header",
            },
            
            {
                headerName: "",
                width: 25,
                filter: false,
                sortable: false,
                pinned: "right",
                unSortIcon: false,
                cellRenderer: (params) => (
                    <div
                        style={{
                            display: "flex",
                            marginTop: "5px",
                            verticalAlign: "center",
                        }}
                    >
                        <img
                            src={
                                ACTION_ITEM_TYPE[
                                params.data.workItemType
                                ]
                            }
                            alt={
                                ACTION_ITEM_TYPE_TITLE[
                                params.data.workItemType
                                ]
                            }
                            title={
                                ACTION_ITEM_TYPE_TITLE[
                                params.data.workItemType
                                ]
                            }
                            style={{
                                width: "19px", marginTop: 2,
                                verticalAlign: "center", display: "flex",
                            }}
                        />
                    </div>
                ),
                headerClass: "header",
            },
            {
                headerName: "",
                width: 25,
                filter: false,
                sortable: false,
                pinned: "right",
                unSortIcon: false,
                cellRenderer: (params) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            verticalAlign: "center",
                        }}
                    >
                        <img
                            src={TASK_PRIO[params.data.priority]}
                            alt={
                                PriorityListTitle[params.data.priority]
                            }
                            title={
                                PriorityListTitle[params.data.priority]
                            }
                            style={{
                                width: "19px", marginTop: 7,
                                verticalAlign: "center", display: "flex",
                                marginLeft: '9px'
                            }}
                        />
                    </div>
                ),
                headerClass: "header",
            },
            {
                headerName: "",
                width: 25,
                filter: false,
                sortable: false,
                pinned: "right",
                unSortIcon: false,
                cellRenderer: (params) => {
                    const status = StatusList.find(status => status.label === params.data.status);
                    return (
                        <div
                            style={{
                                display: "flex",
                                verticalAlign: "center",
                            }}
                        >
                            {status ? (
                                <>
                                    <img
                                        src={status.imagePath}
                                        alt={params.data.status}
                                        title={params.data.status}
                                        style={{
                                            marginTop: 7, width: "19px",
                                            verticalAlign: "center", display: "flex",
                                            marginLeft: '-6px'


                                        }}
                                    />
                                </>
                            ) : (
                                <span title={params.data.status !== undefined && params.data.status}>
                                    {params.data.status !== undefined && params.data.status.length > 2 ? params.data.status.substring(0, 2) + '...' : params.data.status}
                                </span>
                            )}
                        </div>
                    );
                },
                headerClass: "header",
            },
            {
                headerName: "",
                width: 25,
                left: 117,
                filter: false,
                sortable: false,
                pinned: "right",
                unSortIcon: false,
                cellRenderer: (params) => (
                    <div
                    >
                        <LetteredAvatar
                            name={params.data.empName}
                            bgcolor={params.data.color}
                            profileImageComments={params.data.profilePic}
                            editTask={false}
                            myTasks={true}
                            isTimeLog={true}
                        />
                    </div>
                ),
                headerClass: "header",
            },
        ];
    }
    //const EllipsisCellRendererEmpName = ({ value }) => {
        const EllipsisCellRendererEmpName = ({ value }) => {
            const maxFirstNameLength = 12;
            const maxLastNameLength = 12;
          
            // Split the employeeName into first and last name
            const [firstName = '', lastName = ''] = value ? value.split(' ') : ['', ''];
          
            // Apply ellipsis if needed
            const displayFirstName =
              firstName.length > maxFirstNameLength
                ? firstName.slice(0, maxFirstNameLength) + "..."
                : firstName;
          
            const displayLastName =
              lastName.length > maxLastNameLength
                ? lastName.slice(0, maxLastNameLength) + "..."
                : lastName;
          
            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{displayFirstName}</span>
                <span>{displayLastName}</span>
              </div>
            );
          };

    const EllipsisCellRendererWorkdone = ({ value }) => {
        const maxLength = 40;
        const displayValue = value && value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

        return <span>{displayValue}</span>;
    };

    const EllipsisCellRenderer = ({ value }) => {
        const maxLength = 70;
        const displayValue = value && value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

        return <span>{displayValue}</span>;
    };

    const EllipsisCellRenderers = ({ value }) => {
        const maxLength = 70;
        const displayValue = value && value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

        return <span>{displayValue}</span>;
    };




    useEffect(() => {
        fetchData();
        fetchDropDownData();
        GetAllRolesPermission();
    }, []);

    const handleDelete = async (event, item) => {
        event.stopPropagation();
        setWorkLogIdToDelete(item.data.workLogDetailID);
        setShowDeleteModal(true);

    };

let selectedFilters=
    {
    project:selectedProject !== null && selectedProject !== undefined ? selectedProject:null,
    employee:selectedEmployee !== null && selectedEmployee !== undefined ? selectedEmployee : null,
    misc:selectedMisc !== null && selectedMisc !== undefined && selectedMisc.value > 0 ? selectedMisc : null,
    date:filteredDate?filteredDate:null

    }

    const handleEditTimLog = (event, item) => {   //for time log data
         
        AddLocalStorageData("selectedFilters",selectedFilters);
        AddLocalStorageData("pathvalueh", `/TimeLog`);
        event.stopPropagation();
        navigate("/EditTimeLogging", {
            state: {
                timeLogDetails: item.data
            },
        });
    };
    const handleDeleteTimeLog = (event, item) => {
        //AddLocalStorageData("selectedFilters",selectedFilters)
        event.stopPropagation();
        // navigate("/EditTimeLogging", {
        //     state: {
        //         timeLogDetails: item.data
        //         //,selectedFilters:selectedFilters
        //     },
        // });
    };
    
    const OnDeleteModalYesClick = async () => {
        try {
            ToggleLoader(true);
            let idToDelete = workLogIdToDelete;
            if (idToDelete > 0) {
                var deleteRes = await ReportService.DeleteWorkLogData(idToDelete);
                if (deleteRes.data) {
                    //fetchData();
                  setTotalWorkLogData( totalWorkLogData.filter((item)=>item.workLogDetailID !=idToDelete)) ;
                    setWorkLogIdToDelete(0);
                    ShowToastMessage(ToastMessages.WorkLogDelete, ToastMessageType.Success);
                }
                else{
                    ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
                }
            }
            setShowDeleteModal(false);
            ToggleLoader(false);
        }
        catch (error) {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };

    const OnCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthName = MonthNames[date.getMonth()]; // Get month name from array
        const year = date.getFullYear();
        return `${day}/${monthName}/${year}`;
    };

    /* <summary>
    date: 19-02-2024
    Name: SYJ
    description: Fetch Data from db
    <summary>*/
    const fetchData = async () => {
        try {

            const datas = await ManageUsersService.GetProfileData(userId);
            if (datas.data && datas.data.length > 0) {
                const firstData = datas.data[0];
                AddLocalStorageData("imagePath", firstData.userPic);
            }
            ToggleLoader(true);
debugger
            let response = await ReportService.GetWorklogDetail(employeeId, roleId);
            if (response.status === HTTPResponse.OK) {
                
                const data = response.data;
                if (response.status === HTTPResponse.OK) {
                    const sortedData = response.data && response.data.length > 0 && response.data.map(item => ({
                        ...item,
                        editedOn: new Date(item.editedOn)
                    })).sort((a, b) => b.editedOn - a.editedOn);


                    if (sortedData !== null && sortedData.length > 0) {
                        let filteredData = sortedData.map(item => ({
                            employeeName: item.loggedByEmployee,
                            date: item.loggedDate ? formatDate(item.loggedDate.split("T")[0]) : "",
                            loggedDate: item.loggedDate ? item.loggedDate : '',
                            editedOn: item.editedOn ? item.editedOn : new Date(),
                            hours: item.loggedHours || 0,
                            project: item.projectName || '',
                            taskId: item.key || '',
                            taskName: item.title || '',
                            loggedBy: item.loggedBy,
                            projectID: item.projectID,
                            comment: stripHTMLTags(item.comments),
                            miscellaneousTimeLogId: item.miscellaneousTimeLogId,
                            misctaskType: item.misctaskType,
                            miscCategory: item.miscCategory,
                            miscMisctaskName: item.miscMisctaskName,
                            workLogDetailID: item.workLogDetailID,
                            miscName: item.miscName,
                            workDone: item.workDone,
                            workLogTaskID: item.taskID,
                            loggedDate: item.loggedOn ? item.loggedOn : new Date(),
                            originalEstimate: item.originalEstimate,
                            actionItemId: item.taskID || 0,
                            empName: item.miscellaneousTimeLogId > 0 ? "" : item.employeeName,
                            color: item.color,
                            workItemType: item.workItemType,
                            priority: item.priority,
                            status: item.status,
                            profilePic: item.profileImagePath,
                            isFromDeletedProject: item.isDeletedProject,
                            projectKey: item.projectKey,
                        }));
debugger
                        setTotalWorkLogData(filteredData);
                        setWorklogDetails(filteredData);
                        setFilteredWorklogDetails(filteredData);

                        const filteredDataForTime = data
                        const minutesArray = filteredDataForTime.map(curr => {
                            const [hours, decimalPart] = curr.loggedHours.toString().split('.');
                            const minutes = decimalPart ? decimalPart.padEnd(2, '0') : '00';
                            const hrsInMin = parseInt(hours) * 60;
                            const value = hrsInMin + parseInt(minutes);
                            return value;
                        });
                        // Sum all the minutes
                        const totalMinutes = minutesArray.reduce((acc, curr) => acc + curr, 0);

                        // Convert total minutes back to hours and minutes
                        const totalHours = Math.floor(totalMinutes / 60);
                        const remainingMinutes = totalMinutes % 60;

                        // Combine hours and minutes as a decimal
                        const roundedTotal = parseFloat((totalHours + remainingMinutes / 60).toFixed(2)); // Round to two decimal places
                        const roundedTotalHrsMin = parseFloat(`${totalHours}.${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`);

                        setTotalHours(roundedTotalHrsMin);
                        //MS-4 Oct 24:location is used to set fields pre-selected when navigating from Edit Report page.
                        setSelectedEmployee((prev) => ({
                            ...prev,
                           // [id]: e,
                            "employeeId":selectedFiltersLocal?.employee?.employeeId?selectedFiltersLocal.employee.employeeId
                            :selectedFiltersLocal?null
                            :userId,

                            "employees":
                            {"label":selectedFiltersLocal?.employee?.employees?.label?selectedFiltersLocal.employee.employees.label
                                :selectedFiltersLocal?null
                                :name, 

                            "value":selectedFiltersLocal?.employee?.employees?.value?selectedFiltersLocal.employee.employees.value
                            :selectedFiltersLocal?null
                            :parseInt(userId)
                            },

                            "label":selectedFiltersLocal?.employee?.employees?.label?selectedFiltersLocal.employee.employees.label
                            :selectedFiltersLocal?null
                            :name,

                            "value":selectedFiltersLocal?.employee?.employees?.value?selectedFiltersLocal.employee.employees.value
                            :selectedFiltersLocal?null
                            :parseInt(userId)
                        }));
                        setSelectedMisc((prev) => ({
                            ...prev,
                            "label":selectedFiltersLocal?.misc?.label?selectedFiltersLocal?.misc?.label:"",
                            "value":selectedFiltersLocal?.misc?.value?selectedFiltersLocal?.misc?.value:null,
                            
                        }));
                        setFilteredDate(  selectedFiltersLocal?.date?new Date(selectedFiltersLocal.date): selectedFiltersLocal?null:new Date())
                    }
                }
                ToggleLoader(false);
            } else {
                if (response.status === HTTPResponse.Unauthorized) {
                    LogOut();
                }
                ToggleLoader(false);
            }
        } catch (error) {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };


    /* <summary>
    date: 19-02-2024
    Name: SYJ
    description: Fetch Data from db and biind to DropDown
    <summary>*/
    const fetchDropDownData = async () => {
        try {
            let response = await ReportService.GetWorklogDropDownData(employeeId, roleId, true, true);
            var data
            if (response.status === HTTPResponse.OK) {
                data = response.data;
            }

            if (response.status === HTTPResponse.OK) {
                setEmployees(data.employees);
                setProjects(data.projects);
            } else {
                if (response.status === HTTPResponse.Unauthorized) {
                    LogOut();
                }
            }
        } catch (error) {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterBy, setFilterBy] = useState("1"); // Default value for Day"

    

    const HandleDropdownChange = (e, id, drp) => {
        const value = e ? Number(e.value) : null;
        const label = e ? e.label : "";

        if (drp === "employeeId") {
            setSelectedEmployee((prev) => ({
                ...prev,
                [id]: e,
                [drp]: value,
            }));
        } else if (drp === "miscId") {
            setSelectedMisc((prev) => ({
                ...prev,
                [id]: e,
                [drp]: value,
            }));
        } else {
            setSelectedProject((prev) => ({
                ...prev,
                [id]: e,
                [drp]: value,
            }));
        }
    };


    useEffect(() => {
        if (selectedEmployee !== undefined || selectedProject !== undefined || selectedMisc !== undefined)
            filterGridView();
    }, [selectedEmployee, selectedProject, selectedMisc, filteredDate]);

    const handleClearDate = () => {
        RemoveLocalStorageData("selectedFilters")
        setSelectedEmployee(null);
        setSelectedProject(null);
        setSelectedMisc(null);
        setFilteredDate(null);
    };


    /* <summary>
    date: 19-02-2024
    Name: SYJ
    description: Filter Grid Data depending on filter selected
    <summary>*/

    const filterGridView = () => {

        let filterList = [];
        let workLogList = filteredWorklogDetails;
        let defaultData = worklogDetails;
        let selectedproj = selectedProject || { value: 0 };
        let selectedEmp = selectedEmployee || { value: 0 };
        let selectedDate = filteredDate;
        let selectedMis = selectedMisc || { value: 0 };

        if (selectedEmp.value) {
            filterList = workLogList.filter((itm) => itm.loggedBy === selectedEmp.value);
        }

        if (selectedproj.value) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value);
        }

        if (selectedproj.value && selectedEmp.value) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value && itm.loggedBy === selectedEmp.value);
        }

        if (!selectedproj.value && !selectedEmp.value) {
            filterList = defaultData;
        }

        if (!selectedproj.value && !selectedEmp.value && !selectedMis.value) {
            filterList = defaultData;
        }

        if (selectedDate) {
            filterList = workLogList.filter((itm) => new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedproj.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedEmp.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.loggedBy === selectedEmp.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedproj.value && selectedEmp.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value && itm.loggedBy === selectedEmp.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedMis.value !== 999 && selectedEmp.value && selectedMis.value) {
            filterList = workLogList.filter((itm) => itm.employeeId === selectedEmp.value && itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedMis.value !== 999 && selectedMis.value && selectedEmp.value) {
            filterList = workLogList.filter((itm) => itm.loggedBy === selectedEmp.value && itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedMis.value !== 999 && selectedMis.value && selectedEmp.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.loggedBy === selectedEmp.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString() && itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedMis.value !== 999 && selectedMis.value && selectedDate) {
            filterList = workLogList.filter((itm) => new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString() && itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedMis.value === 999) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId !== null);
        }

        if (selectedMis.value !== 999 && selectedMis.value) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedMis.value === 999 && selectedEmp.value) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId !== null && itm.loggedBy === selectedEmp.value);
        }

        if (selectedMis.value !== 999 && selectedMis.value && selectedEmp.value) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId === selectedMis.value && itm.loggedBy === selectedEmp.value);
        }

        if (selectedMis.value === 999 && selectedDate) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId !== null && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedMis.value !== 999 && selectedMis.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId === selectedMis.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedMis.value === 999 && selectedEmp.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId !== null && itm.loggedBy === selectedEmp.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedMis.value !== 999 && selectedMis.value && selectedEmp.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId == selectedMis.value && itm.loggedBy === selectedEmp.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        if (selectedMis.value && selectedMis.value !== 999 && !selectedEmp.value && !selectedDate && !selectedproj.value) {
            filterList = workLogList.filter((itm) => itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedproj.value && selectedMis.value) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value && itm.miscellaneousTimeLogId === selectedMis.value);
        }

        if (selectedproj.value && selectedMis.value && selectedEmp.value) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value && itm.miscellaneousTimeLogId === selectedMis.value && itm.loggedBy === selectedEmp.value);
        }

        if (selectedproj.value && selectedMis.value && selectedEmp.value && selectedDate) {
            filterList = workLogList.filter((itm) => itm.projectID === selectedproj.value && itm.miscellaneousTimeLogId === selectedMis.value && itm.loggedBy === selectedEmp.value && new Date(itm.date).toLocaleDateString() === selectedDate.toLocaleDateString());
        }

        const filteredDataForTime = filterList
        //.filter(item => item.miscellaneousTimeLogId !== 11 && item.miscellaneousTimeLogId !== 12);
        // });

        const minutesArray = filteredDataForTime.map(curr => {
            const [hours, decimalPart] = curr.hours.toString().split('.');
            const minutes = decimalPart ? decimalPart.padEnd(2, '0') : '00';
            const hrsInMin = parseInt(hours) * 60;
            const value = hrsInMin + parseInt(minutes);
            return value;
        });

        // Sum all the minutes
        const totalMinutes = minutesArray.reduce((acc, curr) => acc + curr, 0);

        // Convert total minutes back to hours and minutes
        const totalHours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;

        // Combine hours and minutes as a decimal
        const roundedTotal = parseFloat((totalHours + remainingMinutes / 60).toFixed(2)); // Round to two decimal places
        //const roundedTotalHrsMin=parseFloat((totalHours +""+ remainingMinutes).toFixed(2)); // Round to two decimal places
        const roundedTotalHrsMin = parseFloat(`${totalHours}.${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`);

        setTotalHours(roundedTotalHrsMin);
        setTotalWorkLogData(filterList);
    };


    /* <summary>
    date: 19-02-2024
    Name: SYJ
    description: AG Grid functions
    <summary>*/
    const onSortChanged = (e) => {
        e.api.refreshCells();
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }, []);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        cellClass: "td",
    };


    const gridOptions = {
        defaultColDef,
        rowHeight: DefaultAGGridProps.ROW_HEIGHT,
        getRowStyle: params => {
            if (params.node.rowPinned === 'bottom') {
                return { fontWeight: "bold" };
            }
        },
        tooltipShowDelay: 0,
        tooltipHideDelay: 3000,
    };

    /* <summary>
    date: 01-04-2024
    Name: Mustakim Shaikh
    description:to get Miscellaneous dropdown labels and its sub options
    <summary>*/
    useEffect(() => {
        GetMiscellaneousTimeLog();
    }, []);

    const [misc, setMisc] = useState([]);
    const GetMiscellaneousTimeLog = async () => {
        const resp = await ReportService.GetMiscellaneousTimeLog(0,false);
        if (resp.status === HTTPResponse.OK) {

            const options = resp.data.map((category) => ({
                label: category.key,
                options: category.value.map((subcategory) => ({
                    value: subcategory.miscellaneousTimeLogId,
                    label: subcategory.subValues,
                    comments:subcategory.comments,
                    miscTaskName:subcategory.miscTaskName
                })),
            }));
            const allSubcategory = {
                value: 999,
                label: 'All'
            };
            const allCategory = {
                label: 'All',
                options: [allSubcategory]
            };
            const newOptions = [allCategory, ...options];

            setMisc(newOptions);
        }
        else {
            if (resp.status === HTTPResponse.Unauthorized) {
                LogOut();
            }
        }

    };

    const customStyles = {
        groupHeading: (provided) => ({
            ...provided,
            fontWeight: 'bolder',
            fontSize: '14px', // Set your desired font size
            color: 'black',  // Set the font color to black 
            fontFamily: 'Lato-Regular',
        }),
        option: (provided, state) => ({
            ...provided,
            ...(state.isSelected ? { marginLeft: '15px', fontSize: '12px', fontFamily: 'Lato-Regular' } : { marginLeft: '15px', fontFamily: 'Lato-Regular', fontSize: '12px' }), // Apply margin-left only if the option is not selected
        }),
    };

    //Set Roles Permission

    const GetAllRolesPermission = async () => {
        try {
            //Default Array llist to filter data
            let adminList = [];
            let adminOtherList = [];
            let leadList = [];
            let leadOtherList = [];
            let employeeList = [];
            let employeeOtherList = [];
            const responseDefaultRP = await UserRolesPermission.GetDefaultRolePermissions();
            const responseUserSaveRP = await UserRolesPermission.GetUserSavedRolePermissions();
            if (responseUserSaveRP.status === HTTPResponse.OK && responseUserSaveRP.data.length > 0) {
                // Function to get the list of role IDs that exist in the user saved role permissions data
                const existingRoleIds = responseUserSaveRP.data.map(item => item.roleId);


                // Bind data based on the existing role IDs
                if (existingRoleIds.includes(1)) {
                    adminList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
                    adminOtherList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
                }
                if (existingRoleIds.includes(2)) {
                    employeeList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
                    employeeOtherList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
                }
                if (existingRoleIds.includes(3)) {
                    leadList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
                    leadOtherList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
                }

                // If no role IDs 1, 2, or 3 data is present, bind the default list
                if (adminList.length === 0) {
                    adminList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
                }
                if (employeeList.length === 0) {
                    employeeList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
                }
                if (leadList.length === 0) {
                    leadList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
                }

                if (adminList.length > 0) {
                    // Update the adminPermission state with the filtered admin list
                    setAdminPermission({
                        ManageEmp: {
                            add: adminList.some(item => item.meadd === 0) ? 0 : 1,
                            edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
                            view: adminList.some(item => item.meview === 0) ? 0 : 1,
                            delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
                        },
                        ManageRolesPermission: {
                            add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
                            edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
                            view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
                            delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
                        },
                        ManageProject: {
                            add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
                            edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
                            view: adminList.some(item => item.mpview === 0) ? 0 : 1,
                            delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
                        },
                        TimeLog: {
                            add: adminList.some(item => item.tladd === 0) ? 0 : 1,
                            edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
                            view: adminList.some(item => item.tlview === 0) ? 0 : 1,
                            delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
                        },
                        MyTask: {
                            add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
                            edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
                            view: adminList.some(item => item.mtview === 0) ? 0 : 1,
                            delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
                        }
                    });
                }

                if (leadList.length > 0) {
                    // Update the adminPermission state with the filtered admin list
                    setProjectLeadPermission({
                        ManageEmp: {
                            add: leadList.some(item => item.meadd === 0) ? 0 : 1,
                            edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
                            view: leadList.some(item => item.meview === 0) ? 0 : 1,
                            delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
                        },
                        ManageRolesPermission: {
                            add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
                            edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
                            view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
                            delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
                        },
                        ManageProject: {
                            add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
                            edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
                            view: leadList.some(item => item.mpview === 0) ? 0 : 1,
                            delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
                        },
                        TimeLog: {
                            add: leadList.some(item => item.tladd === 0) ? 0 : 1,
                            edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
                            view: leadList.some(item => item.tlview === 0) ? 0 : 1,
                            delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
                        },
                        MyTask: {
                            add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
                            edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
                            view: leadList.some(item => item.mtview === 0) ? 0 : 1,
                            delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
                        }
                    });
                }

                if (employeeList.length > 0) {
                    // Update the adminPermission state with the filtered admin list
                    setEmployeePermission({
                        ManageEmp: {
                            add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
                            edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
                            view: employeeList.some(item => item.meview === 0) ? 0 : 1,
                            delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
                        },
                        ManageRolesPermission: {
                            add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
                            edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
                            view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
                            delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
                        },
                        ManageProject: {
                            add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
                            edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
                            view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
                            delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
                        },
                        TimeLog: {
                            add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
                            edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
                            view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
                            delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
                        },
                        MyTask: {
                            add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
                            edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
                            view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
                            delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
                        }
                    });
                }

                if (adminOtherList.length > 0) {
                    // Update the adminPermission state with the filtered admin list
                    setAdminPermission(prevState => ({
                        ...prevState,
                        ManageEmp: {
                            ...prevState.ManageEmp,
                            addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
                            editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
                            viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
                            deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
                        },
                        ManageRolesPermission: {
                            ...prevState.ManageRolesPermission,
                            addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                            editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                            viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                            deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                        },
                        ManageProject: {
                            ...prevState.ManageProject,
                            addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                            editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                            viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
                            deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                        },
                        TimeLog: {
                            ...prevState.TimeLog,
                            addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
                            editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
                            viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
                            deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                        },
                        MyTask: {
                            ...prevState.MyTask,
                            addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                            editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                            viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
                            deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                        }
                    }));
                }

                if (leadOtherList.length > 0) {
                    // Update the adminPermission state with the filtered admin list
                    setProjectLeadPermission(prevState => ({
                        ...prevState,
                        ManageEmp: {
                            ...prevState.ManageEmp,
                            addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
                            editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
                            viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
                            deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
                        },
                        ManageRolesPermission: {
                            ...prevState.ManageRolesPermission,
                            addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                            editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                            viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                            deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                        },
                        ManageProject: {
                            ...prevState.ManageProject,
                            addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                            editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                            viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
                            deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                        },
                        TimeLog: {
                            ...prevState.TimeLog,
                            addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
                            editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
                            viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
                            deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                        },
                        MyTask: {
                            ...prevState.MyTask,
                            addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                            editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                            viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
                            deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                        }
                    }));
                }

                if (employeeOtherList.length > 0) {
                    // Update the adminPermission state with the filtered admin list
                    setEmployeePermission(prevState => ({
                        ManageEmp: {
                            ...prevState.ManageEmp,
                            addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
                            editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
                            viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
                            deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
                        },
                        ManageRolesPermission: {
                            ...prevState.ManageRolesPermission,
                            addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                            editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                            viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                            deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                        },
                        ManageProject: {
                            ...prevState.ManageProject,
                            addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                            editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                            viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
                            deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                        },
                        TimeLog: {
                            ...prevState.TimeLog,
                            addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
                            editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
                            viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
                            deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                        },
                        MyTask: {
                            ...prevState.MyTask,
                            addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                            editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                            viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
                            deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                        }
                    }));
                }

            }
        } catch (error) {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };
    const selectedFiltersLocal=GetLocalStorageData("selectedFilters")
    
    useEffect(() => {
        if (selectedFiltersLocal && selectedFiltersLocal?.selectedFilters) 
            {
          setSelectedProject(selectedFiltersLocal?.project);
          setSelectedMisc(selectedFiltersLocal?.misc);
           
          setFilteredDate(new Date(selectedFiltersLocal?.date) )
            }
    }, [selectedFiltersLocal]);

    return (
        <>
            {showDeleteModalPopUp && (
                <DeleteModal
                    showDeleteModal={showDeleteModalPopUp}
                    onYesClick={OnDeleteModalYesClick}
                    onNoClick={OnCloseDeleteModal}
                    textToDisplay={"Are you sure you want to delete?"}
                />
            )}

            <section className="breadcrumb-block">
                <BreadcrumbComponent /></section>
            <div className="timeLog">
                <main className="main-body-content-project timelog-custom-height" style={{ paddingBlock: "0px" }}>
                    <section className="container-fluid">
                        <div
                            className="dateAndEmployeeDropdown"
                        >
                        </div>
                        <div>
                            <main style={{ paddingBlock: "0px" }}>
                                <section className="container-fluid project" style={{ padding: "0px", position: "relative" }}>


                                    <div className="row">

                                        <div className="col-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                                            <label>Employee</label>
                                            {(roleId === ROLE_TYPE.ADMIN || roleId == ROLE_TYPE.LEAD) ? (
                                                <Select
                                                    inputId="employeeDropDown"
                                                    value={selectedEmployee !== null && selectedEmployee !== undefined ? selectedEmployee : null}
                                                    placeholder="Please Select"
                                                    options={[
                                                        { value: 0, label: "All" }, // Option to select all projects
                                                        ...employees.map((emp) => ({
                                                            value: emp.value,
                                                            label:
                                                                //`${
                                                                emp.label
                                                            //} ${userId === emp.value ? "(ME)" : ""}`,
                                                            ,status:emp.status,
                                                        }))
                                                    ]}
                                                    onChange={(e) => {
                                                        setSelectedEmployee(e);
                                                        HandleDropdownChange(e, "employees", "employeeId");
                                                        filterGridView()
                                                    }}
                                                    isClearable={selectedEmployee !== undefined && (!selectedEmployee === null || selectedEmployee?.value > 0 || selectedEmployee?.value === 0)}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                          ...provided,
                                                          backgroundColor: state.data.status === false ? "#f0f0f0" : provided.backgroundColor, 
                                                           color: state.isSelected ? "black" : "black", 
                                                        }),
                                                      }}
                                                />
                                            ) : (
                                                employees.map((emp) => (
                                                    <div key={emp.value} value={emp.value} className="disabled-div" style={{ width: "100%" }}>
                                                        {emp.label}

                                                    </div>
                                                ))
                                            )}
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 col-xl-3 mt-3 ">
                                            <label>Project</label>
                                            <Select
                                                name="projectList"
                                                placeholder="Please Select"
                                                value={selectedProject !== null && selectedProject !== undefined ? selectedProject : null}
                                                options={[
                                                    { value: 0, label: "All" },
                                                    ...projects.map((project) => ({
                                                        value: project.value,
                                                        label: project.label,
                                                    }))
                                                ]}
                                                onChange={(e) => {
                                                    setSelectedProject(e);
                                                    HandleDropdownChange(e, "projects", "projectId");
                                                    filterGridView()
                                                }}// Call handleProjectDropdownChange on change
                                                isClearable={selectedProject !== undefined && (!selectedProject === null || selectedProject?.value > 0 || selectedProject?.value === 0)}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 col-xl-2 mt-3">
                                            <label>Miscellaneous Category</label>
                                            <Select
                                                id="miscList" // Add an id to the Select component
                                                name="miscList"
                                                placeholder="Please Select"
                                                value={selectedMisc !== null && selectedMisc !== undefined && selectedMisc.value > 0 ? selectedMisc : null}
                                                options={misc}
                                                onChange={(e) => {
                                                    setSelectedMisc(e);
                                                    HandleDropdownChange(e, "misc", "miscId");
                                                    filterGridView();
                                                }}
                                                styles={customStyles}
                                                isClearable={selectedMisc !== undefined && (!selectedMisc === null || selectedMisc?.value > 0 || selectedMisc?.value === 0)}
                                            />
                                        </div>

                                        {selectedMisc !== null && selectedMisc !== undefined && selectedMisc.value > 0 ? (
                                            <UncontrolledTooltip
                                                autohide={false}
                                                target={"miscList"} // Update target to match the id or name of the Select component
                                                aria-haspopup="true"
                                                role="example"
                                                effect="solid"
                                                placement="bottom"
                                                style={{ width: "350px", color: 'black', backgroundColor: '#e8d3d3' }} // Adjusted text color to black
                                            >

                                                {misc.map(category => {
                                                    const selectedOption = category.options.find(option => option.value === selectedMisc.value);
                                                    if (selectedOption) {
                                                        return selectedOption.label;
                                                    }
                                                    return null;
                                                })}

                                            </UncontrolledTooltip>
                                        ) : null}

                                        <div className="col-12 col-md-6 col-lg-3 col-xl-2 mt-3 TimeLog">
                                            <label>Date</label>
                                            <div className="dateandclear" style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                                <label>
                                                    <DatePicker
                                                        placeholderText="Select Date"
                                                        className="form-control"
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={filteredDate}
                                                        onChange={(date) => { setFilteredDate(date); filterGridView() }}
                                                        showIcon={true}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        maxDate={new Date()}
                                                        popperClassName="ag-custom-component-popup"
                                                        scrollableYearDropdown={true}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        todayButton="Today"

                                                    /></label>

                                            </div>

                                        </div>


                                    </div>
                                    <div className="floating-btn-timelog">
                                        <div>
                                            <button
                                                className="save-project-btn"
                                                onClick={handleClearDate}
                                                style={{
                                                    width: "70px"
                                                }}
                                                title="Reset Filter"
                                            >
                                                Reset
                                            </button >

                                            {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.TimeLog.addOther === 1 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.TimeLog.add === 1
                                                || roleId === ROLE_TYPE.LEAD && projectLeadPermission.TimeLog.add === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.TimeLog.addOther === 1)
                                                &&
                                                <button className="save-project-btn" onClick={() =>{AddLocalStorageData("pathvalueh", `/TimeLog`); 
                                                navigate("/AddTimeLogging", {
                                                    state: { dateSelected: filteredDate }
                                                  });
                                                }} style={{ width: "105px", padding: '5px 8px' }}>Add Time Log</button>
                                            }

                                            <img
                                                src="../Assets/images/refresh.png"
                                                onClick={() => window.location.reload()}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '25px',
                                                    marginBottom: "5px"
                                                }}
                                                title="Refresh"
                                            />
                                        </div>
                                    </div>

                                    <div className="Project-table" style={{ marginTop: "10px" }}>
                                        {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.TimeLog.viewOther === 1 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.TimeLog.view === 1
                                            || roleId === ROLE_TYPE.LEAD && projectLeadPermission.TimeLog.view === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.TimeLog.viewOther === 1) &&
                                            <AGGrid
                                                rowData={totalWorkLogData}
                                                gridOptions={gridOptions}
                                                colDefs={gridColumns(adminPermission, employeePermission, projectLeadPermission)}
                                                onGridReady={onGridReady}
                                                pagination="10"
                                                isTimelog={true}
                                                pinnedBottomRowData={[
                                                    roleId === ROLE_TYPE.ADMIN && gridUpdateColumns.some(column => column.pinned !== 'right') ? { employeeName: 'Total', hours: totalHours } : { date: 'Total', hours: totalHours }
                                                ]}
                                                onSortChanged={(e) => onSortChanged(e)}
                                            />
                                        }
                                    </div>
                                </section>
                            </main>
                        </div>
                    </section>
                </main>
            </div >
        </>
    );
};

export default TimeLog;
